import { axiosApiInstance } from "./HttpService";
import { IAvailableSettlementsRequest, IcouponsSearchRequest, IHistoricRequest, IHistoricDetailsRequest, IOperationDetailsRequest, IliquidationsConfirmationRequest } from "../models/IAdvancedData";

export class AdvancedService {

    public static availableSettlements(request:IAvailableSettlementsRequest) {

      //  request.fiscalNumbers=["0033630659219", "0033711535719"];
        let dataURL: string = `${process.env.REACT_APP_API_SETTLEMENT_URL}/settlement/SettlementVal/queryAvailableVal`;
        return axiosApiInstance.post(dataURL, request);
    }

    public static couponsSearch(request:IcouponsSearchRequest) {
        let dataURL: string = `${process.env.REACT_APP_API_SETTLEMENT_URL}/settlement/SettlementVal/queryVal`;
        return axiosApiInstance.post(dataURL, request);
    }

    public static liquidationsConfirmation(request:IliquidationsConfirmationRequest) {
        let dataURL: string = `${process.env.REACT_APP_API_SETTLEMENT_URL}/settlement/SettlementVal/confirmVal`;
        return axiosApiInstance.post(dataURL, request);
    }

    public static historicAdvanced(request:IHistoricRequest) {
        let dataURL: string = `${process.env.REACT_APP_API_SETTLEMENT_URL}/settlement/SettlementVal/getValHistory`;
        return axiosApiInstance.post(dataURL,request);
    }

    public static historicAdvancedDetails(request:IHistoricDetailsRequest) {
        let dataURL: string = `${process.env.REACT_APP_API_SETTLEMENT_URL}/settlement/SettlementVal/getHistoryDetail`;
        return axiosApiInstance.post(dataURL,request);
    }

    public static operationDetails(request:IOperationDetailsRequest) {
        let dataURL: string = `${process.env.REACT_APP_API_SETTLEMENT_URL}/settlement/SettlementVal/getOperationDetails`;
        return axiosApiInstance.post(dataURL,request);
    }
}