import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import LabelMessage from './LabelMessage';
import './CustomCaptcha.scss'

interface ReCAPTCHAProps {
  onVerify: (verified: boolean,token:string) => void;
}

const sitekey = "6LdQlQ0pAAAAADjbxloDIchjMWSdfO5H3l6Lnvzi";
const hl = 'es'; 

const CustomReCAPTCHA: React.FC<ReCAPTCHAProps> = ({ onVerify }) => {
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);

  const handleCaptchaChange = (value: string | null) => {
    setIsCaptchaVerified(value !== null);
    onVerify(value !== null,value+"");
  };
  
  return (
    <div className='recatpcha'>
      <ReCAPTCHA sitekey={sitekey} hl={hl} onChange={handleCaptchaChange} />
      <div className='messageLabel' >
      <LabelMessage valid={isCaptchaVerified} message="Por favor, verifica el captcha antes de continuar" />
      </div>  
    </div>
  );
};

export default CustomReCAPTCHA;