import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import i18next from "i18next";
import { ChangeEvent, Component } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { IPieChartData } from "../../models/IPieChartData";
import { UtilService } from "../../services/UtilService";
import Card from '../containers/card';
import RadioButtonGroup from '../controls/fields/radioButtonGroup';
import './chart.css';
import { CenterBarText } from '../../services/DashboardService';

ChartJS.register(ArcElement, Tooltip, Legend);

export const options = {
    plugins:{
        legend: {
            display: false
        },
        tooltip: {
            bodyFont: {
                size: 15
            },
            callbacks: {
                label: () => "",
                beforeBody: function(data: any) {
                    //Se genera la multilinea en el tooltip emergente del gráfico de rechazos por tarjeta
                    var multistringText = [data[0].label + ":"];
                    multistringText.push("$ "+ data[0].formattedValue);
        
                    return multistringText;
                }
            }
        }
    },
    maintainAspectRatio: false,
    responsive: true,
    animation: {
        animateScale: true,
        animateRotate: true
    }
};

interface IProps {
    chartData: IPieChartData,
    name: string,
    currencySymbol: string
}
interface IState{
    chartData: any,
    showDetail: boolean,
    dataType: string,
    show: boolean,
    issuersByTotal: [],
    tranId: string
}
interface graftData{
    labels: string[],
    datasets: DataSets[]
}
interface DataSets{
    data: number[],
    backgroundColor: string[]
}

class RejectionByIssuerChart extends Component<IProps, IState> {
    private data: graftData;
    constructor(props: IProps) {
        super(props);
        this.state = {
            show: false,
            showDetail: false,
            issuersByTotal: [],
            tranId: "",
            chartData: null,
            dataType: "amount",
        };
        this.data= {
            datasets: [],
            labels: []
        }
    }

    componentWillReceiveProps(nextProps:IProps) {
        // You don't have to do this check first, but it can help prevent an unneeded render
        if (nextProps.chartData !== this.state.chartData) {
            this.setState({ chartData: nextProps.chartData });

            this.recalculateChart(nextProps,this.state.dataType,this.state.showDetail);
        }
    }

    recalculateChart(nextProps: IProps, dataType: string, showDetail: boolean) {
        let data : graftData= {
            labels:[],
            datasets: [{
                backgroundColor:[],
                data: []
            }]
        };
        let values = ((dataType === "amount") ? nextProps.chartData.values : nextProps.chartData.counts as never[])
            .map((r, index) => {
                return { index , val: r, label: `${nextProps.chartData.labels[index]}`}
            });

        values.sort((x1,x2)=>{return Number(x1.val)>Number(x2.val)?-1:1});

        for (var i in values) {
            data.labels.push(i18next.t(values[i].label));
            data.datasets[0].data.push(values[i].val);
            data.datasets[0].backgroundColor.push(nextProps.chartData.colors[values[i].index]);
        }
        this.data= data;
    }

    render() {
        return (
            <div className="col-lg-4">
                <Card title={this.props.name} actions={
                    <RadioButtonGroup
                        name={this.props.name}
                        model={[ this.state, this.setState.bind(this) ]}
                        attr="dataType"
                        className='buttonDashboard'
                        fieldDefinitions={[
                            { key: 'quantity', value: 'quantity', label: 'quantity' },
                            { key: 'amount', value: 'amount', label: 'amount' }
                        ]}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => this.recalculateChart(this.props, event.target.value, this.state.showDetail)}
                    ></RadioButtonGroup>
                }>
                    <>
                        <div style={{width: !UtilService.isSmallScreen()?"60%":"100%",display:"inline-block"}}>
                            <Doughnut id="Doughnut" plugins={[CenterBarText]} redraw={true} options={options} key={this.data.datasets[0]?.data?.length} data={this.data} className="h-300" />
                        </div>
                        {
                            !UtilService.isSmallScreen() ?
                                <div style={{width: "40%", display: "inline-block"}}>
                                    <table style={{width:"100%",position:"relative"}}>
                                        <tbody>
                                        {
                                            this.data.datasets[0]?.data.map((r,index) => {
                                                if (index < 5 || this.state.showDetail) {
                                                    return (
                                                        <tr key={`tr_${r}_${index}`}>
                                                            <td className='text-start ps-4'>
                                                                    <span className="d-block" style={{paddingLeft: "5px", borderLeft: "solid 15px " + this.data.datasets[0]?.backgroundColor[index]}}>
                                                                        {this.data?.labels[index]}
                                                                    </span>
                                                                <span className="d-block ps-4">
                                                                        {
                                                                            this.state.dataType === "amount" ?
                                                                                Intl.NumberFormat('es-UY', { style: 'currency', currency: this.props.currencySymbol, maximumFractionDigits: 0 }).format(r)
                                                                                :
                                                                                Intl.NumberFormat('es-UY', { maximumFractionDigits: 0 }).format(r)
                                                                        }
                                                                    </span>
                                                            </td>
                                                        </tr>
                                                    )
                                                }

                                                return <></>
                                            })
                                        }
                                        </tbody>
                                    </table>
                                </div>
                                :
                                <div style={{width: "100%"}}>
                                    <table style={{width:"100%"}}>
                                        <tbody>
                                        {
                                            this.data.datasets[0]?.data.map((r,index) => {
                                                if (index < 10 || this.state.showDetail) {
                                                    return (
                                                        <tr key={`tr_${r}_${index}`}>
                                                            <td className='text-start ps-4'>
                                                            <span className="d-block" style={{paddingLeft: "5px", borderLeft: "solid 15px " + this.data.datasets[0]?.backgroundColor[index]}}>
                                                                {this.data?.labels[index]}
                                                            </span>
                                                                <span className="d-block ps-4">
                                                                {
                                                                    this.state.dataType === "amount" ?
                                                                        Intl.NumberFormat('es-UY', { style: 'currency', currency: this.props.currencySymbol, maximumFractionDigits: 0 }).format(r)
                                                                        :
                                                                        Intl.NumberFormat('es-UY', { maximumFractionDigits: 0 }).format(r)
                                                                }
                                                            </span>
                                                            </td>
                                                        </tr>
                                                    )
                                                }

                                                return <></>
                                            })
                                        }
                                        </tbody>
                                    </table>
                                </div>
                        }
                        { !UtilService.isSmallScreen() ?
                            (this.data.datasets !== null) ?
                                (
                                    (!this.state.showDetail) ?
                                        <div className="chart-detail-button" onClick={()=>this.setState({showDetail: true})}>
                                            <a href={window.location.href}>
                                                <i className="fa-solid fa-chevron-down"></i>
                                            </a>
                                        </div>
                                        :
                                        <div className="chart-detail-button" onClick={()=>this.setState({showDetail: false})}>
                                            <a href={window.location.href}>
                                                <i className="fa-solid fa-chevron-up"></i>
                                            </a>
                                        </div>
                                )
                                :
                                <></>
                            :
                            ""
                        }
                    </>
                </Card>
            </div>
        )
    };
}
export default RejectionByIssuerChart;