import React, { useState } from 'react';
import { TFunction, withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Swal, { SweetAlertResult } from 'sweetalert2';
import { ReactComponent as LogoSVG } from "../../node_modules/itd-common/assets/images/brand/logo.svg";
import { UserService } from "../services/UserService";
import "./ForgotPassword.scss";
import LabelMessage from './LabelMessage';
import Form from './containers/form';
import SubmitButton from './controls/buttons/submitButton';
import EmailField from './controls/fields/emailField';
import Footer from './layout/Footer';
import CustomReCAPTCHA from "./CustomCaptcha";

interface IProps { 
    t: TFunction
}
let LostPhone: React.FC<IProps> = ({ t }) => {
    const T_PREFIX: string = 'pages.lostPhone.';
    const [model, setModel] = useState({ email: '' });
    const [isValid, setIsValid] = useState(true);
    const [isEmpty, setIsEmpty] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
    const [token, setToken] = useState("");
    const validateEmail = (emailValue: string): boolean => {
        const emailRegex: RegExp = /\S+@\S+\.\S+/;     
        return emailRegex.test(emailValue);
    };
    const updateValues = (emailValue: string): void => {
        setIsValid(!emailValue || validateEmail(emailValue));
        setIsEmpty(!emailValue || emailValue.length === 0);
    }
    const handleCaptchaVerify = (onVerify: boolean,token:string) => {
        setToken(token);
        setIsCaptchaVerified(onVerify);
    };
    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const email: string = event.target.value;
        setModel({ email });
        updateValues(email);
    };
    const sendEmail = (): void => {
        const email: string = model.email;
        if (validateEmail(email) && isCaptchaVerified) {
            const swalFire = (titleKey: string, htmlKey: string): Promise<SweetAlertResult<Awaited<null>>> => {
                return Swal.fire({
                    title: `<i class="fas fa-exclamation-triangle text-warning"></i> ${t(`${T_PREFIX}sendMailModal.${titleKey}`)}`,
                    html: t(`${T_PREFIX}sendMailModal.${htmlKey}`),
                    confirmButtonColor: '#f60'
                });
            };

            UserService.resetDevices(email,token).then((response) => {
                if (response.status !== 200) {
                    swalFire('errorTitle', 'contactHelpDesk');
                } else {
                    setEmailSent(true);
                }
            }).catch((data) => {
                var message= "contactHelpDesk";
                if(data.response.data!=null&&data.response.data.length>0)
                    message=data.response.data[0];
                /*Swal.fire({
                    title: t('errorOcurred'),
                    html: t(message),
                    confirmButtonColor: '#f60',
                    timer: 6000,
                    timerProgressBar: true,
                    icon:"warning"
                });*/
                swalFire('errorTitle', message);
            });
        } else {
            updateValues(email);
        }
    };
    const MailPassword: React.FC= () => {        
        return (
            <React.Fragment>
                <div className="page mail-password">
                    <div className=""> 
                        <div className="container-mail">
                            <div className="form-mail">
                                <div className='brand-logo-container'>
                                    <LogoSVG className='brand-logo-container__image' ></LogoSVG>
                                </div>
                                <div className='svg'>
                                    <svg width="30" height="28" viewBox="0 0 30 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g id="Emails  x2F  Email Actions  x2F  email action read 1">
                                            <path id="Vector" d="M27.1874 27.3332H2.82876C1.46457 27.3332 0.349609 26.2266 0.349609 24.873V11.5837C0.349609 11.3755 0.454543 11.1803 0.611949 11.0632L14.6211 0.79341C14.8572 0.624202 15.172 0.624202 15.395 0.79341L29.4042 11.0632C29.5747 11.1803 29.6665 11.3755 29.6665 11.5837V24.873C29.6665 26.2266 28.5516 27.3332 27.1874 27.3332ZM1.64822 11.9091V24.873C1.64822 25.5108 2.17291 26.0316 2.81565 26.0316H27.1743C27.817 26.0316 28.3417 25.5108 28.3417 24.873V11.9091L14.9884 2.12107L1.64822 11.9091Z" fill="#333333"/>
                                            <path id="Vector_2" d="M4.65199 23.1031C4.44211 23.1031 4.24535 23.012 4.11418 22.8298C3.9043 22.5304 3.98302 22.1267 4.2716 21.9184L10.358 17.6882C10.4629 17.6101 10.5941 17.5713 10.7384 17.5713H19.2645C19.3957 17.5713 19.5269 17.6101 19.6449 17.6882L25.7313 21.9184C26.033 22.1267 26.0986 22.5304 25.8887 22.8298C25.6788 23.1292 25.2722 23.1941 24.9705 22.9858L19.0547 18.8729H10.9482L5.0324 22.9858C4.91434 23.0639 4.78316 23.1031 4.65199 23.1031Z" fill="#333333"/>
                                            <path id="Vector_3" d="M21.7043 17.0632C21.4944 17.0632 21.2845 16.9591 21.1533 16.7769C20.9566 16.4775 21.0353 16.0741 21.337 15.8789L28.6433 11.05C28.945 10.8548 29.3516 10.9327 29.5483 11.2321C29.7451 11.5315 29.6664 11.9349 29.3647 12.1301L22.0584 16.9593C21.9535 17.0244 21.8223 17.0632 21.7043 17.0632Z" fill="#333333"/>
                                            <path id="Vector_4" d="M8.29849 17.0632C8.16731 17.0632 8.04926 17.0244 7.93121 16.9593L0.624941 12.1301C0.323246 11.9349 0.244544 11.5315 0.441301 11.2321C0.638059 10.9327 1.04468 10.8548 1.34637 11.05L8.65266 15.8789C8.95435 16.0741 9.03305 16.4775 8.8363 16.7769C8.71824 16.9591 8.52148 17.0632 8.29849 17.0632Z" fill="#333333"/>
                                        </g>
                                    </svg>
                                </div>
                                <span className='text-email' > {t(`${T_PREFIX}checkEmail`)}</span>
                                <span className='text-send'>{t(`${T_PREFIX}sendEmail`)} </span>
                                <Link to={'/login'} className="back-login"> {t(`${T_PREFIX}returnToLogin`)}</Link>                                                      
                            </div>
                        </div>
                </div>
                </div>
                <Footer isValid={false} />
            </React.Fragment>
        )
    }

return (
    <React.Fragment>
        {emailSent ? (
            <MailPassword />
        ) : (
            <div className="page forgot-password">
                <div className=""> 
                    <div className="container-forgot">
                        <div className="backward">
                            <div className="backward__init">
                                <Link to={'/login'} className="link">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g id="ico/arrow-left">
                                            <path id="Vector 1" d="M10 4L2 12L10 20" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                            <line id="Line 7" x1="3" y1="12" x2="21" y2="12" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </g>
                                    </svg>            
                                    <span className='backward__init__callback'>{t(`${T_PREFIX}returnToLogin`)}</span>
                                </Link>
                            </div>
                        </div>
                        <div className="form">
                            <div className='brand-logo-container'>
                                <LogoSVG className='brand-logo-container__image' ></LogoSVG>
                            </div>
                            <span className="login100-form-title pb-4">
                                {t(`${T_PREFIX}title`)}
                            </span>
                            <Form model={[model, setModel]} onSubmit={sendEmail}>
                                <div>
                                    <div className="login100-form validate-form">
                                        <EmailField attr="email" label={`${T_PREFIX}username`} onChange={handleOnChange} required autoFocus></EmailField>                           
                                        <LabelMessage valid={isValid} message={t(`${T_PREFIX}invalidEmail`)} ></LabelMessage>
                                        <LabelMessage valid={!isEmpty} message={t(`${T_PREFIX}emptyEmail`)} ></LabelMessage>
                                    </div>
                                    <div className='confirmForgotPass row' >
                                        <div className="col-md-6">                                
                                            <CustomReCAPTCHA onVerify={handleCaptchaVerify} />
                                        </div>  
                                        <div className="boton col-md-6">
                                            <div className="boton__click">
                                                <SubmitButton className="mb-4 envio">
                                                    <div className='submit-button-container'>
                                                        <span className='ms-3' >{t(`${T_PREFIX}continue`)}</span>
                                                        <i className='fe fe-arrow-right submit-button-container__icon me-3' ></i>
                                                    </div>
                                                </SubmitButton>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Form>                          
                        </div>
                    </div>
               </div>
            </div>
        )}
        <Footer isValid={false} />
    </React.Fragment>     
);
}
export default withTranslation()(LostPhone);