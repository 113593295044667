import React from 'react';
import './ChartConciliation.scss';
import { ArcElement, Chart as ChartJS, Tooltip as ChartJSTooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { withTranslation } from "react-i18next";
import { ReactComponent as IconInfoSVG } from "../../../node_modules/itd-common/assets/icons/alert-information.svg";
import { IPeriodIndicator } from "../../models/IBarChartData";
import { IPieChartData } from "../../models/IPieChartData";
import { DashboardService, innerVertBarText } from "../../services/DashboardService";
import Tooltip from '../Tooltip';
import { ReactComponent as ArrowGreen } from "../../../node_modules/itd-common/assets/icons/arrow-green.svg";

ChartJS.register(ArcElement, ChartJSTooltip, Legend);


interface IProps{
    chartData:IPieChartData,
    currencySymbol:string,
    shortPeriodChartData:IPeriodIndicator,
    infoTooltip: string,
    dateRange:string
}

class AmountByIssuerChart extends React.Component<IProps> {

    options = DashboardService.getBarChartOptions();

    data = {
        labels: [""],
        datasets: [{
            label:"",
            data: [],
            backgroundColor: []
        }]
    };

    

    state = {
        chartData:null,
        changePercentage: 0,
        dateRange:"",
        shortPeriodChartData: {
            titleLabel: "",
            total: 0,
            periodTotals: [0, 0],
            countTotals: [0, 0],
            promTotals: [0, 0],
            rows: [{
                label: "",
                color: "",
                values: []
            }],
            labels: [],
            currentPeriodLabel: "-",
            lastPeriodLabel: "-",
        },
        showDetail:false
    }

    componentWillReceiveProps(nextProps:IProps) {
        // You don't have to do this check first, but it can help prevent an unneeded render
        if (nextProps.chartData !== this.state.chartData) {
            this.recalculateChart(nextProps,this.state.showDetail);
        }
    }

    recalculateChart(nextProps:IProps,showDetail:boolean){
        var values=nextProps.chartData.values.map((r,index)=>{return {index:index,val:r}});
        values.sort((x1,x2)=>{return Number(x1.val)>Number(x2.val)?-1:1});
        this.data={
            labels:[""],
            datasets: [ ],
        };
        values.map((x,index)=>{
            if(showDetail||index<5)
                this.data.datasets.push({
                    label:nextProps.chartData.labels[values[index].index],
                    data: [values[index].val],
                    backgroundColor: [nextProps.chartData.colors[index]]
                })
        })
        const totalsDividend = (nextProps.shortPeriodChartData.periodTotals[1] | 0) * 100;
        const totalsDivisor = nextProps.shortPeriodChartData.periodTotals[0] | 0;
        const totalsDivision = totalsDividend / totalsDivisor
        const changePercentage = (isFinite(totalsDivision) && !isNaN(totalsDivision)) ? totalsDivision : 0;

        this.setState({
            showDetail:showDetail,
            chartData: nextProps.chartData,
            changePercentage: (changePercentage === 100) ? 0 : 100 - changePercentage,
            dateRange: nextProps.dateRange,
            shortPeriodChartData: nextProps.shortPeriodChartData
        })
    }

    render() {
        // @ts-ignore
        const { t } = this.props;
        return (
                <>
                        <div className="row">
                        {this.state.shortPeriodChartData.periodTotals.length >0 ? 
                            <div className="col-sm-8">
                                <h2 hidden={this.state.shortPeriodChartData.periodTotals[0]==null}>
                                    {Intl.NumberFormat('es-UY', { style: 'currency', currency: this.props.currencySymbol, minimumFractionDigits: 0 }).format(Number(this.state.shortPeriodChartData.periodTotals[0].toFixed(0)))}
                                    <Tooltip message={this.props.infoTooltip}>
                                        <IconInfoSVG className={'info-icon'} ></IconInfoSVG>
                                    </Tooltip>
                                </h2>
                            </div>:<></>
                        }
                        {this.state.changePercentage!=null?
                            <div className="col-sm-4 text-center section-percent" hidden={this.state.dateRange=="1year"}>
                            <h1 className='mb-0 font-fiserv'>
                                <span className={"text-start mb-0 pe-1 " + (this.state.changePercentage < 0 ? "text-red" : "text-green")}>
                                {Intl.NumberFormat('es-UY', { minimumFractionDigits: 0 }).format(Number(Math.abs(this.state.changePercentage).toFixed(1)))+"%"}</span>
                                <i className={"arrow-icon fe " + (this.state.changePercentage < 0 ? "fe-arrow-down text-red" : "")}>
                                    {this.state.changePercentage >= 0 && <ArrowGreen />}
                                </i>          
                            </h1>
                                <h5 className={"mb-0 w-100 number-font font-fiserv " + (this.state.changePercentage < 0 ? "text-red" : "text-green")} >{" X "+t(this.state.shortPeriodChartData.titleLabel)}</h5>
                            </div>:<></>
                        }
                        {this.state.shortPeriodChartData.periodTotals.length>0?
                            <div className="col-md-6" >
                                <h4 >
                                    <span>{t("couponPromIndicator")+" "+Intl.NumberFormat('es-UY', { style: 'currency', currency: this.props.currencySymbol, maximumFractionDigits: 0 }).format(Number(this.state.shortPeriodChartData.promTotals[0].toFixed(0)))}</span>
                                </h4>

                            </div>:<></>
                        }
                        </div>
                        {/*@ts-ignore*/}
                        {this.data.datasets < 5 ? this.options.dataLabels.enabled = true : this.options.dataLabels.enabled = false}
                        <Bar id="Bar" plugins={[innerVertBarText]} redraw={true} options={this.options} data={this.data} className="h-300"/>
                            <table className='w-100'>
                                <tbody>
                                    {
                                        this.data.datasets.map((r, index) => {
                                            if (index < 5 || this.state.showDetail) {
                                                return (
                                                    <tr key={`${r.label}`} >
                                                        <td className='tableStyle'></td>
                                                        <td className='cellClass'>
                                                            <span  style={{paddingLeft:"5px",borderLeft:""+r.backgroundColor[0]}}></span> <span>{r.label}</span>
                                                        </td>
                                                        <td className='currencyClass' >{Intl.NumberFormat('es-UY', { style: 'currency', currency: this.props.currencySymbol, maximumFractionDigits: 0 }).format(r.data[0])}</td>
                                                    </tr>
                                                )
                                            }
                                            else {
                                                return <></>
                                            }
                                        })
                                    }
                                </tbody>
                            </table>
                        {
                            this.data.datasets!=null?
                                (
                                    !this.state.showDetail?
                                    <div className="chart-detail-button" onClick={()=>this.recalculateChart(this.props,true)}><a href={window.location.href}><i className="fa-solid fa-chevron-down"></i></a></div> :
                                    <div className="chart-detail-button" onClick={()=>this.recalculateChart(this.props,false)}><a href={window.location.href}><i className="fa-solid fa-chevron-up"></i></a></div>
                                ):<></>
                        }


                </>

        )
    }
}
export default withTranslation() (AmountByIssuerChart);
