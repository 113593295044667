import axios, {AxiosResponse} from 'axios';
import { WebCache } from "./WebCache";

import ReactGA from "react-ga4";
import Swal from 'sweetalert2';
import {request} from "http";
export const axiosApiInstance = axios.create();

const exlusionFromSpinner = ["FileExporter/getAllTasks", "queryTransaction","Settlement/getAllPayerBanksAndProducts"]

export class HttpService {

    public static startSpinner() {
        WebCache.disableTasks();
        const divLoading = document.createElement("div")
        divLoading.id = "divLoading"
        divLoading.className = 'overlay';

        const divDimmer = document.createElement("div");
        divDimmer.className = "centerOverlay";

        const divBonunce1 = document.createElement("div");
        divBonunce1.className = "double-bounce1";

        const divBonunce2 = document.createElement("div");
        divBonunce2.className = "double-bounce2";

        divDimmer.appendChild(divBonunce1);
        divDimmer.appendChild(divBonunce2);
        divLoading.appendChild(divDimmer);
        document.body.appendChild(divLoading);
    }

    public static stopSpinner() {
        WebCache.enableTasks();
        const div = document.getElementById('divLoading')?.remove();
    }

    public static setupInterceptors() {
        // Request interceptor for API calls
        axiosApiInstance.interceptors.request.use(
            async config => {

                const url = config.url + "";
                var showLoader = true;
                for (var i in exlusionFromSpinner) {
                    showLoader = url.indexOf(exlusionFromSpinner[i]) < 0;
                    if (!showLoader)
                        break;
                }
                if (showLoader)
                    HttpService.startSpinner();

                if (url.indexOf("/Users/authenticate") >= 0 || url.indexOf("/Users/makeAssertion") >= 0) {
                    WebCache.clearCache();
                }
                const userStr = localStorage.getItem("user");
                let user = null;
                if (userStr)
                    user = JSON.parse(userStr);
                if (user && user.token) {
                    config.headers = {
                        'Authorization': `Bearer ${user.token}`,
                        'Accept': 'application/json'
                    }
                } else {

                }
                return config;
            },
            error => {
                Promise.reject(error)
            });

        function sendGAEvent(url: string, response: AxiosResponse<any,any>) {
            try{

                //prepare audit event
                if (url.indexOf("/Dashboard/getDashboard")>=0)
                {
                    ReactGA.event({
                        category: "Consulta Servidor",
                        action: "Dashboard",
                        label: "Consulta Servidor - Dashboard" // optional
                    });
                }else if (url.indexOf("/Settlement/getAllPayerBanksAndProducts")>=0)// se llama en el login
                    ReactGA.event({
                        category: "Consulta Servidor",
                        action: "Login",
                        label: "Consulta Servidor - Login" // optional
                    });
                else if(url.indexOf("/Transaction/Transactions")>=0)
                    ReactGA.event({
                        category: "Consulta Servidor",
                        action: "Movimientos - Reporte de Txs",
                        label: "Consulta Servidor - Movimientos - Reporte de Txs" // optional
                    });
                else if(url.indexOf("/Transaction/CurrentTransactions")>=0)
                    ReactGA.event({
                        category: "Consulta Servidor",
                        action: "Reportes - Transacciones Pendientes",
                        label: "Consulta Servidor - Reportes - Transacciones Pendientes" // optional
                    });
                else if(url.indexOf("/SettlementDashboard/getDashboard")>=0)
                    ReactGA.event({
                        category: "Consulta Servidor",
                        action: "Conciliación - Dashboard",
                        label: "Consulta Servidor - Conciliación - Dashboard" // optional
                    });
                else if(url.indexOf("/Settlement/getSettlementCalendar")>=0)
                    ReactGA.event({
                        category: "Consulta Servidor",
                        action: "Conciliación - Calendario",
                        label: "Consulta Servidor - Conciliación - Calendario" // optional
                    });
                else if (url.indexOf("/Settlement/SettlementTransactions")>=0)
                {
                    ReactGA.event({
                        category: "Consulta Servidor",
                        action: "Conciliación - Cupones",
                        label: "Consulta Servidor - Conciliación - Cupones" // optional
                    });
                }
                else if(url.indexOf("/Settlement/getSettlementTaxes")>=0)
                    ReactGA.event({
                        category: "Consulta Servidor",
                        action: "Conciliación - Impuestos",
                        label: "Consulta Servidor - Conciliación - Impuestos" // optional
                    });
                else if(url.indexOf("/Settlement/SettlementListDaily")>=0)
                    ReactGA.event({
                        category: "Consulta Servidor",
                        action: "quidaciones Diarias Pdf",
                        label: "Consulta Servidor - quidaciones Diarias Pdf" // optional
                    });
                else if(url.indexOf("/Settlement/MonthlyESettlementFileList")>=0)
                    ReactGA.event({
                        category: "Consulta Servidor",
                        action: "Liquidaciones Mensuales Pdf",
                        label: "Consulta Servidor - Liquidaciones Mensuales Pdf" // optional
                    });
                else if(url.indexOf("/Settlement/getChargeBacks")>=0)
                    ReactGA.event({
                        category: "Consulta Servidor",
                        action: "Liquidaciones Ajustes",
                        label: "Consulta Servidor - Liquidaciones Ajustes" // optional
                    });
                else if(url.indexOf("/FiscalDocument/searchInvoices")>=0){
                    ReactGA.event({
                        category: "Consulta Servidor",
                        action: "Documentos - Documentación Fiscal",
                        label: "Consulta Servidor - Documentos - Documentación Fiscal" // optional
                    });
                }else if(url.indexOf("/Settlement/SettlementListPayMentReport")>=0)
                    ReactGA.event({
                        category: "Consulta Servidor",
                        action: "Liquidaciones",
                        label: "Consulta Servidor - Liquidaciones" // optional
                    });
                else if(url.indexOf("/Presentation/PresentationList")>=0)
                    ReactGA.event({
                        category: "Consulta Servidor",
                        action: "Reportes - Movimientos Presentados",
                        label: "Consulta Servidor - Reportes - Movimientos Presentados" // optional
                    });
                else if(url.indexOf("/Settlement/SettlementFileList")>=0)
                    ReactGA.event({
                        category: "Consulta Servidor",
                        action: "Reportes - Descarga De Archivos",
                        label: "Consulta Servidor - Reportes - Descarga De Archivos" // optional
                    });
                else if(url.indexOf("/viewerOnboarding/getAllMerchantInfo")>=0)
                    ReactGA.event({
                        category: "Consulta Servidor",
                        action: "Datos del comercio",
                        label: "Consulta Servidor - Datos del comercio" // optional
                    });
                else if(url.indexOf("/viewerOnboarding/getOnboardingQuestionsInternal")>=0)
                    ReactGA.event({
                        category: "Consulta Servidor",
                        action: "Comercio - Agregar RUT/CUIT",
                        label: "Consulta Servidor - Comercio - Agregar RUT/CUIT" // optional
                    });
                else if(url.indexOf("/Account/getUser")>=0)
                    ReactGA.event({
                        category: "Consulta Servidor",
                        action: "Mi Comercio - Configuración Usuarios",
                        label: "Consulta Servidor - Mi Comercio - Configuración Usuarios" // optional
                    });
                else if(url.indexOf("/Terminal")>=0)
                    ReactGA.event({
                        category: "Consulta Servidor",
                        action: "Terminales",
                        label: "Consulta Servidor - Terminales" // optional
                    });
                else if(url.indexOf("/SupportTicket/getTicketStatus")>=0)
                    ReactGA.event({
                        category: "Consulta Servidor",
                        action: "Ayuda - Preguntas Frecuentes",
                        label: "Consulta Servidor - Ayuda - Preguntas Frecuentes" // optional
                    });
                else if(url.indexOf("/QrViewer/getQr")>=0)
                    ReactGA.event({
                        category: "Consulta Servidor",
                        action: "\"Cobrar - QR PCT",
                        label: "Consulta Servidor - \"Cobrar - QR PCT" // optional
                    });
                else if(url.indexOf("/Transaction/qrTransactions")>=0)
                    ReactGA.event({
                        category: "Consulta Servidor",
                        action: "Cobrar - Devolucion QR",
                        label: "Consulta Servidor - Cobrar - Devolucion QR" // optional
                    });
                else if(url.indexOf("/Users/changeUser")>=0)
                    ReactGA.event({
                        category: "Consulta Servidor",
                        action: "Cambio de comercio",
                        label: "Consulta Servidor - Cambio de comercio" // optional
                    });
            }catch (e){console.log(e)}
        }

        axiosApiInstance.interceptors.response.use((response) => {
            HttpService.stopSpinner();
            const url = response?.config.url + "";
            sendGAEvent(url,response)
            if (url.indexOf("/Users/authenticate") >= 0 || url.indexOf("/Users/makeAssertion") >= 0||url.indexOf("/Users/moveToCompany")>= 0 ) {
                WebCache.clearCache();
                // const userStr =
                if (response.data.success){
                    localStorage.setItem("user", JSON.stringify(response.data.data));
                    ReactGA.set({userId:response.data.data.email})
                }
            }
            return response
        }, async function (error) {
            HttpService.stopSpinner();
            if (error.response.status === 401) {
                WebCache.clearCache();
                
                const splittedHref: Array<string> = window.location.href.split('/');
                const currentURL: string = `/${splittedHref[splittedHref.length - 1]}`;

                let authorizedUrls = ['/forgot-password', '/reset-password'];

                if (!authorizedUrls.includes(currentURL)) {
                    WebCache.clearCache();
                    window.location.href = "/#/login";
                }
            }else if (error.response.status === 403) {
                Swal.fire({
                    title: "Permisos",
                    html: "No tiene permisos suficientes",
                    confirmButtonColor: '#f60',
                    timer: 6000,
                    timerProgressBar: true,
                    icon:"warning"
                });
            }
            return Promise.reject(error);
        });
    }


}
