import React, {ChangeEvent, Component, RefObject, useRef} from 'react';
import { Modal, OverlayTrigger, Tooltip as BootstrapTooltip } from 'react-bootstrap';
import { TFunction, withTranslation } from "react-i18next";
import { DateObject } from 'react-multi-date-picker';
import { toast } from 'react-toastify';
import { ReactComponent as IconInfoSVG } from "../../../node_modules/itd-common/assets/icons/alert-information.svg";
import { ReactComponent as IconDownloadSVG } from "../../../node_modules/itd-common/assets/icons/download.svg";
import { ReactComponent as IconDownloadWhiteSVG } from "../../../node_modules/itd-common/assets/icons/icon-download-white.svg";
import { ReactComponent as IconSettingSVG } from "../../../node_modules/itd-common/assets/icons/settings.svg";
import { ReactComponent as HeartOrangeSVG } from "../../../node_modules/itd-common/assets/icons/heart-orange.svg";
import { IDailyFavoriteSettlement, IDailySettlement, IDailySettlements, IFavoriteDailySettlementResponse, IGetDailyFavoriteResponse } from "../../models/ISettlementData";
import { Option } from "../../models/Option";
import { IDailySettlementsRequest, IDownloadDailyESettlement, NewFavoriteDailyRequest } from '../../requests/ISettlementsRequest';
import { FiscalSettlementService } from '../../services/FiscalSettlementService';
import { UtilService } from '../../services/UtilService';
import { WebCache } from "../../services/WebCache";
import CustomReCAPTCHA from '../CustomCaptcha';
import ErrorsView from '../ErrorsView';
import Card from '../containers/card';
import FormFilter from '../containers/form-filter';
import Page from '../containers/page';
import Button from '../controls/buttons/button';
import DateField from '../controls/fields/dateField';
import NumericField from '../controls/fields/numericField';
import SelectField from '../controls/fields/selectField';
import TextField from '../controls/fields/textField';
import { ColumnDefinition, actionColumnDefinition, currencyColumnDefinition, currencyTypeColumnDefinition, dateColumnDefinition, numericColumnDefinition, textColumnDefinition } from '../table/ColumnDefinition';
import CustomTable, { TableModeEnum } from '../table/CustomTable';
import { AxiosResponse } from 'axios';
import './Settlement.scss'
import {UserService} from "../../services/UserService";
import i18next, { t } from "i18next";
import ReactToPrint from "react-to-print";
import NavigationTabLinksForSettlements from "./NavigationTabLinksForSettlements";
import {DateHelper} from "../../helpers/DateHelper";
import { ReactComponent as IconPrintSVG } from "../../../node_modules/itd-common/assets/icons/icon-print-white.svg";
import { ReactComponent as IconTrashSVG } from "../../../node_modules/itd-common/assets/icons/trash.svg";
import { ReactComponent as IconCloseSVG } from "../../../node_modules/itd-common/assets/icons/close.svg";
import CheckBoxField from "../controls/fields/checkBoxField";
import {CurrencyHelper} from "../../helpers/CurrencyHelper";
import { OptionMapperHelper } from '../../helpers/OptionMapperHelper';
import { IPageRequest } from '../../models/IPageRequest';

interface IProps {
    t: TFunction
}

interface IFilters {
    dateRange: Array<DateObject>,
    settlementNumber: string | null,
    type: string,   
    currency: string,
    taxPayer: string,
    amount:string,
    brand:string,
    merchantDocument:string
}

interface IState {
    mainPage: number,
    pageRequest: IPageRequest,
    dailySettlements: IDailySettlements,
    errors: string[],
    columnDefinitions: Array<ColumnDefinition>,
    totalSummaryColumns: Array<ColumnDefinition>,
    favSelected: string,
    favoriteName: string,
    favorites: IDailyFavoriteSettlement[],
    filters: IFilters,
    currencyOptions: Array<HTMLOptionElement>,
    brandOptions: Array<HTMLOptionElement>,
    typeOptions: Array<HTMLOptionElement>,
    isModalOpen: boolean,
    isFavoriteModalOpen: boolean,
    isMakingNewFavorite: boolean,
    hiddenColumnsMap: Map<string, boolean>,
    allFiscalDocuments: Array<Option>,
    isCaptchaVerified:boolean,
    selectedRowsToDowndload: IDailySettlement[],
    showTextInfo: number //se setea el numero del text info a mostrar, por default 0,
    merchantNumbers:Option[],
    maxDayRange:number,
    pages: number[],
    activePage:number
}

const pageSize=20
const pageQuantity=3
const defaultPages = [1, 2, 3]
const T_PREFIX: string = 'pages.settlementsByDay.';
let lastFiscalDocument:string=""


class SettlementsByDay extends Component<|IProps, IState> {
    private componentRef:RefObject<any> = React.createRef();
    private optionMapper: OptionMapperHelper = new OptionMapperHelper(this.props.t);

    constructor(props: IProps) {
        super(props);
        const currencyOptions: Array<HTMLOptionElement> = UtilService.getCurrencyOptions();
        const currencyFilter: string = currencyOptions[0].value;
        const typeOptions: Array<HTMLOptionElement> = this.props.t(`${T_PREFIX}tipos`, { returnObjects: true });
        const type: string = typeOptions[0].value;
        const brandOptions: Array<HTMLOptionElement> = [];

        let columnDefinitions = [
            dateColumnDefinition({ key: 'paymentDate', label: `${T_PREFIX}columns.paymentDate`, header: true, mapValue: (settlement: IDailySettlement) => {
                const date = new Date(parseInt(settlement.paymentDate));
                const day = String(date.getDate()).padStart(2, '0');
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const year = String(date.getFullYear());

                //return `${day}/${month}/${year}`;
                return settlement.paymentDate;
            } }),
            dateColumnDefinition({ key: 'presentationDate', label: `${T_PREFIX}columns.presentationDate`, hidden: true, mapValue: (settlement: IDailySettlement) => {
                const date = new Date(parseInt(settlement.presentationDate));
                const day = String(date.getDate()).padStart(2, '0');
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const year = String(date.getFullYear());

                //return `${day}/${month}/${year}`;
                return settlement.presentationDate;
            } }),
            textColumnDefinition({ key: 'settlmentNumber', label: `${T_PREFIX}columns.settlmentNumber`, header: true }),
            numericColumnDefinition({ key: 'merchantNumber', label: `${T_PREFIX}columns.merchantNumber`, header: true }),
            textColumnDefinition({ key: 'saleType', label: `${T_PREFIX}columns.saleType`, header: true }),
            currencyTypeColumnDefinition({ key: 'currencyCode', label: `${T_PREFIX}columns.currencyCode`, header: true }),
            textColumnDefinition({ key: 'payerEntityDesc', label: `${T_PREFIX}columns.payerEntityDesc`, hidden: true }),
            textColumnDefinition({ key: 'bankAccount', label: `${T_PREFIX}columns.bankAccount`, hidden: true }),
            //currencyColumnDefinition({ key: 'grossAmount', label: `${T_PREFIX}columns.grossAmount`, header: true, columnDefinitionKey:'currencyCode' }),
            //currencyColumnDefinition({ key: 'discountAmount', label: `${T_PREFIX}columns.discountAmount`, header: true, columnDefinitionKey:'currencyCode' }),
            //currencyColumnDefinition({ key: 'netAmount', label: `${T_PREFIX}columns.netAmount`, header: true , columnDefinitionKey:'currencyCode'}),
            textColumnDefinition({ key: 'productDesc', label: `${T_PREFIX}columns.productDesc`, hidden: true , mapValue: (settlement: IDailySettlement) => {
                const product= settlement.productDesc;
                if (product == "Adelanto Liquidación Visa"){
                    return <div className="imgDiv">
                                <img src={UtilService.getImageTag(product)} alt={`${product}`} className="imgSize"/>
                                <p >Adelanto Liq.</p>
                            </div>    
                }else if (product == "Adelanto Liquidación Master"){
                    return <div className="imgDiv">
                                <img src={UtilService.getImageTag(product)} alt={`${product}`} className="imgSize"/>
                                <p>Adelanto Liq.</p>
                            </div>                  
                } else {
                return <img src={UtilService.getImageTag(product.toUpperCase())} alt={`${product}`} ></img>;
            }
            }}),
            //numericColumnDefinition({ key: 'marketingTariff', label: `${T_PREFIX}columns.marketingTariff`, hidden: true }),//ArancelAdicional
            //numericColumnDefinition({ key: 'refundTariff', label: `${T_PREFIX}columns.refundTariff`, hidden: true }),//ReintegroArancel
            //numericColumnDefinition({ key: 'gainsTaxArg', label: `${T_PREFIX}columns.gainsTaxArg`, hidden: true }),//RetenciónGanancias
            //numericColumnDefinition({ key: 'settlementTaxRNI', label: `${T_PREFIX}columns.settlementTaxRNI`, hidden: true }),//Iva S/Arancel IVA RNI O SNC
            //numericColumnDefinition({ key: 'settlementTaxPCP', label: `${T_PREFIX}columns.settlementTaxPCP`, hidden: true }),//IVA PERC
            //numericColumnDefinition({ key: 'settlementTaxRI', label: `${T_PREFIX}columns.settlementTaxRI`, hidden: true }),//iva s/arancel
            //numericColumnDefinition({ key: 'retentionGrossAmount', label: `${T_PREFIX}columns.retentionGrossAmount`, hidden: true }),//RetenciónIB
            //numericColumnDefinition({ key: 'retentionTax', label: `${T_PREFIX}columns.retentionTax`, hidden: true }),//Retención Iva  s/ventas
            //numericColumnDefinition({ key: 'discountAmountArg', label: `${T_PREFIX}columns.discountAmountArg`, hidden: true }),//ImporteArancel
            //numericColumnDefinition({ key: 'discountFinOtor', label: `${T_PREFIX}columns.discountFinOtor`, hidden: true }),//DTO. FINANCIERO PLAN CUOTAS
            //numericColumnDefinition({ key: 'otherConceptsAmount', label: `${T_PREFIX}columns.otherConceptsAmount`, hidden: true }),//Otros conceptos
            //textColumnDefinition({ key: 'concepts', label: `${T_PREFIX}columns.totalSummary`, hidden: true }),//Otros conceptos
            actionColumnDefinition({ key: 'edit', header: true, action: { function: (rowObject: IDailySettlement) => {
                //console.log("Download...");
                toast.info(this.props.t("settlementsDownloadFileNotification"));
                this.getDowloadESettlement(rowObject.settlmentNumber,rowObject.settlmentNumber,rowObject.merchantNumber,rowObject.productCode,rowObject.productDesc);
            }, icon: <IconDownloadSVG className='icon-download ms-5'></IconDownloadSVG>} })
        ];

        const hiddenColumnsMap: Map<string, boolean> = new Map();
        columnDefinitions.forEach(columnDefinition => hiddenColumnsMap.set(columnDefinition.key, columnDefinition.hidden || false));

        this.state = {
            mainPage: 0,
            pageRequest: {
                Skip: 0,
                Take: 0
            },
            showTextInfo: 0,
            pages: defaultPages,
            activePage: 1,
            maxDayRange:31,
            selectedRowsToDowndload: [],
            dailySettlements: {
                dailySettlements: [] as IDailySettlement[]
            } as IDailySettlements,
            errors: [],
            columnDefinitions,
            totalSummaryColumns:[],
            favorites: [],
            favSelected: '',
            favoriteName: '',
            filters: {
                dateRange: [],
                settlementNumber: '',
                amount:'',
                type,
                currency: currencyFilter,
                taxPayer: WebCache.getAllMerchantDocuments(false,true)[0]?.key+"",
                brand:'',
                merchantDocument:""
            },
            brandOptions,
            currencyOptions,
            typeOptions,
            isModalOpen: false,
            isFavoriteModalOpen: false,
            isMakingNewFavorite: false,
            hiddenColumnsMap,
            merchantNumbers:OptionMapperHelper.getAllMerchantNumbersOptions(WebCache.getAllMerchantDocuments(false,true)[0]?.label,true,true),
            allFiscalDocuments: WebCache.getAllMerchantDocuments(false,true),
            isCaptchaVerified: false
        };
        this.getProducts();
        this.getFavorites();
    }  
    
    getDownloadDailyESettlementRequest = (fileName: string, settlmentNumber: string, merchantNumber: string,productCode:string, productDesc:string): IDownloadDailyESettlement => {
        const result: IDownloadDailyESettlement = {
            FileName: fileName,
            SettlementNumber: parseInt(settlmentNumber),
            FiscalNumber: this.state.filters.taxPayer,
            MerchantNumber:merchantNumber,
            ProductCode:productCode,
            ProductDesc:productDesc
        };  
        return result;
    }

    getProducts = () => {
        FiscalSettlementService.getAllProducts()
            .then((response: Array<HTMLOptionElement>) => {     
                
                const item: HTMLOptionElement = {
                    value: "",
                    label: "Todas"
                } as HTMLOptionElement;
                response = [item, ...response];   
            this.setState({
                ...this.state,
                brandOptions: response
            });

        }).catch((error: any) => {   
        });         
    }

    handleCaptchaVerification = (verified: boolean) => {
        this.setState({ isCaptchaVerified: verified });
    };

    getDowloadESettlement = (fileName: string, settlmentNumber: string, merchantNumber: string,productCode:string,productDesc:string) => {
        if (this.state.isCaptchaVerified) {
            console.log(fileName);
            FiscalSettlementService.getDowloadDailyESettlement(this.getDownloadDailyESettlementRequest(fileName, settlmentNumber, merchantNumber,productCode,productDesc))
                .then((response: AxiosResponse<Blob>) => {
                })
                .catch((error) => {
                    this.setState({  errors: ["Error obteniendo datos del servidor"] });
                    console.log('error:', error);
                });
        } else {
            toast.error("Por favor, verifica el CAPTCHA antes de descargar el archivo");
        }
    };

    getRequest = (all: boolean): IDailySettlementsRequest => {
        const tranWebModel: IDailySettlementsRequest = {
            SettlementNumber: this.state.filters.settlementNumber,
            From: DateHelper.dateObjectToString(this.state.filters.dateRange[0]),
            To: DateHelper.dateObjectToString(this.state.filters.dateRange[1]),
            Type: "",
            Amount:this.state.filters.amount,
            TypeSell: this.mapType(this.state.filters.type) ,
            DateRangeType:"",
            Currency: this.state.filters.currency,
            MerchantDocument: this.state.filters.taxPayer,
            MerchantNumber:this.state.filters.merchantDocument,
            ProductDesc:this.state.filters.brand,
            Skip: all ? 0 : (this.state.mainPage * pageSize * pageQuantity),
            Take: all ? 0 : ((pageSize * pageQuantity) + 1),
        };
        return tranWebModel;
    }

    downloadZip = () =>{
        if (this.state.isCaptchaVerified) {
            FiscalSettlementService.getDownloadSettlementZip(this.getRequest(false))
                .then((response: AxiosResponse<ArrayBuffer>) => {
                })
                .catch((error) => {
                    this.setState({  errors: ["Error obteniendo datos del servidor"] });
                    console.log('error:', error);
                });
        } else {
            toast.error("Por favor, verifica el CAPTCHA antes de descargar el archivo");
        }
    }; 

    componentDidUpdate(prevProps:any, prevState:any) {
        if(lastFiscalDocument!=this.state.filters.taxPayer){
            lastFiscalDocument=this.state.filters.taxPayer;
            let merchantNumbers= OptionMapperHelper.getAllMerchantNumbersOptions(lastFiscalDocument,true,true)
            this.setState({...this.state,filters:{...this.state.filters,merchantDocument:merchantNumbers[0].value},merchantNumbers:merchantNumbers})
        }
    }

    mapType = (type:string) => {
        if(type=="Todos")
            return "all"
        if(type=="Diario")
            return "D"
        if(type=="Recupero")
            return "R"
        if(type=="Adelanto")
            return "A"
        if(type=="A")
            return "Adelanto"
        if(type=="R")
            return "Recupero"
        if(type=="D")
            return "Diario"
        return ""
    }
    
    getLiquidations = () => {

        let dateFrom= DateHelper.dateObjectToDate(this.state.filters.dateRange[0]);
        let dateTo= DateHelper.dateObjectToDate(this.state.filters.dateRange[1]);
        let diffDays =
            Math.round
            ((dateTo.getTime() - dateFrom.getTime()) / (1000 * 3600 * 24));
        if(diffDays>this.state.maxDayRange){
            toast.error(("El maximo de días entre las fechas es" )+" "+this.state.maxDayRange);
            return;
        } 

        FiscalSettlementService.getDailySettlements(this.getRequest(false)).then((response: IDailySettlements) => {
            if(response!=null&&response.dailySettlements!=null&&response.dailySettlements.length>0){
                for(let i in response.dailySettlements){
                    response.dailySettlements[i].saleType=this.mapType( response.dailySettlements[i].saleType);
                }

                var columnDefinitions=this.state.columnDefinitions;
                var totalSummaryColumns=this.state.totalSummaryColumns;
                for(var i in totalSummaryColumns){
                    columnDefinitions=columnDefinitions.filter(x=>x.key!=this.state.totalSummaryColumns[i].key);
                }
                totalSummaryColumns=[];
                if(response!=null&&response.dailySettlements!=null){
                    var index=0;
                    for(var i in response.dailySettlements){
                        for(var j in  response.dailySettlements[i].concepts){
                            var name=response.dailySettlements[i].concepts[j].name.toLocaleLowerCase();
                            var currentColumn=totalSummaryColumns.filter(x=>x.label==name);
                            if(currentColumn.length==0){
                                totalSummaryColumns.push(currencyColumnDefinition({ key: 'concept'+index, label: name, header: true,hidden:name.toUpperCase().indexOf("TOTAL LIQUIDACION")<0, columnDefinitionKey:'currencyCode' }));
                                columnDefinitions.push(currencyColumnDefinition({ key: 'concept'+index, label:  name, header: true,hidden:name.toUpperCase().indexOf("TOTAL LIQUIDACION")<0, columnDefinitionKey:'currencyCode' }));
                                // @ts-ignore
                                response.dailySettlements[i]['concept'+index]=response.dailySettlements[i].concepts[j].amount;
                                index++;
                            }else {
                                // @ts-ignore
                                response.dailySettlements[i][currentColumn[0].key]=response.dailySettlements[i].concepts[j].amount;

                            }

                        }
                    }
                }

                var hiddenColumnsMap: Map<string, boolean> = new Map();
                columnDefinitions.forEach(columnDefinition => hiddenColumnsMap.set(columnDefinition.key, columnDefinition.hidden || false));

                this.setState({...this.state,
                    hiddenColumnsMap,
                    columnDefinitions,
                    totalSummaryColumns,
                    dailySettlements: response,
                    errors: []
                });
            }
        }).catch(error => {
            this.setState({...this.state,
                errors: ["Error obteniendo datos del servidor"],
                dailySettlements: {
                    dailySettlements: [] as IDailySettlement[]
                } as IDailySettlements
            })
        });
    }

    searchFilter = () => {
        this.setState({
            ...this.state, 
            activePage: 1, 
            pages: defaultPages,
            mainPage: 0
        }, () => {
            this.getLiquidations();
        });
    }

    setFilters(filters: IFilters) {
        this.setState({ ...this.state, filters });
    }
    setFavSelected(value: string) {
        this.setState({ ...this.state, favSelected: value });
    }

    clearFilter = () => {
        this.setState({
            ...this.state,
            filters: {
                dateRange: [],
                settlementNumber: '',
                amount:'',
                type:"",
                currency: "",
                taxPayer: "",
                brand:'',
                merchantDocument:""
            },
            dailySettlements: {
                dailySettlements: [] as IDailySettlement[]
            } as IDailySettlements
        });      
    }

    downloadGrid = () => {
        let fileName="Liquidación diaria.csv";
        UserService.getCsvOnline(this.state.columnDefinitions.filter(x=>x.action==null).map(x=>i18next.t(x.label+"")).join(";")+"\r\n"+
            // @ts-ignore
            this.state.dailySettlements.dailySettlements.map(y=>this.state.columnDefinitions.map(x=>y[x.key]).join(";")).join("\r\n"), fileName).then((response: AxiosResponse<any>) => {
            //const outputFilename = `TerminalsReport${Date.now()}.xlsx`;
            // @ts-ignore
            /*let outputFilename = response.headers['content-disposition']
                .split(';')
                .find((n: any) => n.includes('filename='))
                .replace('filename=', '')
                .trim();*/
            const url = URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            link.click();
        }).catch(error => {
            console.log('error:', error);
        });
    }
    
    handleClickModal = () => {
        this.setState({
            ...this.state, isModalOpen: !this.state.isModalOpen
        });
    }
    handleClickFavoriteModal = () => {
        this.setState({ ...this.state, isFavoriteModalOpen: !this.state.isFavoriteModalOpen });
    }
    addNewFavorite() {
        if(this.state.favorites.findIndex((item)=> item.name.toLocaleLowerCase() === this.state.favoriteName.toLocaleLowerCase()) >= 0){
            toast.error(t(`${T_PREFIX}favoriteRepeatedMessage`).toString());
            this.setState({...this.state,
                favoriteName: ""
            });
        }else{
            let request: NewFavoriteDailyRequest= {
                name: this.state.favoriteName,
                dateFrom:DateHelper.dateObjectToString(this.state.filters.dateRange[0]),
                dateTo:DateHelper.dateObjectToString(this.state.filters.dateRange[1]),
                rut:this.state.filters.taxPayer? this.state.filters.taxPayer: "",
                codComercio:this.state.filters.merchantDocument ? this.state.filters.merchantDocument: "",
                brand:this.state.filters.brand? this.state.filters.brand: "",
                currency:this.state.filters.currency? this.state.filters.currency: "",
                numLiquidation:this.state.filters.settlementNumber ? this.state.filters.settlementNumber: "",
                amount:this.state.filters.amount?Number(this.state.filters.amount): 0,
                type:this.state.filters.type? this.state.filters.type: ""
            }
            FiscalSettlementService.addDailyFavoriteSettlement(request).then((response: IFavoriteDailySettlementResponse) => {
                if(response?.success){
                    this.setState({...this.state, 
                        favorites: response?.data,
                        favoriteName: ""
                    });
                    toast.success(t(`${T_PREFIX}${response.message}`).toString());
                }
            }).catch(error => {
                console.log('error:', error);
            });
        }
    }
    deleteFavorite(name:string){
        FiscalSettlementService.deleteDailyFavoriteSettlement(name).then((response: IFavoriteDailySettlementResponse) => {
            if(response?.success){
                if(response.message==="favoriteRepeatedMessage"){
                    toast.error(t(`${T_PREFIX}${response.message}`).toString());
                }else{
                    toast.success(t(`${T_PREFIX}${response.message}`).toString());
                    this.setState({...this.state, favorites: response?.data});
                }
            }
        }).catch((error: any) => {
            console.log('error:', error);
        });
    }
    applyFavorite(value: IDailyFavoriteSettlement){
        this.setState({...this.state, 
            filters: {
                ...this.state.filters,
                dateRange: [new DateObject(new Date(value.dateFrom)), new DateObject(new Date(value.dateTo))],
                amount: value.amount,
                brand: value.brand,
                currency: value.currency,
                merchantDocument: value.codComercio,
                settlementNumber: value.numLiquidation,
                taxPayer: value.rut,
                type: value.type
            },
            isFavoriteModalOpen: !this.state.isFavoriteModalOpen
        });
        
    }
    getFavorites(){
        FiscalSettlementService.getDailySettlementFavorites().then((response: IDailyFavoriteSettlement[]) => {
            if(response)
                this.setState({...this.state, favorites: response});
        }).catch(error => {
            console.log('error:', error);
        });
    }

    handleClickOptionModal = (event: ChangeEvent<HTMLInputElement>) => {
    let values: Map<string, boolean> = this.state.hiddenColumnsMap;
        values.set(event.target.id, !values.get(event.target.id));
        this.setState({
            ...this.state, hiddenColumnsMap: values
        });
    }

    changeColumnsTable = () => {
        let columnDefinitions: Array<ColumnDefinition> = [...this.state.columnDefinitions];
        this.state.columnDefinitions.forEach((columnDefinition, columnDefinitionIndex) => {
            columnDefinitions[columnDefinitionIndex].hidden = this.state.hiddenColumnsMap.get(columnDefinition.key);
        });
        
        this.setState({...this.state, columnDefinitions, isModalOpen: false});
    }

    renderTotalNetAmounts = (dailySettlements: IDailySettlements, isCurrencyUSD: Boolean) : JSX.Element=> {
        const { t } = this.props;
        let totalNetAmount= "";
        if(isCurrencyUSD){
            // Neto total para la moneda Pesos uruguayos
            totalNetAmount = Intl.NumberFormat('es-UY', { style: 'currency', currency: CurrencyHelper.getCurrencySymbol("858"), maximumFractionDigits: 0 }).format(
                    dailySettlements.dailySettlements?.reduce((acc, dailySettlement) => {
                        return dailySettlement.currencyCode != '840' ? acc + dailySettlement.netAmount : acc;
                    }, 0)
                );
        }else {
            // Neto total para la moneda de USD
            totalNetAmount = Intl.NumberFormat('es-UY', { style: 'currency', currency: CurrencyHelper.getCurrencySymbol("840"), maximumFractionDigits: 0 }).format(
                    dailySettlements.dailySettlements?.reduce((acc, dailySettlement) => {
                        return dailySettlement.currencyCode === '840' ? acc + dailySettlement.netAmount : acc;
                    }, 0)
                );
        }
        return <>
            <div className='d-flex d-inline'>
                <label className="form-label mt-0 w-70 fs-5"> {t('netTotal')}</label>
                <span className='w-50'>
                    <OverlayTrigger
                        placement={'top'}
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                            <BootstrapTooltip>
                                {this.renderTextInfo()}
                            </BootstrapTooltip>
                        }
                    >
                        <a className='float-right button-text-info-1' type='button'>
                            <IconInfoSVG className='fs-3 pe-2'></IconInfoSVG>
                        </a>
                    </OverlayTrigger>
                </span>
            </div>
            <label  className="border-start-0 ms-0 mt-2 amount-label" >
                {totalNetAmount}
            </label>
        </>
    }

    renderTextInfo = () : JSX.Element => {
        const { t } = this.props;
        return <div className={`text-info`}>
                    <p className='p-3 text-white'>{t(`${T_PREFIX}text-info-total`)}</p>
                </div>
    }

    render() {
        const { t } = this.props;
        const currentUser = WebCache.getCurrentUser()?.countryId;
        let currency;
        let taxPayerId;
        

        if (currentUser !== undefined && currentUser === 2) {
            currency =                                             <div className="col-md-3">
            <SelectField attr='currency' label='currency' options={this.state.currencyOptions} ></SelectField>
        </div>
            taxPayerId=`${T_PREFIX}merchantURU`
        } else {
            currency = ''
            taxPayerId=`${T_PREFIX}merchantARG`
        }	
        
        return (
            <Page >
                <>
                
                    <h2>{t(`settlements`)}</h2>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <ErrorsView errors={this.state.errors}/>
                        </div>
                    </div>
                    <div className="row" ref={this.componentRef}>
                        <div className="col-lg-12">
                            <div className='row'>
                                <div className="col-md-8 mt-2">
                                    <NavigationTabLinksForSettlements></NavigationTabLinksForSettlements>
                                </div>
                                <div className='col-md-4 fav-select pe-1´'>
                                    <a type='button' onClick={()=> console.log()}>
                                        <HeartOrangeSVG></HeartOrangeSVG>
                                    </a>
                                    <a className='mx-2 mt-2' type='button' onClick={()=> this.handleClickFavoriteModal()}>
                                        <h6>{t(T_PREFIX+'myFavorites')}</h6>
                                    </a>
                                </div>
                            </div>
                            <FormFilter 
                                onSubmit={this.searchFilter} 
                                clear={this.clearFilter}
                                model={[ this.state.filters, this.setFilters.bind(this) ]} >
                                    <>
                                        <div className="row form-Settlement mt-2">
                                            <div className="col-md-3">
                                                <DateField attr="dateRange" label="period" range ></DateField>
                                            </div>
                                            <div className="col-md-3">
                                                <TextField attr="settlementNumber" label={`${T_PREFIX}searchSettlementNumber`} ></TextField>
                                            </div>
                                            <div className="col-md-3">
                                                <SelectField
                                                    attr='taxPayer'
                                                    label={taxPayerId}
                                                    options={this.state.allFiscalDocuments}
                                                ></SelectField>
                                            </div>
                                        <div className="col-md-3">
                                            <SelectField attr='merchantDocument' label={`${T_PREFIX}merchantNumber`}
                                                        options={this.optionMapper.translateOptionValues(this.state.merchantNumbers)}></SelectField>
                                        </div>
                                            <div className='col-md-3'>
                                                <SelectField attr='brand' label={t(`${T_PREFIX}brand`)}
                                                            options={this.state.brandOptions}></SelectField>
                                            </div> 
                                            {currency}
                                            <div className="col-md-3">
                                                <NumericField attr="amount" label={`${T_PREFIX}amount`} ></NumericField>
                                            </div>
                                            <div className="col-md-3">
                                                <SelectField attr='type' label='type' options={this.state.typeOptions} ></SelectField>
                                            </div>
                                        </div>
                                    </>
                            </FormFilter>
                            <section className="row pb-4 ms-1">
                                {
                                    WebCache.getCurrentUser()?.countryId != 1 &&
                                        <div className="col-md-3 div-info me-4" style={{textAlign:"start"}}>
                                            {this.renderTotalNetAmounts(this.state.dailySettlements, false)}
                                        </div>
                                }
                                <div className="col-md-3 div-info" style={{textAlign:"start"}}>
                                    {this.renderTotalNetAmounts(this.state.dailySettlements, true)}
                                </div>
                            </section>
                            <nav className="nav mt-3">
                                <span className="vertical-align">
                                    <Button color="transparent" className="border-0 mt-0" noBlock onClick={()=> this.handleClickModal()}>
                                        <>
                                            <IconSettingSVG></IconSettingSVG>
                                            <span className='ms-2 text-dark' >{t(`common.action.tableConfig`)}</span>
                                        </>
                                    </Button>   
                                </span>                                    
                                <span className="navbar-text ms-auto mb-3 col-md-9 fs-5 pe-0 d-flex justify-content-end">
                                    <ReactToPrint
                                        trigger={() => <Button color="primary" className="border-0 mt-0 me-3" noBlock>
                                            <>
                                                <span className='ms-2 me-2 text-white'>{t(`common.action.print`)}</span>
                                                <IconPrintSVG></IconPrintSVG>
                                            </>
                                        </Button>}
                                        content={() => this.componentRef.current}
                                    />
                                    <Button color="primary" className="border-0 mt-0 me-3" onClick={()=> this.downloadGrid()} noBlock>
                                        <>
                                            <span className='ms-2 me-2'>{t(`common.action.downloadCsv`)}</span>
                                            <IconDownloadWhiteSVG></IconDownloadWhiteSVG>
                                        </>
                                    </Button>
                                    <Button color="primary" className="border-0 mt-0" onClick={()=> this.downloadZip()} noBlock>
                                        <>
                                            <span className='ms-2 me-2'>{t(`common.action.downloadAll`)}</span>
                                            <IconDownloadWhiteSVG></IconDownloadWhiteSVG>
                                        </>
                                    </Button> 
                                </span>
                            </nav>
                            <div className='row'>
                                <div className="col-md-12 settlementTable">
                                    <CustomTable
                                        rowObjects={this.state.dailySettlements.dailySettlements}
                                        columnDefinitions={this.state.columnDefinitions}
                                        config={{
                                            pageSize:pageSize,
                                            mode: TableModeEnum.GRID,
                                            paginator: {
                                                quantity:pageQuantity,
                                                mainPage: this.state.mainPage,
                                                modifyMainPage: (value: number) => {
                                                    this.setState({
                                                        mainPage: this.state.mainPage + value
                                                    }, () => this.getLiquidations());
                                                },
                                                pageRequest: this.state.pageRequest
                                            },
                                        }}
                                    ></CustomTable>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-6' >
                                </div>
                                <div className="col-md-6">
                                    <CustomReCAPTCHA onVerify={this.handleCaptchaVerification}></CustomReCAPTCHA>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal show={this.state.isModalOpen} size='lg'>
                        <>
                            <div className='mb-2'>
                                <h2>{t(`${T_PREFIX}tableConfigTitle`)}</h2>
                                {/* <h6>{t(`${T_PREFIX}tableConfigDescription`)}</h6> */}
                            </div>
                            <div className="row">
                                { 
                                    Array.from(this.state.hiddenColumnsMap).map((entry: [string, boolean]) => {
                                        const [key, value]: [string, boolean] = entry;
                                        return (
                                            <div className="col-md-4" key={key} >
                                                <CheckBoxField id={key} checked={!value} label={this.state.columnDefinitions.filter(x=>x.key==key)[0].label+""} onChange={(event) => this.handleClickOptionModal(event)} ></CheckBoxField>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className='d-flex flex-row-reverse gap-4'>
                                <Button className='mt-2 ps-8 pe-8' label='accept' onClick={() => this.changeColumnsTable()}/>
                                <Button className='mt-2 ps-8 pe-8' color='white' label='cancel' onClick={() => this.setState({...this.state, isModalOpen: false})} />
                            </div>
                        </>
                    </Modal>
                    <Modal show={this.state.isFavoriteModalOpen} size='sm'>
                        <div className='p-5 h-100'>
                            <div className='row'>
                                <div className='col-md-6 mb-2'>
                                    <h2>{t(T_PREFIX+`favorites`)}</h2>
                                </div>
                                <div className='col-md-6 d-flex justify-content-end'>
                                    <a type='button' onClick={()=> this.handleClickFavoriteModal()}>
                                        <IconCloseSVG></IconCloseSVG>
                                    </a>
                                </div>
                            </div>
                            <div className="row pb-2">
                                { 
                                    <ul className="favorite-list">
                                        { this.state.favorites.map((fav)=>{
                                            return <li className='d-flex inline-block align-items-center mb-1 favorite-button'>
                                                <a type='button' className='w-80' onClick={()=> this.applyFavorite(fav)}>
                                                    <p className='p-2 mb-0'>{fav.name}</p> 
                                                </a>
                                                <a type='button' className='ms-5 float-right w-20' onClick={()=> this.deleteFavorite(fav.name)}>
                                                    <IconTrashSVG></IconTrashSVG>
                                                </a>
                                            </li>
                                        })}
                                    </ul>
                                }
                            </div>
                            <div className="row justify-content-center">
                                <a type='button' onClick={()=> this.setState({...this.state, isMakingNewFavorite: true})} className='text-center favorite-button favorite-add-button py-2 w-60'>
                                    <span>{t('common.action.addFavorite')}</span>
                                </a>
                            </div>
                            { this.state.isMakingNewFavorite &&
                                <>
                                    <div className="row mt-5">
                                        <TextField  attr="favoriteName" model={[ this.state, this.setState.bind(this) ]} label='Nombre'></TextField>
                                    </div>
                                    <div className='row'>
                                        <Button className={`mt-2 ${ this.state.favoriteName?.length === 0 && "disabled" }`} label='accept' onClick={() => this.addNewFavorite()}/>
                                        <Button className='mt-2' label='cancel' color='white' onClick={()=> this.setState({...this.state, isMakingNewFavorite: false, favoriteName: ''})}/>
                                    </div>
                                </>
                            }
                        </div>
                    </Modal>
                </>
            </Page>
                        
        )
    }
}  
export default withTranslation() (SettlementsByDay);
