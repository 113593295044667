import { axiosApiInstance } from "./HttpService";
import {IOnboardingQuestionValidationRequest, GetAllMerchantInfoRequest} from "../models/IOnboardingData";

export class OnboardingService {

    public static createMerchant(request: any) {
        let dataURL: string = `${process.env.REACT_APP_API_URL}/mconboarding/OnboardingMerchant/createMerchant`;
        return axiosApiInstance.post(dataURL, request);
    }



    public static onboardUser(email: string) {
        let dataURL: string = `${process.env.REACT_APP_API_URL}/mconboarding/OnboardingMerchant/onboardUser`;
        return axiosApiInstance.post(dataURL, email);
    }

    public static async getInitialQuestions(fiscalDocument:string, merchantNumber:string,email:string) {
        const dataURL: string = `${process.env.REACT_APP_API_URL}/api/viewerOnboarding/getOnboardingQuestions`;
        try {
            return await axiosApiInstance.post(dataURL,{ merchantNumber: merchantNumber,fiscalDocument: fiscalDocument,email:email});
        } catch (error) {
            throw error;
        }
    }

    public static async getOnboardingQuestionsInternal(fiscalDocument:string, merchantNumber:string,email:string) {
        const dataURL: string = `${process.env.REACT_APP_API_URL}/api/viewerOnboarding/getOnboardingQuestionsInternal`;
        try {
            return await axiosApiInstance.post(dataURL,{ merchantNumber: merchantNumber,fiscalDocument: fiscalDocument,email:email});
        } catch (error) {
            throw error;
        }
    }

    public static async validateInitialQuestions(request:IOnboardingQuestionValidationRequest) {
        const dataURL: string = `${process.env.REACT_APP_API_URL}/api/viewerOnboarding/validateInitialQuestions`;
        try {
            return await axiosApiInstance.post(dataURL,request);

        } catch (error) {
            throw error;
        }
    }

    public static async getAllMerchantInfo (request: GetAllMerchantInfoRequest){ 
        let dataURL: string = `${process.env.REACT_APP_API_URL}/api/viewerOnboarding/getAllMerchantInfo`;
        return axiosApiInstance.post(dataURL, request);
    }

    public static async addMerchantToClient (request: IOnboardingQuestionValidationRequest){ 
        let dataURL: string = `${process.env.REACT_APP_API_URL}/api/viewerOnboarding/addMerchantToClient`;
        return axiosApiInstance.post(dataURL, request);
    }
    public static async addMerchantToClientMultiCompany (request: IOnboardingQuestionValidationRequest){ 
        let dataURL: string = `${process.env.REACT_APP_API_URL}/api/viewerOnboarding/addMerchantToConciliator`;
        return axiosApiInstance.post(dataURL, request);
    }
}
