import { Component } from 'react';
import NavigationTabLinks from '../containers/navigationTabLinks';

class NavigationTabLinksForAdvanced extends Component {
    render() {
        return (
            <NavigationTabLinks
                items={[
                    { to: '/advanced/search', icon: 'fe fe-search', label: `pages.advanced.titleSearch`},
                    { to: '/advanced/historic', icon: 'fe fe-calendar', label: `pages.advanced.titleHistoric` },
                ]} 
            ></NavigationTabLinks>
        )
    };
}

export default NavigationTabLinksForAdvanced;
