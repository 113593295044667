import { AxiosResponse } from 'axios';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { DateObject } from "react-multi-date-picker";
import {
    DateRangeType, IOperationTableRequest,
    IPresentationRequest, IPresentationTotal,
} from "../../models/ISettlementData";
import { SettlementService } from "../../services/SettlementService";
import ErrorsView from '../ErrorsView';
import FormFilter from '../containers/form-filter';
import DateField from '../controls/fields/dateField';
import "./SettlementReport.css";
import SettlementTotal from "./SettlementTotal";
import RadioButtonGroup from '../controls/fields/radioButtonGroup';
import CustomTable, { TableModeEnum } from '../table/CustomTable';
import {
    ColumnDefinition,
    dateColumnDefinition,
    numericColumnDefinition,
    textColumnDefinition,
    currencyTypeColumnDefinition,
    currencyColumnDefinition,
    percentageColumnDefinition
} from '../table/ColumnDefinition';
import { IPageRequest } from '../../models/IPageRequest';
import Page from '../containers/page'
import NavigationTabLinksForReports from './NavigationTabLinksForReports';
import Card from '../containers/card';
import { UtilService } from '../../services/UtilService';
import { DateHelper } from '../../helpers/DateHelper';
import SelectField from '../controls/fields/selectField';
import TextField from '../controls/fields/textField';
import { OptionMapperHelper } from '../../helpers/OptionMapperHelper';
import { WebCache } from '../../services/WebCache';
import Button from '../controls/buttons/button';
import { toast } from "react-toastify";
import CustomReCAPTCHA from '../CustomCaptcha';
import { t } from 'i18next';
import {Option} from "../../models/Option";


interface IProps {
    t:any
}

interface IFilters {
    dateRangeType: DateRangeType,
    fiscalDocument: string,
    merchantDocument: string
    currency: string
    dateRange: DateObject[]
}

interface IState {
    filters: IFilters,
    fiscalDocuments:Option[],
    merchantNumbers:Option[],
    currencyOptions: Array<HTMLOptionElement>,
    mainPage: number,
    pageRequest: IPageRequest,
    operations: IPresentationTotal[],
    errors: string[],
    rowDetail: { title: string, total: string, data: Array<{ prop: string, value: string }> } | null,
    tableMode: TableModeEnum,
    isCaptchaVerified:boolean
}
let doQuery = false;

let lastFiscalDocument:string=""
const T_PREFIX: string = 'pages.operationTableReport.';
class OperationTableReport extends React.Component<IProps, IState>{
    private optionMapper: OptionMapperHelper = new OptionMapperHelper(this.props.t);
    private translateFnc: any;
    private columnDefinitions: Array<ColumnDefinition>;

    updateDimensions = () => {
        /*
        const { innerWidth: width, innerHeight: height } = window;
        if(UtilService.isSmallScreen()&&this.state.columnsToShow.length==0){
            this.setState({...this.state,columnsToShow:[
                    "TERMINAL","AUTHDATETIME","TRANSACTIONTYPE","STATE","RESPONSECODE","TOTALAMOUNTFORREPORT","ISSUER","ACQUIRER"
                ]});
        }else if(width>992&&this.state.columnsToShow.length>0){
            this.setState({...this.state,columnsToShow:[]});
        }
        */
    };

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener('resize', this.updateDimensions);

        if(doQuery) {
            doQuery = false;
            this.getSettlements();
        }

        this.translateFnc = this.props.t;
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    constructor(props: IProps) {
        super(props);

        let dateRangeType: DateRangeType = "PAYMENT_DATE";
        const currencyOptions: Array<HTMLOptionElement> = UtilService.getCurrencyOptions();

        this.state = {
            filters: {
                dateRangeType,
                fiscalDocument:WebCache.getAllMerchantDocuments(false,true)[0].label+"",
                merchantDocument:"" ,
                currency:"",
                dateRange: DateHelper.buildDateRange()
            },
            merchantNumbers:OptionMapperHelper.getAllMerchantNumbersOptions( WebCache.getAllMerchantDocuments(false,true)[0].label,true,true),
            fiscalDocuments:WebCache.getAllMerchantDocuments(false,true),
            currencyOptions,
            mainPage: 0,
            pageRequest: {
                Skip: 0,
                Take: 0
            },
            operations: [],
            errors: [],
            rowDetail: null,
            tableMode: TableModeEnum.LIST,
            isCaptchaVerified: false

        }

        const T_PREFIX_COLUMN = `${T_PREFIX}table.column.`;
        this.columnDefinitions = [
            textColumnDefinition({ key: 'merchantNumber', label: `${T_PREFIX_COLUMN}merchantNumber`, header: true }),
            textColumnDefinition({ key: 'bin', label: `${T_PREFIX_COLUMN}bin`, header: false }),
            textColumnDefinition({ key: 'afam', label: `${T_PREFIX_COLUMN}afam`, header: false }),
            textColumnDefinition({ key: 'vtoDate', label: `${T_PREFIX_COLUMN}vtoDate` ,header: true}),
            textColumnDefinition({ key: 'authDateTIme', label: `${T_PREFIX_COLUMN}authDateTIme`,header: true }),
            textColumnDefinition({ key: 'presentationDate', label: `${T_PREFIX_COLUMN}presentationDate`,header: true }),
            textColumnDefinition({ key: 'product', label: `${T_PREFIX_COLUMN}product`,header: true }),
            textColumnDefinition({ key: 'movementType', label: `${T_PREFIX_COLUMN}movementType`, header: false }),
            textColumnDefinition({ key: 'payerEntityCode', label: `${T_PREFIX_COLUMN}payerEntityCode`, header: false }),
            textColumnDefinition({ key: 'payerEntityBranchCode', label: `${T_PREFIX_COLUMN}payerEntityBranchCode`, header: false }),
            textColumnDefinition({ key: 'paymentMode', label: `${T_PREFIX_COLUMN}paymentMode`, header: false }),
            textColumnDefinition({ key: 'merchantAccount', label: `${T_PREFIX_COLUMN}merchantAccount`, header: false }),
            textColumnDefinition({ key: 'pos', label: `${T_PREFIX_COLUMN}pos`, header: false }),
            textColumnDefinition({ key: 'terminal', label: `${T_PREFIX_COLUMN}terminal`, header: false }),
            textColumnDefinition({ key: 'batch', label: `${T_PREFIX_COLUMN}batch`, header: false }),
            textColumnDefinition({ key: 'ticket', label: `${T_PREFIX_COLUMN}ticket`, header: false }),
            textColumnDefinition({ key: 'quotes', label: `${T_PREFIX_COLUMN}quotes`, header: false }),
            textColumnDefinition({ key: 'plan', label: `${T_PREFIX_COLUMN}plan`, header: false }),
            textColumnDefinition({ key: 'last4Digits', label: `${T_PREFIX_COLUMN}last4Digits`, header: false }),
            currencyColumnDefinition({ key: 'totalAmount', label: `${T_PREFIX_COLUMN}totalAmount`, header: true, columnDefinitionKey: 'currency' }),
            currencyColumnDefinition({ key: 'tipAmount', label: `${T_PREFIX_COLUMN}tipAmount`, header: false, columnDefinitionKey: 'currency' }),
            currencyColumnDefinition({ key: 'netAmount', label: `${T_PREFIX_COLUMN}netAmount`, header: false, columnDefinitionKey: 'currency' }),
            percentageColumnDefinition({ key: 'tariffPercentage', label: `${T_PREFIX_COLUMN}tariffPercentage`, header: false}),
            currencyColumnDefinition({ key: 'tariffAmount', label: `${T_PREFIX_COLUMN}tariffAmount`, header: false, columnDefinitionKey: 'currency' }),
            currencyColumnDefinition({ key: 'transactionTariffTaxAmount', label: `${T_PREFIX_COLUMN}transactionTariffTaxAmount`, header: false, columnDefinitionKey: 'currency' }),
            currencyColumnDefinition({ key: 'retention117453', label: `${T_PREFIX_COLUMN}retention117453`, header: false, columnDefinitionKey: 'currency' }),
            currencyColumnDefinition({ key: 'retention117453Percentage', label: `${T_PREFIX_COLUMN}retention117453Percentage`, header: false, columnDefinitionKey: 'currency' }),
            currencyColumnDefinition({ key: 'taxLaw17934', label: `${T_PREFIX_COLUMN}taxLaw17934`, header: false, columnDefinitionKey: 'currency' }),
            currencyColumnDefinition({ key: 'taxLaw18999', label: `${T_PREFIX_COLUMN}taxLaw18999`, header: false, columnDefinitionKey: 'currency' }),
            currencyColumnDefinition({ key: 'taxLaw18910', label: `${T_PREFIX_COLUMN}taxLaw18910`, header: false, columnDefinitionKey: 'currency' }),
            currencyColumnDefinition({ key: 'taxLaw19210', label: `${T_PREFIX_COLUMN}taxLaw19210`, header: false, columnDefinitionKey: 'currency' }),
            currencyColumnDefinition({ key: 'financialCostAmount', label: `${T_PREFIX_COLUMN}financialCostAmount`, header: false, columnDefinitionKey: 'currency' }),
            currencyColumnDefinition({ key: 'financialCostTaxAmount', label: `${T_PREFIX_COLUMN}financialCostTaxAmount`, header: false, columnDefinitionKey: 'currency' }),
            currencyColumnDefinition({ key: 'financialCostTeaAmount', label: `${T_PREFIX_COLUMN}financialCostTeaAmount`, header: false, columnDefinitionKey: 'currency' }),
            textColumnDefinition({ key: 'valDays', label: `${T_PREFIX_COLUMN}valDays`, header: false }),
            textColumnDefinition({ key: 'fiscalNumberWholesaler', label: `${T_PREFIX_COLUMN}fiscalNumberWholesaler`, header: false }),
            textColumnDefinition({ key: 'wholesalerFlag', label: `${T_PREFIX_COLUMN}wholesalerFlag`, header: false }),
            textColumnDefinition({ key: 'currentQuota', label: `${T_PREFIX_COLUMN}currentQuota`, header: false }),
            textColumnDefinition({ key: 'revertReason', label: `${T_PREFIX_COLUMN}revertReason`, header: false }),
            percentageColumnDefinition({ key: 'planQuotaRate', label: `${T_PREFIX_COLUMN}planQuotaRate`, header: false}),
            currencyColumnDefinition({ key: 'financialCostAmountQuote', label: `${T_PREFIX_COLUMN}financialCostAmountQuote`, header: false, columnDefinitionKey: 'currency' }),
            currencyColumnDefinition({ key: 'fixDiscountAmount', label: `${T_PREFIX_COLUMN}fixDiscountAmount`, header: false, columnDefinitionKey: 'currency' }),
            textColumnDefinition({ key: 'internationalFlag', label: `${T_PREFIX_COLUMN}internationalFlag`, header: false }),
            textColumnDefinition({ key: 'marketingFlag', label: `${T_PREFIX_COLUMN}marketingFlag`, header: false }),
            currencyColumnDefinition({ key: 'internationalChargeAmount', label: `${T_PREFIX_COLUMN}internationalChargeAmount`, header: false, columnDefinitionKey: 'currency' }),
            currencyColumnDefinition({ key: 'chargeTaxAmount', label: `${T_PREFIX_COLUMN}chargeTaxAmount`, header: false, columnDefinitionKey: 'currency' }),
            currencyColumnDefinition({ key: 'discounts', label: `${T_PREFIX_COLUMN}discounts`, header: false, columnDefinitionKey: 'currency' }),
            textColumnDefinition({ key: 'campainFlag', label: `${T_PREFIX_COLUMN}campainFlag` }),
            textColumnDefinition({ key: 'campainCode', label: `${T_PREFIX_COLUMN}campainCode` }),
            currencyColumnDefinition({ key: 'adjustAmount', label: `${T_PREFIX_COLUMN}adjustAmount`, header: false, columnDefinitionKey: 'currency' }),
            textColumnDefinition({ key: 'settlementNumber', label: `${T_PREFIX_COLUMN}settlementNumber` , header: true}),
            textColumnDefinition({ key: 'invoiceNumber', label: `${T_PREFIX_COLUMN}invoiceNumber` }),




        ];
    }

    setFilters(filters: IFilters) {
        this.setState({ ...this.state, filters });
    }

    updateDateRange = (e: DateObject[] | any) => {
        this.setState({ ...this.state, filters: { ...this.state.filters, dateRange: e } });
    }

    getOperationTableRequest = (all: boolean) => {
        const tranWebModel: IOperationTableRequest = {
            From: DateHelper.dateObjectToString(this.state.filters.dateRange[0]),
            To: DateHelper.dateObjectToString(this.state.filters.dateRange[1]),
            DateRangeType: this.state.filters.dateRangeType,
            MerchantDocument: this.state.filters.fiscalDocument=="all"?"":this.state.filters.fiscalDocument,
            MerchantNumber: this.state.filters.merchantDocument=="all"?"":this.state.filters.merchantDocument,
            Currency:  this.state.filters.currency=="all"?"":this.state.filters.currency,
            Skip: all ? 0 : (this.state.pageRequest.Skip),
            Take: all ? 0 : (this.state.pageRequest.Take)
        };
        return tranWebModel;
    }

    getSettlementsFromMainPage = () => {
        this.setState({
            ...this.state, mainPage: 0
        }, () => {
            this.getSettlements();
        });
    }

    componentDidUpdate(prevProps:any, prevState:any) {
        if(lastFiscalDocument!=this.state.filters.fiscalDocument){
            lastFiscalDocument=this.state.filters.fiscalDocument;
            let merchantNumbers= OptionMapperHelper.getAllMerchantNumbersOptions(lastFiscalDocument,true,true)
            this.setState({...this.state,filters:{...this.state.filters,merchantDocument:merchantNumbers[0].value},merchantNumbers:merchantNumbers})
        }
    }

    getSettlements = () => {
        SettlementService.getOperationTable(this.getOperationTableRequest(false)).then((response: AxiosResponse<IPresentationTotal[]>) => {
            if (response === null ||
                response.data === null ||
                response.data.length === 0
            ) {
                this.setState({...this.state,
                    operations: [],
                    errors: []
                })
                return;
            }

            this.setState({...this.state,
                operations: response.data,
                errors: []
            });
        }).catch(error => {
            this.setState({...this.state,
                errors: error.response.data,
                operations: []
            })
            console.log('error:', error);
        });
    }

    handleCaptchaVerification = (verified: boolean) => {
        this.setState({ isCaptchaVerified: verified });
    };

    getTransactionsToExcel = () => {
        if (this.state.isCaptchaVerified) {
        SettlementService.getOperationTableToExcel(this.getOperationTableRequest(true)).then((response: AxiosResponse<Blob>) => {
        }).catch(error => {
            this.setState({ errors: error.response.data });
            console.log('error:', error);
        });
        }else {
            toast.error("Por favor, verifica el CAPTCHA antes de descargar el archivo");
        };
    }


    render() {
        const { t } = this.props;
        const currentUser = WebCache.getCurrentUser()?.countryId;
        let clientDocument;
        if (currentUser !== undefined && currentUser === 2) {
            clientDocument = t("merchantDocument") ;
        } else {
            clientDocument = t("merchantDocumentArg");
        }

        return (
            <Page>
                <>
                    <SettlementTotal rowDetail={this.state.rowDetail} link={null}></SettlementTotal>
                    <div className='row'>
                        <h2>{t('fileReports')}</h2>
                        <div className='col-lg-12'>
                            <ErrorsView errors={this.state.errors}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <Card actions={
                                <>
                                    <div className="col-lg-12">
                                        <NavigationTabLinksForReports></NavigationTabLinksForReports>
                                    </div>
                                </>
                            }>
                                <>
                                    <FormFilter 
                                        onSubmit={this.getSettlementsFromMainPage} 
                                        model={[ this.state.filters, this.setFilters.bind(this) ]} 
                                        header={
                                            <div className="d-flex align-items-center justify-content-end pt-2" >
                                                <RadioButtonGroup
                                                    model={[ this.state, this.setState.bind(this) ]} 
                                                    attr="tableMode"
                                                    fieldDefinitions={[
                                                        { key: TableModeEnum.LIST, value: TableModeEnum.LIST, icon: 'fe fe-list' },
                                                        { key: TableModeEnum.GRID, value: TableModeEnum.GRID, icon: 'fe fe-grid' }
                                                    ]}
                                                ></RadioButtonGroup>
                                            </div>
                                        }>
                                        <>
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <RadioButtonGroup
                                                        attr="dateRangeType" 
                                                        className='radioStyle'
                                                        fieldDefinitions={[
                                                            { key: 'vtoDate', value: 'PAYMENT_DATE', label: 'paymentDate' },
                                                            { key: 'aggregationPresentationDate', value: 'PRESENTATION_DATE', label: 'presentationDate' },
                                                            { key: 'authDate', value: 'OPERATION_DATE', label: 'operationDate' }
                                                        ]}                                                  
                                                    ></RadioButtonGroup>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <DateField attr="dateRange" label="dateRange" range></DateField>
                                                </div>
                                                <div className="col-md-4">
                                                    <SelectField attr='fiscalDocument' label={clientDocument}
                                                    options={this.optionMapper.translateOptionValues(this.state.fiscalDocuments)}></SelectField>
                                                </div>
                                                <div className="col-md-4">
                                                    <SelectField attr='merchantDocument' label={`merchantNumber`}
                                                    options={this.optionMapper.translateOptionValues(this.state.merchantNumbers)}></SelectField>
                                                </div>
                                                {WebCache.getCurrentUser()?.countryId == 2 ?
                                                <div className="col-md-4">
                                                    <SelectField attr="currency" label={`${T_PREFIX}currency`}
                                                    options={this.state.currencyOptions}></SelectField>
                                                </div>
                                                :""}
                                            </div>
                                        </>
                                    </FormFilter>
                                    <CustomTable
                                        rowObjects={this.state.operations}
                                        columnDefinitions={this.columnDefinitions}
                                        config={{
                                            mode: this.state.tableMode,
                                            paginator: {
                                                mainPage: this.state.mainPage,
                                                modifyMainPage: (value: number) => {
                                                    this.setState({
                                                        mainPage: this.state.mainPage + value
                                                    }, () => this.getSettlements())
                                                },
                                                pageRequest: this.state.pageRequest
                                            }
                                        }}
                                    ></CustomTable>
                                    <div className='row'>
                                        <div className='col-md-6'></div>
                                        <div className="col-md-3">
                                            <CustomReCAPTCHA onVerify={this.handleCaptchaVerification}></CustomReCAPTCHA>
                                        </div>
                                        <div className="col-md-3">
                                            <Button disabled={this.state.operations.length === 0} label='excel' onClick={() => { toast.info("El excel se descargará cuando esté listo"); this.getTransactionsToExcel() }}></Button>
                                        </div>
                                    </div>
                                </>
                            </Card>
                        </div>
                    </div>
                </>
            </Page>
        )
    };

}
export default withTranslation() (OperationTableReport);
