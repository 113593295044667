interface IProps {
    country: string;
}

let TermsAndContions: React.FC<IProps> = ({country}) => {
    return (
        country === "UYU" ?
            <>
                <p>
                    POR FAVOR, LEA LOS PRESENTES TERMINOS Y CONDICIONES CUIDADOSAMENTE. AL ACCEDER A la página Fiserv (sección "acceda a sus cuentas online") USTED SE COMPROMETE A CUMPLIR CON Y ACEPTA LOS TERMINOS Y CONDICIONES QUE APARECEN A CONTINUACION. SI NO ESTA DE ACUERDO, POR FAVOR NO ACCEDA A Fiserv (sección "acceda a sus cuentas online"), PUESTO QUE ESTE FUNCIONA UNICAMENTE DE CONFORMIDAD CON LOS SIGUIENTES TERMINOS Y CONDICIONES.
                </p>
                <p>
                    1. Objeto.
                    Fiserv (sección "acceda a sus cuentas online") es un sistema que permite a los Establecimientos adheridos a los servicios de aceptación de pagos de Fiserv (el “ Sistema Fiserv”): (i) acceder a la información sobre operaciones realizadas con las Tarjetas y cualquier otra información que se habilite en el futuro; (ii) solicitar el envío de alertas y promociones por correo electrónico; y (iii) demás operaciones que se habiliten de tiempo en tiempo. El acceso a Fiserv (sección "acceda a sus cuentas online") se acuerda exclusivamente con el objeto anteriormente establecido, de forma tal que no podrá ser utilizado por Establecimientos para ningún otro destino ni para que terceros efectúen operaciones por medio del mismo, ni para efectuar operaciones por cuenta de terceros ni para brindar información a terceros. Tampoco podrán los Usuarios revender, ceder o licenciar en forma alguna, la utilización del servicio Fiserv (sección "acceda a sus cuentas online")
                </p>
                <p>
                    2. Definiciones.
                    Los términos definidos a continuación tendrán, siempre que sean utilizados con mayúscula, el siguiente significado: (i) Entidad Pagadora: Es aquella que los Establecimientos libremente eligen para la presentación y cobranza de sus liquidaciones de ventas; (ii) Establecimiento: Es el que integra el Sistema Fiserv aceptando las Tarjetas como medio de pago de ventas realizadas y servicios prestados a los tarjetahabientes; (iii) Tarjetas, son las tarjetas de las marcas que integran el Sistema Fiserv; (iv) Usuario: Es el Establecimiento que se encuentra registrado y que utiliza Fiserv (sección "acceda a sus cuentas online").
                </p>
                <p>
                    3. Acceso.
                    El acceso a Fiserv (sección "acceda a sus cuentas online") se realiza a través de Internet, en el sitio cuya página principal se encuentra ubica en http:  https://merchantcenter.fiservapp.com/ (el "Sitio"). Todos los gastos de conexión son a cargo del Usuario, quien deberá contar, además, con un equipo y programas de computación que cumplan con los requisitos mínimos de seguridad que se establezcan de tiempo en tiempo. El Usuario toma conocimiento que cuando su equipo o programas de computación no reúnan tales requisitos mínimos, se podrá bloquear su acceso a Fiserv (sección "acceda a sus cuentas online").
                    Para ingresar a Fiserv, el Usuario debe ingresar al link de acceso itdviewer.firstdata.com (el "Sitio") con su nombre de usuario, conjuntamente con su clave personal. Adicionalmente, se le pedirá el doble factor de autenticación con Google Authenticator. Una vez obtenido el código de validación, deberá ingresarlo en la pantalla para ingresar a (el "Sitio") El ingreso de tales datos validará las operaciones como si hubieran sido instrumentadas con la firma del Usuario, aún cuando hubieran sido realizadas por terceros utilizando tales datos.
                </p>
                <p>
                    4. Confidencialidad de la clave de acceso.
                    El Usuario se compromete a guardar en estricta confidencialidad su clave personal, tomando todas las medidas necesarias para ello. El Usuario asume la total responsabilidad por cualquier consecuencia que pudiera derivarse del uso inapropiado de la clave de acceso, incluyendo la divulgación, incorrecto manejo de los productos contratados y toda otra conducta que no se compadezca con la de un responsable y buen hombre de negocios. Serán de responsabilidad exclusiva del Usuario las transacciones que se ejecuten mediante la utilización de la clave de acceso, por operadores que se hubieran desvinculado del Usuario, se hubiera modificado la naturaleza de sus funciones y/o se hubiera revocado total o parcialmente su mandato. Es responsabilidad del Usuario modificar la clave de acceso para evitar inconvenientes de este tipo.
                </p>
                <p>
                    5. Manejo de información.
                    <p>
                        (a) Principio General. Cuando el Usuario voluntariamente proporcione información personal a través del Sitio, como por ejemplo su dirección de correo electrónico, resultarán de aplicación las siguientes disposiciones.
                    </p>
                    <p>
                        (b) Uso de la Información. Cuando el Usuario proporcione su dirección de correo electrónico, First Data Uruguay S.R.L. podrá enviarle promociones u ofertas, propias o de terceros, mediante correo electrónico, para brindar servicios específicos como asegurar una correcta facturación, medir el interés del Usuario respecto de los diversos servicios o informarle acerca de productos y servicios.
                        También podrá adherirse el Usuario al envío de las alertas que de tiempo en tiempo se habiliten (en conjunto, la "Información").
                    </p>
                    <p>
                        (c) Actualización de datos. Rechazar Promociones vía Correo Electrónico. El Sitio ofrece a los Usuarios la capacidad de corregir o cambiar la información recogida, así como de rechazar la comunicación de promociones vía correo electrónico. Los Usuarios pueden cambiar o eliminar esta información en cualquier momento y tan a menudo como sea necesario a través de las herramientas proporcionadas por el Sitio.
                    </p>
                    <p>
                        (d) La Información es de uso estrictamente personal, por lo cual no deberá seleccionar que sea enviada a terceros. Del mismo modo, deberá adoptar las precauciones necesarias, ya sea a través de la habilitación de claves de acceso o protección u otras medidas de seguridad, para que una vez recibida, la Información no sea visualizada y conocida por terceros.
                        La información será enviada en formato plano o HTML, sin encriptación, circunstancia que es reconocida y aceptada por el Usuario.
                    </p>
                    <p>
                        (e) El Usuario declara conocer que la aceptación de los presentes términos y condiciones importa el consentimiento que prevé la ley 18.331 de protección de datos personales y sus normas reglamentarias.
                    </p>
                </p>
                <p>
                    6. Cargos. Establecimientos.
                    El acceso al servicio de Fiserv Consultas brindado a los Establecimientos es sin cargo, a excepción 
                </p>
                <p>
                    7. Garantías. Responsabilidad.
                    Los servicios brindados por Fiserv (sección "acceda a sus cuentas online") resultan, natural y esencialmente falible, dado lo extenso y complejo de la red de que se sirve y los diversos aspectos y sujetos intervinientes, resultando de cumplimiento imposible cualquier tipo de garantía que se pretenda establecer sobre su infalibilidad, es decir, sobre su prestación en forma ininterrumpida y libre de defectos o errores, o aún sobre la posible exposición a la acción ilícita de terceros, sea a través de la captura de la Información, su manipulación o acciones similares. Cuando estos ocurran, First Data Uruguay S.R.L. afectará aquellos medios que se encuentren a su alcance para solucionarlos en el menor tiempo posible.
                    First Data Uruguay S.R.L. no otorga garantía alguna sobre la exactitud, adecuación o integridad de la información y los materiales que figuran en Fiserv (sección "acceda a sus cuentas online") y el Usuario expresamente acepta que First Data Uruguay S.R.L. no tendrá responsabilidad alguna, y por ende no podrá reclamarla, por errores u omisiones en esta información y materiales.
                    El Usuario manifiesta conocer y aceptar que el medio de comunicación utilizado para acceder a Fiserv (sección "acceda a sus cuentas online") puede sufrir, a pesar de todas las medidas que se adopten, filtraciones o interferencias no deseadas, sin que pueda imputarse responsabilidad alguna por tal motivo a First Data Uruguay S.R.L..
                    La elección del proveedor de servicios de Internet y tecnologías a utilizar para acceder a las alertas, es propia de cada Usuario. First Data Uruguay S.R.L. no asume ninguna responsabilidad con relación a los mismos, particularmente en relación con el proceso de recepción de la Información por parte de tales empresas y de transmisión de la misma a los Usuarios.
                </p>
                <p>
                    8. Suspensión del servicio.
                    El servicio podrá ser suspendido temporalmente, sea para tareas de mantenimiento rutinario, reparaciones no programadas o de urgencia que no pudieran evitarse, así como cuando por cualquier circunstancia derivada de medidas o resoluciones que dicte cualquier autoridad pública y/o cuando por cualquier otra razón de fuerza mayor, se vea afectada la normal operatoria de First Data Uruguay S.R.L. y/o del servicio.
                </p>
                <p>
                    9. Propiedad Intelectual.
                    Todos los contenidos del Sitio, incluyendo pero no limitándose a las marcas, avisos, nombres comerciales, frases de propaganda, dibujos, diseños, logotipos, textos, etc. que aparecen en el Sitio, son propiedad exclusiva de First Data Uruguay S.R.L., o de terceros que han autorizado su uso o publicación a First Data Uruguay S.R.L. Queda estrictamente prohibido cualquier uso o explotación por cualquier medio, sin el consentimiento previo y por escrito de First Data Uruguay S.R.L. y/o de sus legítimos propietarios. El diseño y contenido del Sitio se encuentra debidamente protegido conforme lo dispuesto en la normativa de Propiedad Intelectual, por lo que queda estrictamente prohibido: modificar, copiar, distribuir, transmitir, desplegar, publicar, editar, vender o de cualquier forma explotar el diseño y contenido del Sitio con fines comerciales o privados. 
                </p>
                <p>
                    10. Modificación de términos.
                    Vigencia. First Data Uruguay S.R.L. queda expresamente autorizada a suprimir, total o parcialmente, el servicio a que se refiere la presente y/o modificarlo cuando lo considere conveniente, sin necesidad de notificación previa de ninguna naturaleza, sirviendo como suficiente aviso el brindado por medio del Sitio de  Fiserv (sección "acceda a sus cuentas online"), dando cuenta de dicha circunstancia.
                    Por su parte, el Usuario también podrá hacer uso del derecho a hacer cesar su adhesión al sistema, dando aviso con suficiente antelación a Fiserv.
                </p>
                <p>
                    11. Enlaces con sitios, productos y servicios de terceros.
                    Fiserv (sección "acceda a sus cuentas online") puede contener enlaces con otros sitios, productos y servicios los que son propiedad y operados por terceros. Estos son facilitados exclusivamente para la comodidad de los Usuarios. First Data Uruguay S.R.L. advierte a los Usuarios que no administra ni controla estos sitios y consecuentemente no es responsable de sus contenidos y / o de la exactitud de la información brindada por ellos, ni de las negociaciones que los Usuarios puedan celebrar con ellos, ni de cualquier daño o perjuicio causado o que se presuma que sea causado por tales contenidos, productos o servicios disponibles en dichos otros sitios
                </p>
                <p>
                    12. Cambios en la política de Fiserv referente a la privacidad.
                    Si First Data Uruguay S.R.L. decide cambiar la política sobre privacidad para el Sitio Fiserv, anunciará aquí dichos cambios, de manera que el Usuario siempre pueda saber que información recoge, como podría usar dicha información y si la revelará a alguien.
                </p>
                <p>
                    13. Interpretación del ejercicio de derechos.
                    La falta de ejercicio por parte de Fiserv de los derechos conferidos por el presente, no implicarán una renuncia a los mismos, los cuales podrán ser ejercidos en todo momento.
                    SI ESTA DE ACUERDO, PRESIONE ACEPTAR. EL POSTERIOR ACCESO AL SISTEMA FISERV (sección "acceda a sus cuentas online") A TRAVES DEL INGRESO DE SU NOMBRE DE USUARIO Y DE SU CLAVE PERSONAL IMPORTARA LA CONFIRMACION DE LA EXPRESA ACEPTACION POR EL USUARIO DE TODAS Y CADA UNA DE LAS CLAUSULAS Y CONDICIONES INDICADAS PRECEDENTEMENTE.
                </p>
            </>
        : 
            <>
                <h2>Términos y condiciones generales</h2>
                <p>Por favor, lea los presentes términos y condiciones cuidadosamente. Al acceder al sitio de internet cuya página principal se encuentra ubicada en http://www.firstdata.com.ar (el "Sitio"), usted se compromete a cumplir con y acepta los términos y condiciones que aparecen a continuación. Si no está de acuerdo, por favor no acceda al Sitio, puesto que este funciona únicamente de conformidad con estos siguientes términos y condiciones.</p>
                <p>1. OBJETO. First Data es un sistema que permite a los establecimientos afiliados al mismo: (i) acceder a la información sobre operaciones realizadas con las tarjetas y cualquier otra información que se habilite en el futuro; (ii) solicitar el envío de alertas y promociones por correo electrónico; y (iii) demás operaciones que se habiliten de tiempo  en  tiempo.  El  acceso  al  Sitio  se  acuerda  exclusivamente  con  el  objeto anteriormente   establecido,   de   forma   tal   que   no   podrá   ser   utilizado   por establecimientos para ningún otro destino ni para que terceros efectúen operaciones por medio del mismo, ni para efectuar operaciones por cuenta de terceros ni para brindar  información  a  terceros.  Tampoco  podrán  los  usuarios  revender,  ceder  o licenciar en forma alguna la utilización del servicio ofrecido a través del Sitio.</p>
                <p>2.  DEFINICIONES.  Los  términos  definidos  a  continuación  tendrán  el  siguiente significado:  (i)  Entidad  Pagadora:  es  aquella  que  los  establecimientos  libremente eligen  para  la  presentación  y  cobranza  de  sus  liquidaciones  de  ventas;  (ii) Establecimiento: es elque integra el sistema First Data aceptando las tarjetas como medio de pago de ventas realizadas y servicios prestados a los socios; (iii) Tarjetas, son las tarjetas de las marcas que integran el sistema First Data; (iv) Usuario: es el establecimiento quese encuentra registrado y que utiliza el Sitio.</p>
                <p>3.  ACCESO.  Todos  los  gastos  de  conexión  a  internet  para  acceder  al  Sitio  son  a cargo  del  usuario,  quien  deberá  contar,  además,  con  un  equipo  y  programas  de computación  que  cumplan  con  los  requisitos  mínimos  de  seguridad  que  se establezcan  de  tiempo  en  tiempo.  El  Usuario  toma  conocimiento  que  cuando  su equipo o programas de computación no reúnan tales requisitos mínimos, se podrá bloquear su acceso al Sitio.</p>
                <p>Para ingresar al Sitio, el Usuario debe ingresar su correo electrónico, conjuntamente con su clave personal.  El ingreso de tales datos validará las operaciones como si hubieran  sido  instrumentadas  con  la  firma  del  Usuario,  aun  cuando  hubieran  sido realizadas por terceros utilizando tales datos.</p>
                <p>4. CONFIDENCIALIDAD DE LA CLAVE DE ACCESO. El Usuario se compromete a guardar en estricta confidencialidad su clave personal, tomando todas las medidas necesarias  para  ello.  El  Usuario  asume  la  total  responsabilidad  por  cualquier consecuencia  que  pudiera  derivarse  del  uso  inapropiado  de  la  clave  de  acceso, incluyendo  la  divulgación,  incorrecto  manejo  de  los  productos  contratados  y  toda otra conducta que no se compadezca con la de un responsable y buen hombre de negocios. Serán de responsabilidad exclusiva del Usuario las transacciones que se ejecuten  mediante  la  utilización  de  la  clave  de  acceso,  por  operadores  que  se hubieran  desvinculado  del  Usuario,  se  hubiera  modificado la naturaleza de sus funciones y/o se hubiera revocado total o parcialmente su mandato. Es responsabilidad del Usuario modificar la clave de acceso para evitar inconvenientes de este tipo.</p>
                <p>5. USUARIOS ADMINISTRADORES Y USUARIOS OPERADORES. El Sitio contará con dos niveles de Usuario: (i) Usuario Administrador: es aquel que crea la cuenta y corresponde al tipo de usuario que tiene la potestad y la facultad de generar nuevos usuarios  operadores  y  asignarles  o  quitarles  permisos;  (ii)  Usuario  Operador:  es aquel  creado  por  el  Usuario  Administrador,  al  cual  este  último  le  otorga  sólo  los permisos de visualización y administración que considere. Se debe tener en cuenta que  puede  otorgarle  permisos que  generen  costo  o  que  ejecute  funciones  que comprometan onerosamente al Establecimiento.</p>
                <p>El  Establecimiento  será  el  único  responsable  por  los  permisos  que  cada  Usuario otorgue, los costos generados y las consecuencias derivadas de las operaciones y transacciones que el Usuario (Operador y/o Administrador) realice en virtud de su posibilidad deacceso, administración o del uso correcto o incorrecto que haga del Sitio.</p>
                <p>6. MANEJO DE INFORMACIÓN.</p>
                <p>(A)   PRINCIPIO   GENERAL.   Cuando   el   Usuario   voluntariamente   proporcione información  personal  a  través  del  Sitio,  como  por  ejemplo  su  dirección  de  correo electrónico, resultarán de aplicación las siguientes disposiciones.</p>
                <p>(B) USO DE LA INFORMACIÓN. El Usuario, al adherirse y registrarse en el Sitio, brinda  su  consentimiento  y  autoriza  a  First  Data  Cono  Sur  S.R.L.  a  enviarle  y transmitirle  todo  tipo  de  comunicaciones,  avisos,  newsletters,  mensajes  (SMS, PUSH   notifications)   y/o   encuestas   con   fines   promocionales,   comerciales   y publicitarios, propios o de terceros, a través de las direcciones de correo electrónico, teléfonos,  redes  sociales  que  haya  proporcionado,  tanto  para  brindar  servicios específicos  como  asegurar  una  correcta  facturación,  medir  el  interés  del  Usuario respecto  de  los  diversos  servicios  o  informarle  acerca  de  productos  y  servicios existentes o que en el futuro se ofrezcan.</p>
                <p>El Usuario podrá subir al Sitio fotos, imágenes, información que incluya teléfonos, páginas  web,  redes  sociales,  entre  otros  datos,  acerca  del  Establecimiento.  El Establecimiento asumirá la responsabilidad del contenido de dicha información, de las imágenes y de todo otro dato que haya brindado y garantizará que es el único titular o que ha obtenido todos los permisos y autorizaciones correspondientes de los  titulares  de  todos  los  derechos  que  existan  sobre  dicha  información.  El Establecimiento  garantizará que  no  vulnera  los  derechos  de  terceros  sobre  las personas  u  objetos  representados  en  las  fotos  e  información  que  suba  al  Sitio,  y asumirá la responsabilidad derivada de las eventuales infracciones sobre derechos de Propiedad Intelectual y en cuestión de Protección de Datos Personales. Se exime a First Data de cualquier reclamo que pueda ser realizado por terceros, obligándose el Establecimiento a resarcir a First Data de los eventuales daños y perjuicios que se  produzcan.  El  Establecimiento  también  podrá  adherirse  al  envío  de  las  alertas que de tiempo en tiempo se habiliten (en conjunto, la "Información").</p>
                <p>(C)  ACTUALIZACIÓN  DE  DATOS.  RECHAZAR  PROMOCIONES  VÍA  CORREO ELECTRÓNICO. El Sitio ofrece a los Usuarios la posibilidad de corregir o cambiar la información recogida. Los Usuarios pueden cambiar o eliminar esta información en  cualquier  momento  y  tan  a  menudo  como  sea  necesario  a  través  de  las herramientas  proporcionadas  por  el  Sitio.  En  el  caso  en  que  no  desee  recibir información de tipo publicitario, podrá ejercer dicha opción conforme el mecanismo que cada mensaje de ese tipo incluya o mediante correo electrónico a First Data, a la dirección de correo electrónico que esta última le indique.</p>
                <p>(D)  La  Información  es  de  uso  estrictamente  personal,  por  lo  cual  no  deberá seleccionar  que  sea  enviada  a  terceros.  Del  mismo  modo,  deberá  adoptar  las precauciones necesarias, ya sea a través de la habilitación de claves de acceso o protección u otras medidas de seguridad, para que una vez recibida, la Información no sea visualizada y conocida por terceros.</p>
                <p>La Información será enviada en formato plano o html, sin encriptación, circunstancia que es reconocida y aceptada por el Usuario.</p>
                <p>(E)  El  Usuario  declara  conocer  que  la  aceptación  de  los  presentes  términos  y condiciones  importa  el  consentimiento  que  prevé  la  ley  25.326  de  Protección  de Datos Personales y sus normas reglamentarias.</p>
                <p>7. CARGOS POR USO DEL SITIO Y SECCIONES “plus”. El acceso al servicio del Sitio  de  First  Data  brindado  a  los  Establecimientos  es  sin  cargo,  a  excepción  de aquellas secciones del Sitio denominadas “plus”, las cuales serán accesibles por cualquier Usuario registrado en el Sitio, mediante el ingreso a tales secciones y la mera aceptación del cobro del cargo que se informa al acceder a las mismas (“Alta”). El cargo por acceso a las secciones plus será el monto informado al momento de aceptación en el ingreso a aquellas secciones, se devengará por CUIT, y por el mero acceso   inicial   a   cualquiera   de   esas   secciones,   independientemente   de   que posteriormente  el  Usuario  realice  o  no  consultas  y/u  operaciones  dentro  de  las mismas.  Dicho  cargo  dejará  de  cobrarse  con  efectos  al  siguiente  período  de liquidación a aquel en el que el Usuario hubiera gestionado la baja de la utilización de las secciones plus en el mismo Sitio, conforme los mecanismos de cancelación que en el Sitio se determina. El cargo se aplicará sólo para los Establecimientos de la República Argentina y será debitado en forma directa de la cuenta bancaria que el Establecimiento mantenga con el Sistema First Data.</p>
                <p>First Data se reserva el derecho de incrementar el monto del cargo. En el caso que el Establecimiento no acepte dicho aumento del cargo, podrá gestionar la baja de la utilización  de  las  secciones  plus  en  el  mismo  Sitio,  de  conformidad  con  lo anteriormente detallado en el presente punto.</p>
                <p>El  Establecimiento  tendrá  derecho  a  una  bonificación  del  100%  en  el  uso  de  las secciones plus, por única vez y por el período de 90 (noventa) días, contados desde la efectiva Alta.</p>
                <p>Una vez transcurrido dicho plazo, First Data comenzará a facturar el cargo conforme la  política  comercial  vigente  sin  aplicación  de  bonificación  alguna;  por  tanto  en  el supuesto caso de que el Establecimiento, pasado el plazo de bonificación, no desee contar con las secciones plus deberá solicitar la baja correspondiente mediante los mecanismos de cancelación especificados en el presente punto.</p>
                <p>8.  GARANTÍAS.  RESPONSABILIDAD.  El  Sitio  resulta,  natural  y  esencialmente falible, dado lo extenso y complejo de la red de que se sirve y los diversos aspectos y  sujetos  intervinientes,  resultando  de  cumplimiento  imposible  cualquier  tipo  de garantía  que  se pretenda  establecer  sobre  su  infalibilidad,  es  decir,  sobre  su prestación  en  forma  ininterrumpida  y  libre  de  defectos  o  errores,  o  aún  sobre  la posible  exposición  a  la  acción  ilícita  de  terceros,  sea  a  través  de  la  captura  de  la información, su manipulación o acciones similares. Cuando estos ocurran, First Data Cono  Sur  S.R.L.  afectará  aquellos  medios  que  se  encuentren  a  su  alcance  para solucionarlos en el menor tiempo posible.</p>
                <p>Por  ello,  en  ningún  caso  el  Emisor,  la  Entidad  Pagadora,  ni  First  Data  Cono  Sur S.R.L. serán responsables de cualquier daño, incluyendo sin límite, daños, pérdidas o gastos directos, indirectos o remotos, que surjan en relación con el Sitio o su uso o  imposibilidad  de  uso  por  el  usuario,  en  relación  con  cualquier  falla  en  el vencimiento,  error,  omisión,  interrupción,  defecto,  demora  en  la  operación  o transmisión, virus de computadora o falla de sistema o línea, aún en el caso de que el Emisor, la Entidad Pagadora y/o First Data Cono Sur S.R.L. o sus representantes fueran informados sobre la posibilidad de dichos daños, pérdidas o gastos.</p>
                <p>Ni el Emisor, la Entidad Pagadora, ni First Data Cono Sur S.R.L. otorgan garantía alguna sobre la exactitud, adecuación o integridad de la información y los materiales que figuran en el Sitio y el Usuario expresamente acepta que ni el Emisor, la Entidad Pagadora, ni First Data Cono Sur S.R.L. tendrán responsabilidad alguna, y por ende no podrá reclamarla, por errores u omisiones en esta información y materiales.</p>
                <p>First  Data  Cono  Sur  S.R.L.  es  una  empresa  que  presta  servicios  a  las  entidades. Salvo acuerdo expreso y por escrito en contrario, First Data Cono Sur S.R.L. no tiene ninguna vinculación jurídica con los Establecimiento y, por ende, no intervendrá en conflictos entre éstos y las entidades.</p>
                <p>El Usuario manifiesta conocer y aceptar que el medio de comunicación utilizado para acceder  al  Sitio  puede  sufrir,  a  pesar  de  todas  las  medidas  que  se  adopten, filtraciones o interferencias no deseadas, sin que pueda imputarse responsabilidad alguna por tal motivo a First Data Cono Sur S.R.L.</p>
                <p>La  elección  del  proveedor  de  servicios  de  internet  y  tecnologías  a  utilizar  para acceder a las alertas, es propia de cada Establecimiento. First Data Cono Sur S.R.L. no  asume  ninguna  responsabilidad  con  relación  a  los  mismos,  particularmente  en relación con el proceso de recepción de la información por parte de tales empresas y de transmisión de la misma a los usuarios.</p>
                <p>9. SUSPENSIÓN DEL SERVICIO. El servicio podrá ser suspendido temporalmente, sea  para  tareas  de  mantenimiento  rutinario,  reparaciones  no  programadas  o  de urgencia  que  no  pudieran  evitarse,  así  como  cuando  por  cualquier  circunstancia derivada  de  medidas  o  resoluciones  que  dicte  cualquier  autoridad  pública  y/o cuando  por  cualquier  otra  razón  de  fuerza  mayor,  se  vea  afectada  la  normal operatoria de First Data Cono Sur S.R.L. y/o del servicio.</p>
                <p>10. PROPIEDAD INTELECTUAL. Todos los contenidos del Sitio, incluyendo pero no limitándose  a  las  marcas,  avisos,  nombres  comerciales,  frases  de  propaganda, dibujos,  diseños,  logotipos,  textos,  etc.,  que  aparecen  en  el  Sitio,  son  propiedad exclusiva de First Data Cono Sur S.R.L., o de terceros que han autorizado su uso o publicación a First Data Cono Sur S.R.L. Queda estrictamente prohibido cualquier uso o explotación por cualquier medio, sin el consentimiento previo y por escrito de First Data Cono Sur S.R.L. y/o de sus legítimos propietarios. El diseño y contenido del Sitio se encuentra debidamente protegido conforme lo dispuesto en la ley 11.723 de propiedad intelectual, por lo que queda estrictamente prohibido: modificar, copiar, distribuir, transmitir, desplegar, publicar, editar, vender o de cualquier forma explotar el diseño y contenido del Sitio con fines comerciales o privados. La violación de esta prohibición, hará pasibles a sus autores de las sanciones penales previstas en las leyes  11.723  y  22.362.  Se  deja  constancia  que  las  referencias,  tanto  al  contenido del Sitio como a las prohibiciones, son meramente enunciativas y no limitativas.</p>
                <p>11. MODIFICACIÓN DE TÉRMINOS. VIGENCIA. First Data Cono Sur S.R.L. queda expresamente autorizado a suprimir, total o parcialmente, el servicio a que se refiere la  presente  y/o  modificarlo  cuando  lo  consideren  conveniente,  sin  necesidad  de notificación  previa  de  ninguna  naturaleza,  sirviendo  como  suficiente  aviso  el brindado por medio del Sitio , dando cuenta de dicha circunstancia.</p>
                <p>Por  su  parte,  el  Usuario  también  podrá  hacer  uso  del  derecho  a  hacer  cesar  su adhesión al sistema, dando aviso con suficiente antelación a First Data.</p>
                <p>12. ENLACES CON SITIOS, PRODUCTOS Y SERVICIOS DE TERCEROS. El Sitio puede contener enlaces con otros sitios, productos y servicios que son propiedad y operados por terceros. Estos son facilitados exclusivamente para la comodidad de los usuarios. First DataCono Sur S.R.L. advierte a los usuarios que no administra ni controla estos sitios  y consecuentemente no es responsable de sus contenidos y/o de la exactitud de la información brindada por ellos, ni de las negociaciones que los usuarios puedan celebrar con ellos, ni de cualquier daño o perjuicio causado o que  se  presuma  que  sea  causado  por  tales  contenidos,  productos  o  servicios disponibles en dichos otros sitios.</p>
                <p>13. CAMBIOS EN LA POLÍTICA DE FIRST DATA REFERENTE A LA PRIVACIDAD. Si  First  Data  Cono  Sur  S.R.L.  decide  cambiar  la  política  sobre  privacidad  para  el Sitio,  anunciará  aquí  dichos  cambios,  de  manera  que  el  usuario  siempre  pueda saber qué información recoge,cómo podría usar dicha información y si la revelará a alguien.</p>
                <p>14. INTERPRETACIÓN DEL EJERCICIO DE DERECHOS. La falta de ejercicio por parte  de  First  Data  de  los  derechos  conferidos  por  el  presente,  no  implicarán  una renuncia a los mismos, los cuales podrán ser ejercidos en todo momento.</p>
                <p>B. VENTA ANTICIPADA DE LIQUIDACIÓN</p>
                <p>1. OBJETO</p>
                <p>1.1.  La  presente  sección  contiene  los  términos  y  condiciones  de  la  operatoria especial  de  venta  anticipada  de  liquidación,  que  permite  al  Establecimiento,  que adhiera  a  esa  operatoria,  percibir  el  importe  correspondiente  dentro  de  los  2  días hábiles (operaciones P.O.S.) o 4 días hábiles (operaciones Cupón Papel) siguientes a la presentación.</p>
                <p>1.2.  La  operatoria  de  venta  anticipada  de  liquidación  funciona  únicamente  de conformidad con los términos y condiciones de la presente; por ende,  en caso de disconformidad con los mismos, el Establecimiento deberá abstenerse de utilizar la operatoria. La aceptación de la presente implica la aceptación expresa por parte del Establecimiento de la totalidad de sus términos y condiciones.</p>
                <p>1.3. La operatoria del Establecimiento con las tarjetas se regirá por las disposiciones del   Reglamento   de   Comerciantes   del   Sistema   First   Data   y   sus   normas complementarias,  en  todo  lo  que  no  sea  expresamente  modificado  a  través  de  la presente sección de estos Términos y Condiciones Generales.</p>
                <p>2. DESCRIPCIÓN DE LA OPERATORIA</p>
                <p>2.1.  El  Establecimiento  percibirá  el  neto  a  cobrar,  dentro  de  los  2  días  hábiles (operaciones  P.O.S.)  o  4  días  hábiles  (operaciones  Cupón  Papel)  siguientes  a  la presentación,  en  la  misma  forma  de  pago  vigente  para  sus  presentaciones  de transacciones.</p>
                <p>2.2.  El  monto  neto  a  cobrar  se  calculará  deduciendo  al  importe  neto  de  cada liquidación (el "Importe Neto"), el costo financiero y el Cargo de Procesamiento. A su vez, el costo financiero total será calculado según se expresa en el punto 3.4 y el 3.4.1 de la comunicación "A" 6173 del B.C.R.A., o en aquella normativa que en el futuro la modifique o reemplace.</p>
                <p>Días = cantidad de días corridos existentes entre la fecha de pago vigente para el Establecimiento y la fecha de pago del neto a cobrar.</p>
                <p>Tasa = la tasa vigente al momento de cálculo del neto a cobrar.</p>
                <p>2.3. La tasa de referencia será la tasa nominal anual para adelantos y descubiertos en  cuenta  corriente  (descubierto  no  solicitado  previo)  del  Banco  de  la  Nación Argentina, en pesos, publicada diariamente en el diario Ámbito Financiero o la que en el futuro establezca First Data en su reemplazo.</p>
                <p>2.4. Si con posterioridad a la fecha de cálculo del neto a cobrar se establecieran días inhábiles que hubieran modificado la fecha de pago original de las liquidaciones, el Establecimiento   deberá   abonar   el   costo   financiero   correspondiente,   que   se descontará de los importes que el establecimiento tuviera a cobrar de la siguiente liquidación.</p>
                <p>2.5.  Adicionalmente,  el  Establecimiento  deberá  abonar  un  cargo  mensual  por procesamiento de liquidaciones (el "Cargo de Procesamiento"), que será deducido en  el  cálculo  del  neto  a  cobrar.  La  Administradora  descontará  directamente  el importe del Cargo de Procesamiento de la primera liquidación de cada mes, para lo cual el Establecimiento la instruye en forma expresa e irrevocable. El valor del Cargo de Procesamiento es de $ 3,50 + IVA por mes.</p>
                <p>2.6.  Si  en  cualquier  momento  el  neto  a  cobrar  de  las  liquidaciones,  deducidos  los importes percibidos del sistema por tales liquidaciones, alcanzara el monto máximo establecido por First Data Cono Sur S.R.L. respecto del Establecimiento (el "Monto Máximo"), se suspenderá la operatoria hasta tanto se supere dicha situación, tantas veces como sea necesario.</p>
                <p>No  se  realizarán  pagos  parciales  de  liquidaciones,  de  manera  tal  que  una  vez excedido el Monto Máximo a financiar y mientras dure esta circunstancia, First Data Cono Sur S.R.L. no realizará venta anticipada de liquidación.</p>
                <p>2.7. En caso de suspensión, inhabilitación o desafiliación de la Entidad Pagadora, se  suspenderá  la  operatoria  hasta  tanto  el  Establecimiento  modifique  la  Entidad Pagadora y este cambio sea aceptado y registrado por First Data Cono Sur S.R.L.</p>
                <p>2.8.  Producida  la  acreditación  a  la  Entidad  Pagadora  del  neto  a  cobrar,  el Establecimiento quedará desinteresado no teniendo nada que reclamar a First Data Cono  Sur  S.R.L.  y/o  las  Entidades  Emisoras  de  las  tarjetas,  con  relación  a  las liquidaciones involucradas, renunciando a formular cualquier reclamo en tal sentido, sea  por  falta  de  pago  de  los  importes  por  la  Entidad  Pagadora,  imposibilidad  de disponer de los mismos por el Establecimiento, por cualquier situación que afecte a la Entidad Pagadora, al propio Establecimiento y/o por cualquier disposición legal.</p>
                <p>Expresamente, el Establecimiento renuncia a interponer cualquier acción que pueda coartar, perjudicar o limitar el derecho de First Data Cono Sur S.R.L. y/o la Entidad Pagadora  a  cobrar  a  las  Entidades  Emisoras  de  las  tarjetas  con  las  cuales  se hubieran realizado las transacciones objeto de la venta de liquidación, el importe de las mismas.</p>
                <p>2.9.  Todo  y  cualquier  reclamo  vinculado  con  la  presente  operatoria  de  venta  de liquidación deberá ser formulado fehacientemente por el Establecimiento dentro de los  15  días  siguientes  a  la  fecha  de  la  liquidación.  Vencido  dicho  término,  el establecimiento perderá  todo  derecho  que  pudiera  corresponderle,  renunciando expresamente a formular cualquier reclamo en tal sentido.</p>
                <p>3. DECLARACIONES DEL ESTABLECIMIENTO</p>
                <p>3.1. El Establecimiento declara en forma expresa:</p>
                <p>(a) que es titular exclusivo de los derechos sobre los cupones objeto de la venta de liquidación.</p>
                <p>(b) su aceptación de la presente solicitud no viola restricción legal, reglamentaria ni contractual alguna, ni existe mejor derecho que pueda ser vulnerado a través de su cumplimiento.</p>
                <p>(c) que, tratándose de personas jurídicas:</p>
                <p>(i)  es  una  persona  jurídica  constituida  y  con  existencia  válida  en  la  República Argentina.</p>
                <p>(ii)  la  aceptación  y  cumplimiento  de  las  obligaciones  establecidas  en  esta  sección de los Términos y Condiciones Generales referida a Venta Anticipada de Liquidación ha sido debidamente autorizada mediante la adopción de las decisiones necesarias y  no  contravienen:  (1)  el  instrumento  constitutivo  o  su  estatuto,  ni  (2)  ninguna restricción legal, reglamentaria ni contractual obligatoria para Establecimiento o que pueda afectarlo o vincularlo.</p>
                <p>4. VIGENCIA</p>
                <p>4.1. La adhesión a venta anticipada de liquidación tendrá una vigencia de sesenta (60) días contados a partir del día de pago de la primera liquidación sujeta a venta anticipada  de  liquidación,  renovándose  por  períodos  idénticos  de  tiempo,  salvo decisión  en  contrario  del  Establecimiento,  de  la  Administradora  o  la  Entidad Pagadora, la que deberá ser notificada con 2 días de anticipación.</p>
                <p>4.2.  Cuando  a  criterio  de  la  Administradora  existan  circunstancias  o  hechos  que afecten  la  seguridad  del  sistema  o  de  alguno  de  sus  integrantes,  el  prestigio,  el crédito  o  el  concepto  del  Establecimiento;  la  Administradora  podrá  cancelar  la afiliación del Establecimiento a la operatoria de venta anticipada de liquidación, aún sin notificación previa alguna y en forma automática.</p>
                <p>5. MODIFICACIÓN DE LOS TÉRMINOS Y CONDICIONES</p>
                <p>5.1.  La  Administradora  podrá  modificar  en  cualquier  momento  los  términos  y condiciones  de  la  presente  sección  de  los  Términos  y  Condiciones  Generales referida  a  Venta  Anticipada  de  Liquidación,  así  como  la  tasa  de  referencia,  el coeficiente   de   interés,   el Cargo   de   Procesamiento   y/o   el   monto   máximo, notificándolo al Establecimiento con una anticipación mínima de 10 días a la fecha en  que  tendrá  efecto.  Dentro  de  dicho  plazo  el  Establecimiento  podrá  optar  por rescindir su adhesión a la operatoria de venta de liquidación (del modo establecido en el punto 4.2. de esta solicitud), sin que ello le otorgue derecho a formular reclamo alguno  a  la  Administradora.  Una  vez  transcurrido  el  mismo,  las  modificaciones  se tendrán por aceptadas a todo efecto.</p>
                <p>5.2. El Establecimiento presta conformidad para que cualquier notificación que deba serle  efectuada  en  cumplimiento  de  lo  dispuesto  en  la  presente,  sea  realizada indistintamente  mediante  una  nota  especial,  una  mención  en  las  liquidaciones  y/o en la página web de auto-consulta de los comercios: www.firstdata.com.ar.</p>
                <p>6. DOMICILIOS Y JURISDICCIÓN</p>
                <p>6.1.  Salvo  cuando  una  ley  de  orden  público  disponga  lo  contrario,  toda  disputa  o controversia referida a la presente sección de los Términos y Condiciones Generales referida  a  Venta  Anticipada  de  Liquidación  será  de  competencia  de  los  tribunales nacionalesen lo comercial de la Ciudad Autónoma de Buenos Aires, con renuncia de otro fuero o jurisdicción que pudiera corresponder.</p>
                <p>7. DEFINICIONES</p>
                <p>7.1.  Todos  los  términos  cuya  primera  letra  figura  en  mayúscula  en  la  presente sección  de  los  Términos  y  Condiciones  Generales  referida  a  Venta  Anticipada  de Liquidación,  tendrán  el  significado  que  se  indica  a  continuación.  Los  términos definidos comprenderán tanto el singular como el plural.</p>
                <p>(a) Administradora. Es First Data Cono Sur S.R.L., quien por cuenta y orden de la Entidad   Pagadora,   procesa   las   presentaciones   de   transacciones   de   los Establecimientos.  La  Administradora  es  una  empresa  que  presta  servicios  a  las Entidades Pagadoras y, a efectos de una más eficiente prestación de tales servicios, se le otorgan diversos derechos que constituyen estipulaciones a favor de terceros. Por  ende,  la  Administradora  no  intervendrá  en  conflictos  relacionados  con  la operatoria del Establecimiento con las tarjetas.</p>
                <p>(b)  Cargo  de  Procesamiento.  Tiene  el  alcance  asignado  en  el  punto  2.5  de  la presente.</p>
                <p>(c) Costo Financiero. Es el que se calcula en la forma establecida en el punto 2.2 de la presente.</p>
                <p>(d) Días. Tiene el alcance asignado en el punto 2.2 de la presente.</p>
                <p>(e) Días hábiles. Es todo día hábil bancario para la operatoria con tarjetas de crédito en la República Argentina.</p>
                <p>(f) Días inhábiles. Significa todo aquel día que no es día hábil.</p>
                <p>(g)  Entidad  Pagadora.  Es  la  entidad  que  integra  el  sistema,  seleccionada  por  el Establecimiento para su operatoria con las tarjetas.</p>
                <p>(h) Importe neto. Tiene el alcance asignado en el punto 2.2 de la presente.</p>
                <p>(i) Liquidaciones. Es el documento que refleja el resultado del procesamiento de las transacciones presentadas al cobro por el Establecimiento e indica los importes que éste tiene a cobrar.</p>
                <p>(j) Monto máximo. Tiene el alcance asignado en el punto 2.6 de la presente.</p>
                <p>(k)  Neto  a  cobrar.  Es  el  importe  de  cada  presentación  que,  calculado  en  la  forma prevista  en  el  punto  2.2  del  presente,  el  Establecimiento  percibirá  en  forma anticipada a los 2 días hábiles de la presentación.</p>
                <p>(l) Sistema. El sistema de tarjetas administrado por First Data Cono Sur S.R.L. que interconecta a distintas Entidades Emisoras, permitiendo a los titulares de tarjetas realizar compras en todos los establecimientos adheridos.</p>
                <p>(m) Tarjetas. Son las tarjetas del sistema First Data Cono Sur S.R.L.</p>
                <p>(n) Tasa. Tiene el significado asignado en el punto 2.3 de la presente.</p>
                <p>C. SERVICIO DE DATOS A COMERCIOS (DEVELOPERS)</p>
                <p>El Servicio de Datos a Comercios formará parte de las denominadas secciones plus, y  proporcionará  datos  e  información  específica  al  Establecimiento,  el  cual  tendrá acceso  a  los  mismos  previo  a  la  realización  por  parte  del  Establecimiento  de  los desarrollosen sus propios sistemas que fueran necesarios o convenientes. Dichos desarrollos deberán cumplir previamente con un procedimiento de homologación por parte  de  First  Data  Cono  Sur  S.R.L.  First  Data  Cono  Sur  S.R.L.  no  velará  por  la seguridad del front end que el Establecimiento desarrolle a estos efectos.</p>
                <p>El  Establecimiento  es  el  único  y  exclusivo  responsable  por  el  uso,  divulgación, manipulación,  transformación  o  utilización  de  los  datos  que  First  Data  Cono  Sur S.R.L. le suministre, desde el momento en que el Establecimiento integre esos datos con su propio sistema.</p>
                <p>Se aclara que el presente servicio tendrá un cargo para el Establecimiento que lo contrate, el que será informado previamente por First Data Cono Sur S.R.L.</p>
                <p>D. PROTECCIÓN DE DATOS PERSONALES</p>
                <p>"Art. 10 ter, ley 24.240: “MODOS DE RESCISIÓN. Cuando la contratación de un servicio, incluidos los servicios públicos domiciliarios, haya sido realizada en forma telefónica,  electrónica  o  similar,  podrá  ser  rescindida  a  elección  del  consumidor  o usuario mediante el mismo medio utilizado en la contratación. La empresa receptora del pedido de rescisión deberá enviar sin cargo al domicilio del consumidor o usuario una  constancia  fehaciente  dentro  de  las  setenta  y  dos  (72)  horas  posteriores  a  la recepción del pedido de rescisión. Esta disposición debe ser publicada en la factura o  documento  equivalente  que  la  empresa  enviare  regularmente  al  domicilio  del consumidor o usuario".</p>
                <p>"el titular de los datos personales tiene la facultad de ejercer el derecho de acceso a los mismos en forma gratuita a intervalos no inferiores a seis meses, salvo que se acredite un interés legítimo al efecto conforme lo establecido en el artículo 14, inciso 3 de la ley 25.326"; (Disp. 10/2008).</p>
                <p>"la Dirección Nacional de Protección de Datos Personales, órgano de control de la ley  25.326,  tiene  la  atribución  de  atender  las  denuncias  y  reclamos  que  se interpongan con relación al incumplimiento de las normas sobre protección de datos personales". (Disp. 10/2008).</p>
                <p>SI  ESTÁ  DE  ACUERDO,  PRESIONE  ACEPTAR.  EL  POSTERIOR  ACCESO  AL SITIO   DE   FIRST   DATA,   A   TRAVÉS   DEL   INGRESO   DE   SU   CORREO ELECTRÓNICO Y DE SU CLAVE PERSONAL IMPORTARÁ LA CONFIRMACION DE LA EXPRESA ACEPTACIÓN POR EL USUARIO DE TODOS Y CADA UNO DE LOS TÉRMINOS Y CONDICIONES INDICADOS PRECEDENTEMENTE.</p>
            </>
    )
}
export default TermsAndContions;