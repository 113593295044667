import React, { ChangeEvent, Fragment, useState } from 'react';
import './Footer.scss'
import { Modal } from "react-bootstrap";
import Button from '../controls/buttons/button';
import { useTranslation } from 'react-i18next';
import ReglamentoDeComerciantes from './ReglamentoDeComerciantes';
import BaseField from '../controls/fields/baseField';
import SelectField from '../controls/fields/selectField';
import Form from '../containers/form';
import TermsAndContions from './TermsAndCondition';
import { WebCache } from '../../services/WebCache';

interface IState {
    isValid: boolean;
}
interface IOptionSelect {
    value: string;
    label: string;
}


const URUGUAY= "UYU";
const ARGENTINA= "ARG";

const LoggedInFooter: React.FC = () => {
    const countryUserLogged: string = WebCache.getCurrentUser()?.countryId == 2 ? "Uruguay" : "Argentina";
        
    return (
        <div className="w-100 h-100 text-center position-relative">
            <div className="divLine w-100">
                <div className="divLine__line"></div>
            </div>
            <div className="logoFooter">
                    <svg width="85" height="43" viewBox="0 0 85 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="ico/logo" clipPath="url(#clip0_218_19597)">
                            <path id="Union" fillRule="evenodd" clipRule="evenodd" d="M2.82485 6.05634C2.82485 3.83568 3.02662 2.42254 4.03549 1.41315C5.24614 0.403756 7.26388 0 9.28162 0H11.0976V4.03756H10.2905C9.28162 4.03756 8.6763 4.23944 8.27275 4.64319C7.8692 5.04695 7.66744 5.85446 7.66744 6.66197V12.7183H11.0976V16.7559H7.66744V42.3944H2.82485V16.7559H0V12.9202H2.82485V6.05634ZM75.0599 12.7184L71.8315 36.9437L68.6031 12.7184H63.5587L68.1995 42.3944H75.4634L80.1042 12.7184H75.0599ZM85.1486 36.9436H79.7007V42.3943H85.1486V36.9436ZM17.5541 12.7184H12.7115V42.3944H17.5541V12.7184ZM35.3105 19.9859C35.3105 14.5352 37.7318 12.3145 42.9779 12.3145C48.0223 12.3145 50.6453 14.7371 50.6453 20.1878V29.0704H40.3548V35.3286C40.3548 37.3474 41.1619 38.7605 43.1797 38.7605C45.1974 38.7605 46.0045 37.3474 46.0045 35.3286V32.5023H50.6453V34.9249C50.6453 40.3756 47.6187 42.5962 42.9779 42.5962C38.3371 42.5962 35.3105 39.9718 35.3105 34.9249V19.9859ZM40.3548 25.2347H45.8027H46.0045V19.1784C46.0045 17.3615 45.601 15.7465 43.1797 15.7465C40.7584 15.7465 40.3548 17.5634 40.3548 19.1784V25.2347ZM28.6519 25.4365C25.0199 22.2064 24.6164 21.8027 24.6164 19.3801C24.6164 17.3614 24.8182 16.1501 26.6341 16.1501C28.4501 16.1501 28.6519 17.3614 28.6519 19.3801V22.2064H33.2927V17.7651C33.2927 14.3332 30.6696 12.5163 26.2306 12.5163C21.9933 12.5163 19.3702 13.9294 19.3702 19.582V20.1877C19.3702 22.0046 19.3703 24.8309 23.8093 28.4647L25.0199 29.474C28.2483 32.0985 28.6519 32.5022 28.6519 35.7323C28.6519 37.751 28.4501 39.1642 26.4324 39.1642C24.4146 39.1642 24.2128 37.5492 24.2128 36.7416V32.906H19.1685V36.7416C19.1685 41.5867 22.1951 42.798 26.4324 42.798C30.6696 42.798 33.6962 41.5867 33.6962 35.3285V34.7229C33.898 30.4834 33.0909 29.2722 28.6519 25.4365ZM57.3038 12.7183V15.5446C58.5144 12.9202 59.725 12.3145 62.3481 12.3145V17.5634C60.9357 17.5634 59.9268 17.7652 59.1197 18.3709C58.3126 18.9765 57.7073 19.9859 57.7073 21.6009V42.3943H52.8647V12.7183H57.3038Z" fill="black"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_218_19597">
                            <rect width="84.6129" height="43" fill="white"/>
                        </clipPath>
                        </defs>
                    </svg>
            </div>
            <div className="w-100 h-100 textContainer">
                <div className="d-flex textContainer__into">
                    <div className="textContainer__into__text ">First Data {countryUserLogged} S.R.L. | Todos los derechos reservados</div>
                </div>
            </div>
        </div>
    );
};

const LoggedOutFooter: React.FC = () => {
    const { t } = useTranslation();
    const [hiddenSelectCountry, sethiddenSelectCountry] = useState<Boolean>(false); 
    const [showTermsAndConditionsModal, setShowTermsAndConditionsModal] = useState<boolean>(false);
    const [showPersonalDataProtectionModal, setShowPersonalDataProtectionModal] = useState<boolean>(false);
    const [showMerchantRegulationsModal, setShowMerchantRegulationsModal] = useState<boolean>(false);
    const [country, setCountry] = useState<string>(URUGUAY);
    const [showOptionLinksModal, setShowOptionLinksModal] = useState<boolean>(false);


    const openTermsAndConditionsModal = () => {
        setCountry(URUGUAY);
        setShowTermsAndConditionsModal(true);
    };

    const openPersonalDataProtectionModal = () => {
        setCountry(URUGUAY);
        setShowPersonalDataProtectionModal(true);
    };

    const openMerchantRegulationsModal = () => {
        setCountry(URUGUAY);
        setShowMerchantRegulationsModal(true);
    };

    const closeTermsAndConditionsModal = () => {
        setShowTermsAndConditionsModal(false);
    };

    const closePersonalDataProtectionModal = () => {
        setShowPersonalDataProtectionModal(false);
    };

    const closeMerchantRegulationsModal = () => {
        setShowMerchantRegulationsModal(false);
    };

    const redirect = (event: React.ChangeEvent<HTMLSelectElement>) => {
        console.log("llega", event);
        window.location.href= event.target.value;
        // router.push(url);
    };

    return (
        <>
            <div className="w-100 h-100 text-center position-relative footerRou" style={{ marginTop: '-145px' }}>
                <div className="divLine w-100 position-absolute">
                    <div className="divLine__line"></div>
                </div>
                <div className="group">
                    <div className="group__img">
                        <svg width="61" height="31" viewBox="0 0 61 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="ico/logo" clipPath="url(#clip0_455_4116)">
                                <path id="Union" fillRule="evenodd" clipRule="evenodd" d="M2.03652 4.3662C2.03652 2.76526 2.18198 1.74648 2.90931 1.01878C3.7821 0.29108 5.23675 0 6.6914 0H8.00059V2.9108H7.41873C6.6914 2.9108 6.25501 3.05634 5.96408 3.34742C5.67315 3.6385 5.52769 4.22066 5.52769 4.80282V9.16901H8.00059V12.0798H5.52769V30.5634H2.03652V12.0798H0V9.31456H2.03652V4.3662ZM54.1129 9.16907L51.7855 26.6339L49.458 9.16907H45.8214L49.1671 30.5634H54.4039L57.7496 9.16907H54.1129ZM61.3862 26.6338H57.4586V30.5633H61.3862V26.6338ZM12.6553 9.16907H9.16414V30.5634H12.6553V9.16907ZM25.4564 14.4084C25.4564 10.4789 27.202 8.87792 30.9841 8.87792C34.6207 8.87792 36.5117 10.6244 36.5117 14.554V20.9577H29.093V25.4695C29.093 26.9249 29.6749 27.9437 31.1295 27.9437C32.5842 27.9437 33.166 26.9249 33.166 25.4695V23.4319H36.5117V25.1784C36.5117 29.108 34.3298 30.7089 30.9841 30.7089C27.6384 30.7089 25.4564 28.8169 25.4564 25.1784V14.4084ZM29.093 18.1925H33.0206H33.166V13.8263C33.166 12.5164 32.8751 11.3521 31.1295 11.3521C29.3839 11.3521 29.093 12.662 29.093 13.8263V18.1925ZM20.656 18.3379C18.0376 16.0093 17.7467 15.7182 17.7467 13.9717C17.7467 12.5163 17.8922 11.6431 19.2013 11.6431C20.5105 11.6431 20.656 12.5163 20.656 13.9717V16.0093H24.0017V12.8074C24.0017 10.3332 22.1107 9.02338 18.9104 9.02338C15.8556 9.02338 13.9646 10.0422 13.9646 14.1173V14.5539C13.9646 15.8638 13.9646 17.9013 17.1648 20.521L18.0376 21.2487C20.3651 23.1407 20.656 23.4318 20.656 25.7605C20.656 27.2159 20.5105 28.2346 19.0559 28.2346C17.6012 28.2346 17.4558 27.0703 17.4558 26.4882V23.7229H13.8191V26.4882C13.8191 29.9811 16.0011 30.8544 19.0559 30.8544C22.1107 30.8544 24.2926 29.9811 24.2926 25.4694V25.0328C24.4381 21.9764 23.8562 21.1032 20.656 18.3379ZM41.312 9.169V11.2066C42.1848 9.31454 43.0576 8.87792 44.9486 8.87792V12.662C43.9304 12.662 43.2031 12.8075 42.6212 13.2441C42.0393 13.6807 41.6029 14.4084 41.6029 15.5728V30.5634H38.1118V9.169H41.312Z" fill="black"/>
                            </g>
                                <defs>
                                    <clipPath id="clip0_455_4116">
                                        <rect width="61" height="31" fill="white"/>
                                    </clipPath>
                                </defs>
                        </svg>
                    </div>
                    <div className="group__text">
                        <div className="group__text__links">
                            <div className="group__text__links__a"><a href={window.location.href} onClick={openMerchantRegulationsModal} className='group__text__links__a__color'>Reglamento de Comerciantes</a></div>
                            <div className="group__text__links__a"><a href={window.location.href} onClick={openTermsAndConditionsModal} className='group__text__links__a__color'>Términos y condiciones</a></div>
                            <div className="group__text__links__a"><a href={window.location.href} onClick={openPersonalDataProtectionModal} className='group__text__links__a__color'>Protección de datos personales</a></div>
                            <div className="group__text__links__a">
                                <div className='pb-3'>
                                    <div className={`select-country ${!hiddenSelectCountry ? "hidden" : ""}`}>
                                        <a type='button' href='https://www.fiserv.com.uy/'>
                                            <span className='pe-2'>Fiserv UY</span>
                                        </a>
                                        <a type='button' href='https://www.fiserv.com.ar/'>
                                            <span>Fiserv ARG</span>
                                        </a>
                                    </div>
                                    <a type='button' onClick={() => sethiddenSelectCountry(!hiddenSelectCountry)} className='about-fiserv d-flex justify-content-center group__text__links__a__color'>Sobre Fiserv</a>
                                </div>
                            </div>
                        
                        </div>
                    </div>
                </div>
                <div className="w-100 position-absolute d-flex mt-9 textContainer">
                    <div className="w-100 textContainer__into">
                        <div className="textContainer__into__text">© 2024 Fiserv. Todos los derechos reservados.</div>
                    </div>
                </div>
            </div>
            <Modal show={showMerchantRegulationsModal} dialogClassName="footer-modal" >
                <div className="modal-content modal-content-demo">
                    <div className="modal-header bg-primary align-items-center">
                        <a type='button' className='me-3' onClick={() => setCountry(URUGUAY)}>
                            <h4 className="modal-title text-white">Reglamento de Comerciantes - URUGUAY  </h4>
                        </a>
                        <div className='divider'></div>
                        <a type='button' className='ms-3' onClick={() => setCountry(ARGENTINA)}>
                            <h4 className="modal-title text-white">  Reglamento de Comerciantes - ARGENTINA</h4>
                        </a>
                        <Button aria-label="Close" className="btn-close p-0" onClick={() => closeMerchantRegulationsModal()} noBlock >
                            <span className='text-white' aria-hidden="true">&times;</span>
                        </Button>
                    </div>
                    <div className="modal-body">
                        <div className="scrollable-container">
                            <ReglamentoDeComerciantes country={country}/>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <Button onClick={() => closeMerchantRegulationsModal()} noBlock >
                            <span className='text-white' aria-hidden="true">{t(`close`)}</span>
                        </Button>
                    </div>
                </div>
            </Modal>
            
            <Modal show={showTermsAndConditionsModal} dialogClassName="footer-modal" >
                <div className="modal-content modal-content-demo">
                    <div className="modal-header bg-primary align-items-center">
                        <a type='button' className='me-3' onClick={() => setCountry(URUGUAY)}>
                            <h4 className="modal-title text-white">Términos y condiciones generales - URUGUAY  </h4>
                        </a>
                        <div className='divider'></div>
                        <a type='button' className='ms-3' onClick={() => setCountry(ARGENTINA)}>
                            <h4 className="modal-title text-white">  Términos y condiciones generales - ARGENTINA</h4>
                        </a>
                        <Button aria-label="Close" className="btn-close p-0" onClick={() => closeTermsAndConditionsModal()} noBlock >
                            <span className='text-white' aria-hidden="true">&times;</span>
                        </Button>
                    </div>
                    <div className="modal-body">
                        <div className="scrollable-container w-95">
                            <TermsAndContions country={country} />
                        </div>
                    </div>
                    <div className="modal-footer">
                        <Button onClick={() => closeTermsAndConditionsModal()} noBlock >
                            <span className='text-white' aria-hidden="true">{t(`close`)}</span>
                        </Button>
                    </div>
                </div>
            </Modal>

            <Modal show={showPersonalDataProtectionModal} title='Protección de datos personales' dialogClassName="footer-modal" >
                <div className="modal-content modal-content-demo">
                    <div className="modal-header bg-primary align-items-center">
                        <a type='button' className='me-3' onClick={() => setCountry(URUGUAY)}>
                            <h4 className="modal-title text-white">Protección de datos personales - URUGUAY  </h4>
                        </a>
                        <div className='divider'></div>
                        <a type='button' className='ms-3' onClick={() => setCountry(ARGENTINA)}>
                            <h4 className="modal-title text-white">  Protección de datos personales - ARGENTINA</h4>
                        </a>
                        <Button aria-label="Close" className="btn-close p-0" onClick={() => closePersonalDataProtectionModal()} noBlock >
                            <span className='text-white' aria-hidden="true">&times;</span>
                        </Button>
                    </div>
                    <div className="modal-body">
                        <div className="scrollable-container">
                            {   country === URUGUAY ?
                                    <>
                                        <p>
                                            Al registrarse como Usuario de este Sitio, suministrará información que, a criterio de First Data Uruguay S.R.L (“FISERV”) resulta necesaria para brindar un mejor servicio.
                                        </p>
                                        <p>
                                            FISERV informa a los usuarios de su portal Merchant Center (en adelante, los "Usuarios" y el "Portal") acerca de su política de protección de datos de carácter personal para que los Usuarios determinen libre y voluntariamente si desean facilitar a FISERV los Datos Personales que se les puedan requerir o que se puedan obtener de los mismos con motivo de la prestación de alguno de los servicios ofrecidos por Fiserv de manera de dar cumplimiento a lo establecido en la Ley No. 18.331 y su Decreto Reglamentario No. 414/009.
                                        </p>
                                        <p>
                                            FISERV tratará los datos personales proporcionados por los Usuarios de este Sitio Web en un todo de acuerdo a la normativa de la República Oriental del Uruguay en materia de Protección de Datos Personales, en particular a la Ley 18331 y los decretos 664/008 y 414/009.
                                        </p>
                                        <p>
                                            FISERV se reserva el derecho a modificar la presente para adaptarla a los cambios normativos en materia de protección de datos personales.
                                        </p>
                                        <p>
                                            FISERV informará en esta página los cambios introducidos con razonable antelación a su puesta en práctica.
                                        </p>
                                        <p>
                                            Ciertos servicios prestados en el Portal pueden contener condiciones particulares con previsiones específicas en materia de protección de Datos Personales.
                                        </p>
                                        <p>
                                            El Usuario garantiza que los Datos Personales facilita a FISERV son veraces y será responsable de comunicar a ésta cualquier modificación en los mismos.
                                        </p>
                                        <p>
                                            Los Usuarios tienen reconocidos y podrán ejercitar los derechos que les otorga la Ley No. 18.331, de acceso, cancelación, rectificación y oposición, así como tienen reconocido el derecho a ser informados de las cesiones realizadas.
                                        </p>
                                    </>
                                :
                                    <>
                                        <h2>Protección de datos personales</h2>
                                        <p>“Art. 10 ter, Ley 24.240: Modos de rescisión. Cuando la contratación de un servicio, incluídos los servicios públicos domiciliarios, haya sido realizada en forma telefónica, electrónica o similar, podrá ser rescindida a elección del consumidor o usuario mediante el mismo medio utilizado en la contratación. La empresa receptora del pedido de rescisión deberá enviar sin cargo al domicilio del consumidor o usuario una constancia fehaciente dentro de las setenta y dos (72) horas posteriores a la recepción del pedido de rescisión. Esta disposición debe ser publicada en la factura o documento equivalente que la empresa enviare regularmente al domicilio del consumidor o usuario”.</p>
                                        <p>“El titular de los datos personales tiene la facultad de ejercer el derecho de acceso a los mismos en forma gratuita a intervalos no inferiores a seis meses, salvo que se acredite un interés legítimo al efecto conforme lo establecido en el artículo 14, inciso 3 de la Ley 25.536”.</p>
                                        <p>“La Agencia de Acceso a la Información Pública, Órgano de Control de la Ley 25.326 tiene la atribución de atender las denuncias y reclamos que se interpongan con relación al incumplimiento de las normas sobre protección de datos personales”.</p>
                                    </>
                            }
                        </div>
                    </div>
                    <div className="modal-footer">
                        <Button onClick={() => closePersonalDataProtectionModal()} noBlock >
                            <span className='text-white' aria-hidden="true">{t(`close`)}</span>
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

const Footer: React.FC<IState> = ({ isValid }) => {
  return (
    <div>
      {isValid ? <LoggedOutFooter /> : <LoggedInFooter />}
    </div>
  );
};

export default Footer;