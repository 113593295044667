let RegulationCommerce: React.FC = () => {
    return (
            <>
                <h4>Reglamento de Comerciantes</h4>
                <h5>Capítulo 1</h5>
                <p>
                    1.1 Ámbito de aplicación. El presente Reglamento de Comerciantes y/o sus actualizaciones futuras, así como todo anexo que el Establecimiento suscriba en un futuro conforman un único instrumento (el “Reglamento”) que regulará: (i) la
                    aceptación de medios de pagos que integran el Sistema, para la adquisición de bienes, o para el pago de la prestación de servicios a través de tarjetas de las Marcas y otros medios de pago que el Establecimiento acepte o decida aceptar en un futuro; (ii) el servicio de captura de datos a través de los Medios de Captura que permiten la conexión del Establecimiento
                    con el centro de procesamiento de los distintos medios de pago habilitados al efecto, para la validación, autorización y/o captura de transacciones; (iii) otros productos y servicios relacionados que Fiserv ofrezca en un futuro, todos ellos referidos como los “Servicios” indistinta o conjuntamente según corresponda. Mediante su adhesión, El Establecimiento se obliga a: (i) ingresar periódicamente al Sitio para la lectura del Reglamento e
                    informarse de sus novedades y/o modificaciones; (ii) adecuar su accionar al Reglamento, asumiendo responsabilidad exclusiva de su cumplimiento.                 
                </p>
                <p>
                    1.2. Entidades prestadoras de los Servicios: sociedades pertenecientes al grupo Fiserv. 
                </p>
                <p>
                    1.3. Formas de operar o productos. La operatoria de los Establecimientos con las Tarjetas podrá efectuarse a través de
                    las siguientes modalidades:
                </p>
                <p>
                    1.3.1. Tarjeta Presente o Tarjeta no presente: (i) Operatoria de captura de datos; (ii) Equipos de Medios de Captura, (iii) Soluciones de integración; (iv) tarjeta de débito; (v) tarjeta no presente; (vi) débito automático; (vii) operatoria contactless o sin contacto (viii) operatoria de pagos instantáneos.
                    1.3.2. Porformas de venta: (i) Operaciones de contado; y (ii) operaciones en cuotas.
                    1.3.3. Servicios Adicionales: (i) Anticipación de ventas (“Anticipación”); (ii) Operatoria mayorista; (iii) Prevención de fraudes; (iv) Aceptación de PIX.
                </p>
                <h5>Capítulo 2</h5>
                <p>
                    2. Ejercicio de derechos y facultades por el Banco Ciudad. El Establecimiento reconoce que la aceptación de tarjetas de la marca Visa se ofrece bajo la licencia adquirente del Banco de la Ciudad de Buenos Aires y, por ende, acepta que todos los derechos y facultades establecidos en este capítulo en relación a Visa podrán ser ejercidos por el Banco Ciudad con el mismo alcance que Fiserv.
                </p>
                <p>
                    3.Aceptación bajo el Programa OptBlue de American Express. El Establecimiento reconoce que la aceptación de tarjetas de la marca American Express se ofrece en el marco del Programa OptBlue de American Express y que a la aceptación de tarjetas de dicha Marca le serán aplicables las disposiciones previstas en el siguiente link:
                    http://www.americanexpress.com.ar/guia-comercio. Dicho reglamento se incorpora al presente por referencia. 
                </p>
                <p>
                    3.1 Condiciones aplicables a la aceptación de American Express bajo el Programa Optblue.
                    3.1.1 Derechos de Terceros Beneficiarios. - American Express es un tercero beneficiario directo y previsto en este reglamento respecto de las operaciones con tarjetas American Express y puede ejercer los derechos establecidos bajo el presente reglamento frente a los Establecimientos exclusivamente con relación al cumplimiento de las disposiciones de
                        la guía-comercio por parte del Establecimiento.
                    3.1.2 Cancelación de las Tarjetas American Express como medio de pago. - El Establecimiento puede optar por no aceptar las Tarjetas American Express como medio de pago de los bienes y servicios que ofrece, en cualquier momento, sin afectar directa o indirectamente su derecho de aceptar otras tarjetas, de conformidad con lo estipulado en el presente Reglamento
                    3.1.3. Políticas de Reembolso. - El Establecimiento se compromete a establecer políticas de reembolso para compras realizadas con las Tarjetas American Express, que sean igual de favorables que las políticas de reembolso que tenga establecidas para los pagos realizados por sus clientes con otras Tarjetas diferentes a American Express; asimismo, se compromete a divulgar a los Tarjetahabientes de American Express la política de reembolso al momento de realizar una compra.
                    3.1.4 Establecimientos de Alto Volumen de Facturación. En caso de que el Establecimiento participante del Programa OptBlue se convierta en un Establecimiento con Alto Volumen de Facturación conforme dicho término se encuentran  definidos en la guía-comercio de American Express mencionada en el punto 3, American Express se comunicará directamente con el Establecimiento con Alto Volumen de Facturación para ofrecerle suscribir un contrato de aceptación de tarjeta directo con American Express, no  pudiendo el Establecimiento continuar aceptando tarjetas de la marca
                    American Express bajo el Programa OptBlue 
                </p>                
                <p>
                    4.Aplicación. Los principios detallados en este capítulo serán aplicables a todas las formas de operar que se detallan en el Reglamento salvo que dichas formas especifiquen una condición especial, la que primará sobre la general.
                </p>
                <p>
                5.Adhesión y Vigencia. Producida la aceptación de este Reglamento mediante la firma de la Solicitud de Comercio y/o la
aceptación del mismo por parte del Establecimiento a través de los mecanismos disponibles al efecto, la adhesión al
Sistema tendrá lugar una vez aceptada la solicitud por parte de Fiserv, lo que se materializará a través de la habilitación y
otorgamiento del código de Establecimiento. Salvo que se hubiere negociado un plazo diferente en instrumentos
específicos, la vigencia será de treinta y seis (36) meses y se renovará automáticamente por idénticos períodos salvo
decisión en contrario de alguna de las partes, previa notificación fehaciente con dos (2) días de preaviso. Sin perjuicio de
ello, y salvo lo previsto para cada operatoria y/o Servicio, el Reglamento podrá ser rescindido por cualquiera de las partes
con un preaviso de treinta (30) días.
                </p>

                <p>
                5.1. Resolución por Fiserv. Fiserv podrá rescindir la adhesión a este Reglamento o a cualquiera de sus modalidades u
operatorias o Servicios en particular en cualquier momento, sin necesidad de notificación previa y en forma automática
cuando: (i) tenga conocimiento de circunstancias o hechos que, a su juicio, puedan afectar la seguridad del Sistema, el
prestigio, el crédito, solvencia y/o modificación del giro o actividad comercial del Establecimiento, (ii) le sea declarada o
pedida la quiebra al Establecimiento, se presente en concurso preventivo, se encuentre en mora con Fiserv, (iii) en caso
de incremento en volumen de contracargos, operaciones irregulares o sospecha de transacciones fraudulentas, (iv)
incumplimiento de los estándares de seguridad de datos que rigen la Industria de Pago con Tarjeta (Payment Card Industry
Data Security Standards – PCI DSS) o las Reglas, (v) cuando el Establecimiento no registrare operaciones de venta durante un lapso de sesenta (60) días, o (vi) en caso que el Establecimiento incumpla cualquiera de las condiciones establecidas
en este Reglamento, la Solicitud del Comercio o aquellas condiciones que se le comuniquen a futuro.
                   </p>
                <p>
                5.2. Baja del Servicio. En caso de que el Establecimiento comunicare su voluntad de rescindir o no renovar la vigencia
                deberá indicar el Servicio contratado que en particular decidiere dar de baja
                </p>
                <p>
                6.Actualización de información. El Establecimiento deberá notificar a Fiserv y a su Pagador cualquier cambio en su razón
o denominación social, nombre de fantasía, domicilio, teléfono, cambio de titularidad del Establecimiento y/o en la
conformación del capital social, situación impositiva, rubros en que opera, suspensión o cancelación de matrícula de
corresponder, información provista en la información de su Pagador, Solicitud de Comercio y/o cualquier otra
circunstancia que razonablemente pudiera incidir en su existencia dentro del Sistema, dentro de los cinco (5) días hábiles
de ocurrida la variación. El incumplimiento a este deber de informar o la falsedad en las declaraciones formuladas al
efectuar la adhesión hará responsable al Establecimiento de los inconvenientes que esto ocasione.
                </p>
                <p>
                7.Uso de Marcas. Los Establecimientos podrán exhibir las calcomanías, carteles, marcas, logotipos y otros materiales
promocionales a losfines de informar la aceptación de los medios de pago, estos no podrán ser alterados nisean utilizados
de manera tal que se pudiera entender que Fiserv o cualquiera de los titulares de Marcas promocionan sus productos y
servicios. El Establecimiento se obliga a retirar de exhibición todo material identificatorio como adherente al Sistema al
cesar su adhesión al mismo, o ante el requerimiento de Fiserv. En caso de incumplimiento, el Establecimiento será
responsable de todos los daños y perjuicios ocasionados y será el único y exclusivo responsable del pago de impuestos,
tasas y contribuciones que deban abonarse por tal uso o exhibición indebida.
El Establecimiento reconoce que el presente Reglamento no le otorga derecho, título ni interés alguno sobre los logos y
Marcas de los medios de pago del Sistema, ni sobre sus productos y servicios. El Establecimiento se abstendrá de realizar
cualquier acción que tenga por efecto causar que tales logos, marcas, denominaciones, productos o servicios sean
utilizados en cualquier forma contraria a la moral y las buenas costumbres o que puedan tener efectos contrarios hacia el
buen nombre, reputación o imagen de los mismos. El Establecimiento se obliga en relación con todo y cualquier cartel,
publicación y/o material promocional en cualquier medio que incluya o se refiera a los logos o marcas, a dar cumplimiento
de las disposiciones legales vigentes (sea en materia de derecho de autor, propiedad industrial y defensa del consumidor)
y deberá mantener indemne a Fiserv y/o a las Marcas en caso de corresponder frente a cualquier reclamo derivado del
incumplimiento de tales disposiciones.

                </p>
                <p>
                8. Comunicaciones. Toda comunicación desde Fiserv al Establecimiento, incluyendo la modificación de los términos y
condiciones del Reglamento y la Solicitud del Comercio, el arancel, otros cargos, así como cualquier otra condición, podrá
efectuarse mediante mensajes a través del Sitio, enviándolo por correo electrónico a la dirección de mail indicada por el
Establecimiento en la Solicitud del Comercio y/o por otros medios que disponga Fiserv de tiempo en tiempo. Asimismo,
Fiserv realizará periódicamente el registro del Reglamento ante la autoridad de aplicación, de conformidad con la
regulación vigente. Tales comunicaciones serán consideradas como integrantes del presente Reglamento y sin que sea
necesario acto posterior alguno, salvo cuando fuera expresamente requerido en el presente. Las comunicaciones se
presumen conocidas por los Establecimientos desde la fecha de su puesta a disposición.
                </p>
                <p>
                8.1. Reporte de transacciones. Salvo pedido expreso del Establecimiento, el reporte de transacciones y cualquier otra
información y liquidación de la actividad del Establecimiento con el Sistema, será puesto a disposición a través del Sitio y
tendrá el formato que Fiserv establezca para cada modalidad. El Establecimiento acepta que no se emitirán liquidaciones
por escrito.
8.2. Modificaciones. El Establecimiento reconoce a Fiserv la facultad de modificar los términos y condiciones de este
Reglamento y la Solicitud del Comercio en cualquier momento mediante preaviso de treinta (30) días a través de alguno
de los medios indicados en la cláusula 8, salvo que en este Reglamento se establezca un plazo menor. Si dentro de tal
plazo los Establecimientos no optan por la rescisión, se entenderá que las modificaciones introducidas son aceptadas y de
cumplimiento obligatorio.
                </p>
                <p>
                9.Regulación aplicable. El Establecimiento manifiesta que conoce y se compromete a cumplir todas las leyes, normativas
y reglas aplicables a losservicios aquí acordados y a los bienes y servicios que comercializa, entre ellos, los procedimientos
operativos, las medidas de seguridad y reglas emitidas por los titulares de Marcas disponibles en www.visa.com,
www.mastercard.com, www.americanexpress.com, Guía Operativa Comercios American Express en
http://www.americanexpress.com.ar/guia-comercio y los sitios webs oficiales de las demás Marcas (las “Reglas”) y las
normas referidas al Estándar de Seguridad de los Datos que rigen en la Industria de Pagos con Tarjeta (Payment Card
Industry Data Security Standards – “PCI DSS”) disponibles en www.pcisecuritystandards.org. Incluyendo los requisitos de
Seguridad de datos (DSR) de American Express.
El Establecimiento deberá implementar todas las actualizaciones y medidas de seguridad adicionales requeridas para dar
cumplimiento a las Reglas. Adicionalmente deberá remitir la información y documentación respaldatoria para acreditar el
cumplimiento del Programa de Protección de Datos (Site Data Protection Program) de PCI DSS, correspondiente al nivel
que le aplique.
                </p>
                <p>
                10. Obligación de no recargar. Los Establecimientos no deberán aplicar recargos a los Tarjetahabientes por la compra de
bienes y/o servicios con las Tarjetas. Los Tarjetahabientes no podrán ser obligados a adquirir o consumir productos y/o
servicios por sobre un monto mínimo para poder abonarlos con Tarjetas.

                </p>
                <p>
                11. Aceptación de Tarjetas.
11.1. Características de las Tarjetas. El Establecimiento deberá controlar que las Tarjetas reúnan las características: llevar
impreso el isotipo de la Marca, el nombre del Emisor, firma de su titular en el panel al dorso, número de tarjeta, el código
de validación; su vigencia, el carácter de seguridad, una banda magnética; inserto un holograma de seguridad y/o un
microchip. El Establecimiento deberá consultar periódicamente la actualización de las características de las Tarjetas en las
Reglas.
11.2. Retención de Tarjetas. Cuando se presente una Tarjeta que pudiere presumirse irregular, su portador no acreditare
su identidad, y/o cuando Fiserv requiriera la retención de la Tarjeta, el Establecimiento empleará sus mayores esfuerzos,
utilizando medios razonables y pacíficos, para retener tal Tarjeta y entregarla al Emisor, dando aviso a la autoridad policial
competente, en caso de corresponder.
11.2.1. Condiciones de utilización. El Establecimiento deberá, salvo excepción expresa, cumplir con las siguientes
condiciones para la utilización de Tarjetas;
(a) Verificación de identidad e intransferibilidad. Verificar la identidad del portador de la Tarjeta la que deberá coincidir
con la información de la Tarjeta. En caso de no corresponder, deberá abstenerse de operar. La Tarjeta es intransferible, y
solo es su tenedor legítimo la persona a cuyo nombre ha sido otorgada y cuya firma consta en el panel al dorso. No se
encuentra permitido el uso de Tarjetas por terceros.
(b) Autorización. Obtener la autorización de la operación del centro de autorizaciones de Fiserv. El número de autorización quedará reflejada en el Ticket. En caso de sospecha con respecto a una transacción el Establecimiento deberá
comunicarse con Fiserv e indicar al centro de autorizaciones que se trata de una transacción "código 10" y aguardar
instrucciones. Ni la Autorización ni el código 10 legitiman la identidad de quien exhibe la Tarjeta, sino que sólo implican
que (i) los datos transmitidos coinciden con los registrados en la base de datos del Sistema, (ii) la Tarjeta está habilitada
para operar al momento de la autorización; y (iii) la cuenta posee límite de compra suficiente para realizar la transacción.
El Establecimiento deberá informar de manera inmediata cualquier cancelación de autorización concedida. La omisión de
la información responsabilizará al Establecimiento por cualquier desconocimiento por parte del Tarjetahabiente.
(c) La Tarjeta deberá estar habilitada por el Emisor y deberá encontrarse en vigencia.
(d) EMV. Todas las tarjetas que cuenten con chip deberán ser insertadas en el lector de chip del Medio de Captura. Su
incumplimiento podrá generar la aplicación de multas por parte de las Marcas y hará responsable al Establecimiento
frente a cualquier cuestionamiento de la operación por parte del Tarjetahabiente. Cualquier excepción a dicha regla
deberá ser requerida telefónicamente al centro de autorizaciones.
(e) Préstamos de dinero. El Establecimiento se obliga a no adelantar a los Tarjetahabientes dinero en efectivo, ni cheques,
ni aceptar cambio de cheques, salvo autorización expresa y por escrito de Fiserv.
(f) Requisitos aplicables a otras operatorias. Dar cumplimiento a todo otro requisito particular del Reglamento y/o las
Reglas.
11.2.2. Desdoblamiento de operaciones. El Establecimiento deberá incluir en un único Ticket el importe total
correspondiente al precio de los bienes y/o servicios incluidos en la transacción, sin subdividir la operación de venta en
varios Tickets. Salvo prueba en contrario, se presumirá que hay subdivisión cuando se presenten o se requiera
Autorización, en el mismo día y por un mismo Establecimiento, varios Tickets o Tickets del mismo Tarjetahabiente de la
Tarjeta. Quedará exceptuado de lo dispuesto anteriormente: 1) cuando el Tarjetahabiente abone en efectivo o mediante
cheque, al momento de realizarse la venta, el saldo pagadero; o 2) en aquellos Establecimientos expresamente
autorizados por Fiserv para operar bajo la modalidad de remisión de mercadería o prestación de servicios con
posterioridad a su perfeccionamiento. En dichos casos podrán realizarse dos Tickets diferentes, uno como depósito y el
restante en pago del saldo. Las mercaderías o los servicios que se facturen individualmente a la misma cuenta (por
ejemplo, pasajes aéreos) no se considerarán una violación del presente Reglamento cuando se procese un comprobante
dividido, si se obtiene autorización aparte para cada una de las mercancías o servicios. En los casos en que se presenten
varias tarjetas para abonar el pago de una sola transacción, no se aplicará la prohibición anterior.
11.2.3. Cobro de deuda. Refinanciación. Cancelación de operaciones. El Establecimiento no podrá aceptar pagos de los
Tarjetahabientes en concepto de cancelación o refinanciación parcial o total de deudas. El Establecimiento deberá
abstenerse de recibir pagos de Tarjetahabientes por mercaderías o servicios incluidos en el Ticket. El Ticket de Devolución
deberá realizarse y presentarse al cobro en los plazos y condiciones vigentes a la fecha de su presentación.
11.2.4. Cesión de Tickets. Se encuentra prohibido para el Establecimiento, salvo que cuente con autorización de Fiserv
y/o Pagador, la cesión de los Tickets respaldatorios de las operaciones, los que deberán ser presentados al cobro
indefectiblemente bajo el número de Establecimiento donde las mismas se realicen. También está expresamente
prohibido al Establecimiento, presentar al cobro operaciones realizadas fuera de los límites geográficos del país en el cual
se afilió al Sistema o transferir los Tickets correspondientes a operaciones realizadas en dicho país para ser cobradas por
otro establecimiento fuera del mismo.
11.2.5. Moneda. Las transacciones pueden realizarse en moneda local o aquellas otras especialmente habilitadas por
Fiserv. El Establecimiento deberá abstenerse de realizar conversiones entre dichas monedas o cualquier otra diferente de
la consignada en el Ticket.
                </p>
                <p>
                11.3. Operaciones irregulares o defectuosas. Son causas legítimas de rechazo de operaciones, no asumiendo Fiserv
responsabilidad alguna a su respecto, y sin perjuicio del derecho del Establecimiento con respecto a terceros por la venta
efectuada, las establecidas a continuación: (a) Causales comunes a todas las operatorias: (i) Incumplimiento a las
obligaciones previstas bajo la sección 11.2.1, 11.2.2, 11.2.3, 11.2.4, 11.2.5; (ii) cuando la venta consignada en el Ticket no
se incluya dentro del cierre de lote del mismo día o día hábil inmediatamente posterior; (iii) operación presentada al cobro
bajo un número de Establecimiento distinto al de su realización; (iv) operaciones en infracción con las disposiciones de
este Reglamento, la legislación vigente y/o las Reglas; (b) Causales propias de operatoria Tarjeta Presente: (i) falta de
firma del Tarjetahabiente, o la misma estuviere enmendada o si tuviere apariencia de falsa; (ii) en caso de Ticket se hallare
incompleto, enmendado o adulterado; (iii) uso de Medio de Captura no homologado o cuya certificación hubiere expirado;
(iv) en caso de diferencias entre el duplicado del Ticket en poder del Tarjetahabiente y el original presentado por el
Establecimiento; (v) operaciones realizadas con ingreso del número de Tarjeta por teclado sin autorización de Fiserv, o
mediando tal autorización, sin que figuren impresos en el Ticket los datos de la Tarjeta grabados en relieve o éste
presentara irregularidades perceptibles a simple vista, tales como la falta de alineación, continuidad o definición, o
diferencias en los tipos, tamaños, espacios o caracteres de las letras o números correspondientes a los datos consignados
en relieve en la Tarjeta del Tarjetahabiente, o falta de alguno de éstos datos; (vi) cuando ante requerimiento de Fiserv, el
Establecimiento no remitiere los Tickets originales dentro de los plazos establecidos; (vii) operaciones desconocidas por
el Tarjetahabiente, (viii) cuando el importe de la operación presentada al cobro exceda el importe de la preautorización
otorgada o sea presentada fuera de la vigencia de la preautorización; (c) Causales propias a operaciones de débito
automático: (i) falta de remisión de la documentación respaldatoria dentro de los cinco (5) días hábiles de requerida; (ii)
imposibilidad de probar el consentimiento del Tarjetahabiente, sea por falta de constancia documentada de la
autorización, de la legislación aplicable y/o las Reglas; (iii) cuando el Tarjetahabiente ejerciera su derecho de
arrepentimiento dentro de los treinta (30) días de la fecha de vencimiento del resumen de cuenta, habilitando a Fiserv a
realizar en forma inmediata la baja del débito automático; (d) Causales propias de operatoria Tarjeta No Presente: (i)
impugnación o desconocimiento de operación por el Tarjetahabiente; (ii) autorización solicitada en forma irregular; (iii)
ausencia de constancia documentada y suficiente dentro de los cinco (5) días de requerida, a criterio de Fiserv, del
consentimiento del Tarjetahabiente; (iv) falta de cumplimiento de las formalidades legales para el perfeccionamiento de
la operación o en la documentación respaldatoria; (v) falta de cumplimiento de las disposiciones de seguridad fijadas por
Fiserv en operaciones de comercio electrónico.
                </p>
                <p>
                12. Incumplimiento. En caso de incumplimiento por parte del Establecimiento de alguna/s de las disposiciones del
presente, Fiserv podrá optar por: (i) requerir la subsanación del incumplimiento en un plazo no mayor a cinco (5) días
hábiles; (ii) suspender la prestación de los Servicios; (iii) declarar resuelta la adhesión (total o parcial a determinada
operatoria o servicio) del Establecimiento en forma inmediata o discontinuar la prestación de cualquiera de los Servicios
Adicionales conforme lo dispuesto en la cláusula 5.1; (iv) cobrar las penalidades, que correspondan según determine
Fiserv; y/o (v) trasladar cualquier penalidad o cargo impuesto por terceros y/o las Marcas a raíz del incumplimiento del
Establecimiento.
12.1. Remedios acumulativos. Los derechos y remedios aquí expuestos son acumulativos y no excluyentes de todo otro
derecho o remedio dispuesto por la ley, pudiendo ser ejercidos en forma separada o conjunta.
                </p>
                <p>
                13. Descuento de operaciones. Fiserv y/o el Pagador podrán descontar mediante débitos o compensaciones de la/s
cuenta/s que mantenga abierta el Establecimiento y aún con percepción directa o judicial, todas las sumas que hubiere
abonado a los Establecimientos con relación a operaciones irregulares o defectuosas, así como cualquier suma de dinero
que adeudare el Establecimiento en virtud del Reglamento.
Fiserv podrá exigir al Establecimiento la operación exclusivamente a través de Tarjeta Presente y demás requisitos de
seguridad que considere convenientes, cuando exista un incremento en el promedio mensual de operaciones irregulares
o defectuosas.
                </p>
                <p>
                14. Daños y perjuicios. El rechazo o descuento de operaciones no eximirá al Establecimiento de su responsabilidad frente
                a los Tarjetahabientes por los daños y perjuicios que dichas transacciones pudieron haberles inferido.
                </p>
                <p>
                15. Presentación de operaciones al cobro. El Establecimiento deberá realizar el cierre de lote y realizar la presentación
de sus ventas a Tarjetahabientes de conformidad con lo aquí previsto o cualquier condición particular para la operatoria
de que se trate. El cierre de lote deberá realizarse de manera diaria en día hábil y al día hábil siguiente en caso de sábados,
domingo y feriados.
La realización del cierre de lote y aún la emisión de un acuse de recibo del mismo por parte de Fiserv, no implica
conformidad con su contenido. El Establecimiento deberá controlar que lo remitido concuerde con lo procesado y
acreditado. Las fechas de presentación de los Tickets de venta dependerán de la modalidad contratada por el
Establecimiento.
a. Pago de operaciones. El pago de las operaciones presentadas al cobro por el Establecimiento será realizado por el
Pagador, con las excepciones establecidas a lo largo del presente y se abonarán una vez concluido su procesamiento, en
los plazos y condiciones acordadas según la operatoria acordada. El pago de operaciones irregulares o defectuosas será
condicional y las mismas podrán ser descontadas con posterioridad. Fiserv pondrá a disposición del Establecimiento, el
resumen de las liquidaciones correspondientes a las operaciones presentadas y procesadas en el Sitio.
Cualquier diferencia y/o controversia respecto de las operaciones aquí detalladas deberá ser planteada por el
Establecimiento dentro de los sesenta (60) días de su presentación. Transcurrido dicho plazo, las mismas se tendrán por
consentidas, produciéndose la caducidad de todo derecho respecto de las mismas.
b. En caso de suspensión, inhabilitación o desafiliación del Pagador, se suspenderá la operatoria hasta tanto el
Establecimiento modifique al Pagador y este cambio sea aceptado y registrado por Fiserv.

                </p>
                <p>
                16. Aranceles y cargos. El Arancel es la comisión que el Establecimiento reconoce al Sistema por toda transacción que
realice con la utilización de Tarjetas. Adicionalmente el Establecimiento deberá abonar los cargos correspondientes a la
prestación de otras operatorias y/o Servicios.
De los importes liquidados al Establecimiento se le deducirá el Arancel (a la tasa fijada en el presente Reglamento, que
podrá ser diferente para cada medio de pago, producto o rubro del Establecimiento), más los cargos, intereses y las
retenciones o descuentos establecidos en la Solicitud del Comercio, en este Reglamento o impuestos por ley o
contractualmente.
16.1. Formas de cobro. El Establecimiento autoriza a Fiserv a cobrar los cargos y cualquier importe que adeudare en virtud
de este Reglamento y la Solicitud del Comercio mediante las siguientes vías: (i) mediante débito directo de la cuenta del
Establecimiento en el Sistema; (ii) a través del servicio de débito automático de las Tarjetas aceptadas; (iii) a través del
débito directo de la cuenta bancaria del
Establecimiento cuya Clave Bancaria Única (CBU) se especifica en la Solicitud del Comercio; y (iv) mediante la deducción
de dichos cargos de la liquidación que le sean adeudados o cualquier otro medio o facilidad que a futuro Fiserv informe.
En caso de optar por el débito automático, el Establecimiento se obliga a adoptar las previsiones necesarias para tener
saldo suficiente disponible en dicha cuenta en el momento en que se produzcan los débitos de las facturas que será el de
su vencimiento o, si fuera un inhábil, el día hábil posterior.
a. Mora e intereses. Si por cualquier motivo y a través de las formas de cobro establecidas en la cláusula 16.1, Fiserv se
viera imposibilitada de cobrar los importes adeudados por el Establecimiento, las sumas en cuestión podrán devengar un
interés moratorio equivalente al doble de la tasa activa promedio que perciba el Banco de la Nación Argentina, para sus
operaciones de descuento de documentos a treinta (30) días, capitalizable semestralmente, contado a partir de la fecha
de pago indicada en la factura respectiva, hasta el momento del efectivo pago.
                </p>
                <p>
                17. Diferimiento de pagos. Retención de fondos. Garantía.
(a) Diferimiento de pagos. Fiserv podrá condicionar el pago de las operaciones al cobro de las sumas adeudas por
considerar que las mismas sean irregulares, defectuosas, sospechosas, fraudulentas o cuando existan obligaciones de
cumplimiento diferido por parte del Establecimiento en caso de cierre de su negocio o finalización de la adhesión del
mismo al Sistema (por la causa que fuere). Adicionalmente, podrá, en cualquier momento, condicionar el pago de
operaciones realizadas en Establecimientos pertenecientes a rubros específicos, a la prestación del servicio o entrega de
la cosa objeto de la operación.
(b) Retención de fondos. Respecto de operaciones ya liquidadas al Establecimiento, su impugnación por el
Tarjetahabiente, el Emisor y/o las Marcas por el volumen de operaciones que a criterio de Fiserv sean sospechadas o
verificadas como fraudulentas, o cuando existan razones de seguridad que razonablemente hagan presumir la ocurrencia
de un riesgo de daño para el Sistema, Fiserv y/o el Pagador podrán retener al Establecimiento presentaciones futuras y/o
pendientes de acreditación y/o cualquier otra suma que tenga depositada en la cuenta abierta en la entidad del Pagador,
(i) hasta el importe que resulte de sumar el equivalente al monto de la/s operación/es impugnada/s y el importe de
cualquier cargo, multa de las Marcas, Arancel o comisión adeudado por el Establecimiento, además de cualquier tasa o
penalidad, actual o anterior; o bien (ii) el total de dichas presentaciones. El plazo de retención no podrá exceder los ciento
veinte (120) días contados desde su instrumentación.
(c) Garantía. El Establecimiento acepta que Fiserv podrá, en cualquier momento, retener la totalidad o una porción de la
liquidación de operaciones del Establecimiento y/o modificar el plazo de pago de éstas y/o exigir dentro de un plazo de
diez (10) días la constitución de un depósito en garantía y/u otras garantías equivalentes, a satisfacción de Fiserv, a fin de
respaldar el cumplimiento de las obligaciones asumidas bajo este Reglamento. La aprobación de la garantía y sus
condiciones estará sujeta a aprobación previa de Fiserv. Dicha garantía no generará interés o compensación alguna en
favor del Establecimiento.
                </p>
                <p>
                18. Responsabilidad. Limitación. Garantías. Exclusión. El Establecimiento asume plena responsabilidad frente a Fiserv
                por los daños y perjuicios de toda clase que se generen como consecuencia del accionar propio, de sus dependientes o de
                terceros que resulten de la inobservancia de las leyes, reglamentaciones o disposiciones del presente, o de otros hechos
ilícitos.
La responsabilidad máxima de Fiserv por daños directos o inmediatos, salvo dolo, en ningún caso superará durante toda
la vigencia del presente, acumulando todos los importes que correspondiera indemnizar, la suma equivalente a las
liquidaciones pagadas los dos (2) meses inmediatamente anteriores al hecho generador.
Queda establecido que Fiserv no asume ninguna responsabilidad de indemnizar por lucro cesante, daños indirectos, daño
moral, penalidades o similares, ya sea bajo la doctrina de los daños colaterales o consecuentes, aun cuando los mismos
pudieran haber sido previstos o se hubiera informado acerca de la posibilidad de que tales daños se produjeran,
renunciando el Establecimiento a formular cualquier reclamo respecto de dichos daños.
Fiserv no será responsable de las pérdidas o daños causados por los Sistemas Propios del Establecimiento, los sistemas,
equipos y/o Medios de Captura provistos por terceros.
Asimismo, sin limitar ninguna garantía mencionada en este Reglamento, Fiserv declara que los Servicios contemplados en
este Reglamento, se ajustarán a todas las leyes, reglamentos y normas de la industria, y requerimientos que resulten
aplicables. El Establecimiento acepta que las garantías contenidas en las disposiciones del presente Reglamento
constituyen la totalidad de las obligaciones y responsabilidades, y que toda otra garantía o condición, explícita o implícita,
queda expresamente excluida del Reglamento. El Establecimiento reconoce y acepta que la adhesión al presente
Reglamento constituye un acuerdo entre partes y no se encuentra alcanzado por las normas de Defensa del Consumidor.
                </p>
                <p>
                19. Indemnidad. El Establecimiento se compromete a mantener indemne a Fiserv por todos los reclamos, quejas, daños,
pérdidas, penalidades, multas, acciones judiciales y/o administrativas, honorarios, costos y costas que Fiserv deba abonar
y/o reciba como consecuencia o con motivo del incumplimiento del Establecimiento de las obligaciones emergentes del
presente Reglamento y/o de las obligaciones que les corresponda por disposición legal. Ni Fiserv ni las entidades del
Sistema, serán responsables ni intervendrán en controversias que el Establecimiento tenga con los Tarjetahabientes,
motivadas en los servicios que el mismo presta, en los bienes que comercializa, en las actividades que desarrolla, en el
monto, composición y/u oportunidad de los débitos efectuados y/o en el cumplimiento o incumplimiento de las
obligaciones legales (en especial a las que protegen los derechos de los consumidores) y contractuales a su cargo, sino
que estas se sustanciarán exclusivamente con el Establecimiento, a quien incumbe íntegramente la responsabilidad
pertinente. Será exclusiva responsabilidad del Establecimiento la atención y satisfacción de dichosreclamos y/o consultas,
debiendo mantener indemne a Fiserv y a las entidades parte del Sistema de los mismos. El incumplimiento de las
disposiciones anteriores habilitará a quien haya debido afrontar los importes reclamados, a descontarlos directamente al
Establecimiento en la forma prevista en la cláusula 13 del presente Reglamento.

                </p>
                <p>
                20. Auditoría. El Establecimiento se obliga a permitir el acceso a sus instalaciones y equipos, en días y horario hábiles, de
Fiserv o terceros designados al efecto, para auditar el funcionamiento de los Servicios y el cumplimiento de las
disposiciones del presente. El incumplimiento de esta obligación tornará susceptible de rechazo y/o descuento de todas
las operaciones presentadas con posterioridad al mismo, sin perjuicio de la aplicación de las sanciones previstas en la
cláusula 12
                </p>
                <p>
                21. Medidas de Seguridad. El Establecimiento se compromete a dar cumplimiento, a su exclusivo cargo, con todas las
medidas de seguridad que correspondan conforme lo establecido en este Reglamento, las que exijan las Reglas y las que
le sean requeridas en el futuro por Fiserv. Dichas medidas de seguridad deberán ser implementadas en los plazos que
Fiserv disponga en la comunicación correspondiente o, en su defecto, en los plazos que resulten técnicamente factibles y
que no deberán ser superiores a sesenta (60) días corridos a contar de la notificación que Fiserv o los titulares de las
Marcas efectúen al respecto. Las inversiones que resulten necesarias para cumplir con las medidas de seguridad y demás
condiciones establecidas por Fiserv, así como para el mantenimiento de la autorización de la Plataforma del
Establecimiento (conforme se indica en la cláusula 28.1), estarán a cargo exclusivo del Establecimiento, sin que éste pueda
efectuar reclamo alguno a Fiserv. En caso que el Establecimiento no pudiese implementar las medidas de seguridad
requeridas por disposiciones legales, por Fiserv o por los titulares de las Marcas, ya sea por razones técnicas, financieras
y/o comerciales debidamente fundadas, el Establecimiento o Fiserv podrán resolver el presente Reglamento mediante
notificación escrita con al menos quince (15) días de antelación sin estarsujetos a ninguna sanción y sin que ello le otorgue
derecho a reclamo alguno contra la otra parte. El Establecimiento se compromete a informar a Fiserv y/o a las Marcas en
caso de que aplique, cualquier falla y/o incidente que pudiera afectar o vulnerar la información de las transacciones.
                </p>
                <h5>Capítulo 3 Operatoria Tarjeta Presente (POS) y NO PRESENTE</h5>
                <p>
                22. Operatoria de captura de datos
22.1. Aplicación. Las disposiciones de este capítulo serán de aplicación a aquellas operaciones realizadas por los
Tarjetahabientes en los Establecimientos a través de un Medio de Captura, que es utilizado también para la solicitud de
Autorización electrónica y presentación electrónica de operaciones al cobro.
22.2. Homologación de Medio de Captura. Los Medios de Captura y/o equipos complementarios con los que el
Establecimiento pretenda operar con el Sistema, deberán ser previamente homologados o certificados por Fiserv o
terceros designados al efecto, quienes controlarán que tales Medios de Captura reúnan las condiciones técnicas y de
seguridad indispensables para operar con el Sistema. El costo de la homologación y certificación estará a cargo del
Establecimiento. Fiserv podrá en cualquier momento exigir la adecuación del Medio de Captura a nuevas normas o pautas,
con el solo requisito de comunicarlo al Establecimiento con una anticipación previa de treinta (30) días.
No podrá entenderse que la homologación implica el otorgamiento de garantía alguna por parte de Fiserv o el tercero
homologante, no siendo éstos responsables de errores o defectos en el funcionamiento de los Medios de Captura
homologados.
22.3. Condiciones de uso de los Servicios. El Establecimiento se obliga a evitar cualquier tipo de acción que pueda dañar
a sistemas, equipos o servicios accesibles directa o indirectamente a través del Sistema (entre elloslos Medios de Captura)
y especialmente a no utilizarlos para: (i) la transmisión de toda otra información distinta de los datos de las transacciones
autorizadas por el Sistema; (ii) la transmisión de información que atente contra los derechos de terceros, la moral o la egislación vigente; (iii) fraguar un archivo o de otra forma manipularlo para evitar la detección del origen de los datos
transmitidos; (iv) la transmisión de cualquier material que contenga virus de computación o cualquier otro tipo de código
de computación, archivos o programas destinados a interrumpir, destruir o limitar la funcionalidad de cualquier programa
o equipo; (v) la transmisión de información que no tiene derecho a transmitir, tal como transacciones de otros
establecimientos; (vi) interrumpir, trastornar o perturbar de cualquier forma el normal funcionamiento del Sistema.
22.4. Tickets. Cada operación a través de un Medio de Captura deberá generar un Ticket (digital o físico), por lo menos
por duplicado. El original firmado por el Tarjetahabiente deberá quedar archivado en el Establecimiento en que la
operación se haya celebrado. Su duplicado será entregado físicamente, enviado por correo electrónico o por otro medio
que pudiera habilitarse en el futuro y servirá como constancia de la operación.
El Establecimiento deberá controlar que los datos del Ticket o que aparezcan en el visor del Medio de Captura, si tuviera
alguno, coincidan con los impresos y grabados en la Tarjeta (frente y dorso). Cuando los datos difieran,serán de aplicación
las disposiciones de la cláusula 11.2 del Reglamento. También deberá ingresar a través del teclado del Medio de Captura
los cuatro (4) últimos dígitos u otros datos adicionales de la Tarjeta, en aquellos casos en que Fiserv lo requiera.
Los Establecimientos deberán guardar los originales de los Tickets correspondientes a esta operatoria por el término de
un (1) año en caso de operaciones de contado y dos (2) años en caso de operaciones en cuotas y estarán obligados a
presentarlos dentro del plazo de cinco (5) días corridos en caso de que Fiserv lo requiera a través de los canales de
comunicación que estime pertinentes como ser el correo electrónico del Establecimiento.
22.5. Operación sin firma. Fiserv podrá autorizar de manera previa a determinados Establecimientos a operar con lectura
de banda magnética o chip, aunque sin firma del Tarjetahabiente ni emisión de comprobante respaldatorio de la operación
o emisión de Ticket sólo para el Tarjetahabiente. El Establecimiento asumirá exclusivamente el riesgo de la operatoria y
consecuentemente, el simple desconocimiento de la operación por el Tarjetahabiente será causal de rechazo o descuento
de la misma. Fiserv podrá limitar el número de operaciones a realizar por un mismo Tarjetahabiente en dicho
Establecimiento, según parámetros que establecerá periódicamente, sin necesidad de informarlo al Establecimiento.
22.6. Preautorización. Operación mediante la cual el Establecimiento efectúa una reserva de fondos sobre la cuenta del
Tarjetahabiente, hasta que se reciba la confirmación de la realización de la operación, a través de su presentación al cobro,
o bien expire su plazo de validez. El monto de la operación no deberá exceder el de la preautorización, en cuyo caso la
misma podrá ser rechazada o descontada total o parcialmente.
El Establecimiento no deberá entregar mercaderías ni prestar servicios cubiertos por una preautorización una vez
notificada la cancelación o rechazo de la preautorización.
22.7. Anulación. Una anulación es una transacción que permite dejar sin efecto operaciones de compra y/o devolución.
La misma deberá realizarse antes del cierre de lote correspondiente a la presentación la transacción original. El
Establecimiento se compromete a no aplicar políticas discriminatorias en la anulación y/o de devolución de transacciones
de las distintas Marcas.    
           </p>
                <p>
                23. Lectura de código QR. Los Equipos de Medios de Captura de Fiserv que se encuentren habilitados a tal efecto podrán
disponibilizar Códigos QR que permitirán la aceptación de transacciones de Billeteras /Aplicaciones Móviles conforme se
detalla en el número 32. Ese Código QR poseerá la información requerida por la regulación aplicable para cursar pagos.
Cada Código QR posee información específica de la transacción a realizar de manera de gestionar la seguridad de la red
de pagos
                </p>
                <p>
                24. Link de Pago. Se refiere al medio de captura de la operación a través de un link que generará en el Sitio que permite
al Establecimiento operar con un dispositivo inteligente y/o una computadora con conexión a la red de Internet. El
Establecimiento podrá acceder al Link de Pago con su usuario y contraseña en el sitio que Fiserv informe a tal efecto. Para
ingresar una operación, el Establecimiento deberá completar todos los campos requeridos por el sitio y este generará un
link que permitirá al tarjetahabiente ingresar los datos de la compra que desea realizar y una vez enviado, se cursará la
operación para su autorización o rechazo según corresponda. A las operaciones capturadas bajo el Link de pagos le serán
aplicables las disposiciones de tarjeta no presente prevista en el punto 31.
                </p>                
                <p>
                25. Equipos de Medios de Captura. Las siguientes reglas resultarán aplicables para el caso en que el Establecimiento
solicitare los Medios de Captura de Fiserv o una sociedad del Grupo, entre ellas Posnet S.R.L., para la solicitud de
Autorización electrónica y presentación electrónica de operaciones al cobro (el “Servicio de Captura”).
25.1. La conexión con los distintos sistemas de procesamiento dependerá de la tecnología del Medio de Captura utilizado
para la prestación de los Servicios de Captura. Dicha conexión podrá requerir el acceso a internet y/o a la red de telefonía
y/o hardware y/o software adicional, que en su caso serán por cuenta y cargo del Establecimiento.
El Establecimiento toma conocimiento que la transmisión de los datos podrá involucrar transmisiones a través de varias
redes o en forma directa con el centro de procesamiento correspondiente, de acuerdo con cada sistema.
Será a cargo del Establecimiento el soporte necesario para la prestación de los Servicios de Captura, que incluye el
equipamiento (hardware), programas (software), vínculos, interfaces, alimentación eléctrica, etc., compatibles para
operar con el Sistema de Fiserv.
25.2. Los Servicios de Captura son para uso exclusivo de un solo Medio de Captura por abono, quedando expresamente
prohibida la utilización de servidores u otras tecnologías que multipliquen una conexión en varias conexiones.
25.3. El Establecimiento reconoce que los Medios de Captura aquí provistos pueden no ser compatibles con otros sistemas
de procesamiento de Tarjetas y no existirá obligación por parte de Fiserv de adaptar el Medio de Captura para que resulte
compatible con otros sistemas de procesamiento y no será responsable por la demora y/o por las pérdidas ocasionadas
por los sistemas de procesamiento de terceros. Asimismo, el Establecimiento reconoce que todas las transacciones
realizadas a través de su Medio de Captura se presumen autorizadas por el propio Establecimiento y, por lo tanto, será el
responsable por el uso del Medio de Captura en infracción a las Reglas y/o los sistemas de procesamiento de Tarjetas de
terceros.
25.4. El Establecimiento será responsable de informar los datos de identificación para la programación de los Medios de
Captura con que opere, tales como denominación, N° de CUIT, N° de Establecimiento, etc., y de controlar la exactitud de
la programación efectuada, asumiendo la total y exclusiva responsabilidad en caso de errores en la programación. Se
entenderá que los Medios de Captura han sido programados correctamente y se encuentran en perfectas condiciones de conservación, funcionamiento y operabilidad, si el Establecimiento no manifiesta lo contrario dentro de las 24 hs. de
instalada o configurada.
25.5. Funcionamiento de los Medios de Captura.
(i) El Establecimiento reconoce y acepta que el correcto funcionamiento de los Medios de Captura depende, a su vez, del
correcto funcionamiento a modo ejemplificativo del equipamiento (hardware), programas(software), vínculos, interfaces,
alimentación eléctrica, etc. propios del Establecimiento o provistos por terceros, por lo que Fiserv en ningún caso será
responsables por las fallas y/o deficiencias y/o interrupciones de los mismos.
(ii) Los Servicios de Captura incluyen la reparación y provisión de insumos de los Medios de Captura sin cargo adicional,
siempre que no correspondan a daños provocados por la utilización indebida de los mismos; en los restantes casos, los
servicios requeridos serán facturados y el Establecimiento los abonará a la tarifa vigente en tal oportunidad.
(iii) Fiserv no asume responsabilidad por el funcionamiento de los Medios de Captura provistos ni otorga garantía alguna
respecto de la misma. Las únicas garantías aplicables, cuando corresponda, serán las otorgadas por el fabricante de los
Medios de Captura provistos.
25.6. Comodato. Los Medios de Captura serán provistos bajo comodato, el Establecimiento reconoce expresamente que
Fiserv, según corresponda, conserva la propiedad y posesión civil de los Medios de Captura provistos, adquiriendo el
Establecimiento solamente un derecho personal de uso gratuito, sin que ello implique la transferencia de derecho de
propiedad alguno sobre la misma en su favor.
Fiserv queda facultada a solicitar la devolución inmediata de los Medios de Captura provistos en cualquier momento o
cuando deje de prestar los Servicios de Captura al Establecimiento, por cualquier causa. En caso de que los Medios de
Captura no sean restituidos o los mismos sean devueltos en malas condiciones ‐ajenas al deterioro normal‐ a opción de
Fiserv-, el Establecimiento deberá abonar el costo de reposición o, en su caso, el costo de reparación o de los repuestos
que sean necesarios para que los mismos vuelvan a funcionar correctamente, emitiendo Fiserv la correspondiente factura.
25.7. Responsabilidad. Queda expresamente establecido que la contratación de un seguro para asegurar los Medios de
Captura no exime de responsabilidad al Establecimiento respecto de la guarda, conservación y devolución en buen estado
de los mismos, ni del pago directo de su reposición en caso de que los Medios de Captura hayan sido hurtados, robados o
se le hayan ocasionado daños irreparables imputables al Establecimiento, por el monto que Fiserv razonablemente estime.
En su caso, dicho importe será facturado y podrá ser debitado al Establecimiento conforme la modalidad prevista para el
cobro del abono mensual.
25.8. Cargos y condiciones de pago. El Establecimiento se obliga al pago del abono mensual establecido en la Solicitud
del Comercio más el IVA correspondiente y el cargo de instalación de los Medios de Captura. Los cargos serán percibidos
por cualquiera de las vías previstas en la cláusula 16.1.
El cargo de instalación deberá ser abonado por el Establecimiento una vez instalado el Medio de Captura, pudiendo ser
facturado en forma conjunta con el primer abono mensual.
Fiserv podrá modificar los cargos y condiciones de pago de los Medios de Captura en cualquier momento mediante
preaviso de diez (10) días corridos a través de alguno de los medios indicados en la cláusula 8. Si dentro de tal plazo los
Establecimientos no optan por la rescisión, se entenderá que las modificaciones son aceptadas y de cumplimiento
obligatorio.
Por los Medios de Captura se emitirá la correspondiente factura electrónica conforme los requisitos fiscales vigentes la
que, previa registración, podrá ser visualizada y descargada sin costo del sitio web:
https://www1.posnet.com.ar/posnetonline.
En caso de desconexión por falta de pago u otra causa imputable al Establecimiento, éste deberá pagar el cargo de
reconexión, equivalente al 50 % del cargo de instalación vigente a tal fecha y cualquier otra deuda pendiente.
El Establecimiento podrá acceder bonificaciones u otros beneficios que Fiserv le otorgue (los “Beneficios”), los que serán
comunicados por los medios de comunicación previstos en la cláusula 8.
Salvo que existan otras condiciones pactadas entre el Establecimiento y Fiserv, este último tendrá el derecho de modificar
o cancelar los Beneficios en cualquier momento.
25.9. Medios de Captura
Medios de Captura disponibles para el Establecimiento:
(i) Terminal en línea. El equipo de terminal punto de venta podrá ser; WIFI – GPRS, ETHERNET, DIAL UP, GPRS FIJO o
Móvil según el tipo de conectividad.
(ii) Smart POS (Clover). Dispositivo inteligente basado en Android que ofrece un set de aplicativos de gestión y
administración preinstalados y basado en la nube. Se disponibilizarán los modelos que se indiquen en Solicitud de
Comercio.
(iii) Pin Pads / Panel digital de firma. Dispositivo que se conecta a un Sistema Propio y este último lo comunica con el
Sistema. Permite al Establecimiento ingresar el PIN, banda magnética, chip y contacless y encriptar el mensaje. El
Establecimiento solamente podrá usar el dispositivo con el Medio de Captura y no para un fin distinto al previsto en el
presente
(iv) E‐Posnet. Gateway de pagos conforme se detalla en el punto 27.
(v) Posnet móvil. Dispositivo que se conecta a un Smartphone o Tablet y permite la captura de transacciones.
(vi) Link de Pago. Conforme se establece en la sección 24.
(vii) Terminal Virtual. Aplicación de celular que permite aceptar pagos
25.10. Soluciones de integración al Medio de Captura
El Establecimiento podrá contratar cualquiera de los servicios detallados. Fiserv no asume ninguna responsabilidad ni se
obliga a ejercer control alguno sobre la información transmitida entre el Medio de Captura y la pc del Establecimiento.
(i) Lote/POS a PC. Permite transmitir los datos de las transacciones desde el Medio de Captura a una pc del
Establecimiento. Se entrega en comodato un programa de computación y un cable para la conexión del Medio de Captura
a la pc.
(ii) Lote IP. Permite transmitir los datos de las transacciones vía Internet a un servidor propio del Establecimiento. A tal
fin se entregará un software para que el Establecimiento instale y pueda operar por este medio. (iii) POS Integrado (“Trx a Trx”). Permite conectar el sistema de facturación con un Medio de Captura transmitiendo las
transacciones al sistema del Establecimiento de forma “en línea”. Se pondrá a disposición un programa y un cable de
conexión.
(iv) Web Posnet. Este servicio web brinda la posibilidad al Establecimiento de solicitar autorización y hacer la presentación
virtual para la acreditación de sus operaciones. A tal fin se le asignará un usuario y contraseña, para que pueda operar con
este servicio a través del sitio www.posnet.com.ar/webposnet. Se regirá por lo previsto en la sección 31.


                </p>
                <p>
                26. Equipos propios o de terceros. En caso de que el Establecimiento utilizase Medios de Captura, Sistemas Propios y/o
equipos y/o dispositivos POS y dispositivos especiales para la encriptación de pines propios o de terceros, los mismos
deberán requerir certificación y homologación previa de Fiserv o de quien esta indique y deberán ser compatibles y
cumplir con las normas PCI y con los requisitos técnicos, operativos y de seguridad exigidos por las Reglas. Fiserv podrá en
cualquier momento exigir la adecuación del Medio de Captura a nuevas normas o pautas, con el sólo requisito de
comunicarlo al Establecimiento con una anticipación previa de treinta (30) días. Si el Establecimiento decide utilizar un
equipo que no es provisto por Fiserv o una empresa del mismo Grupo el Establecimiento acepta y asume exclusiva
responsabilidad en garantizar que su equipo se ajusta y se ha instalado de acuerdo a las Reglas y normas establecidas por
los titulares de las Marcas y Fiserv. El Establecimiento asume la responsabilidad de presentar las transacciones de
conformidad con este Reglamento de modo que Fiserv pueda procesarlas. Fiserv no será responsable por cualquier
pérdida y/o demora que pueda derivarse por la utilización de equipos no provistos por Fiserv. Bajo ningún concepto podrá
entenderse que la homologación implica el otorgamiento de garantía alguna por parte de Fiserv, no siendo éstos
responsables de errores o defectos en el funcionamiento de los equipos homologados y certificados.
                </p>

                <p>
                27. E‐posnet, Servicio de gateway de pagos. El servicio de “Gateway” de pagos permitirá la captura de todas aquellas
transacciones realizadas por los Tarjetahabientes de las Tarjetas bajo la modalidad de comercio electrónico, a través de la
plataforma de comercio electrónico que el Establecimiento posea en internet (la “Plataforma”). Este servicio permite la
conexión del Establecimiento con el centro de procesamiento de los distintos sistemas de medios de pago habilitados al
efecto, para la validación, autorización y/o captura de transacciones. Dicha conexión será posible a través de la
implementación y configuración en la Plataforma del Establecimiento de un software provisto por Fiserv y que permite la
remisión de los datos de la transacción –incluidos los datos de la Tarjeta‐ al procesador correspondiente, que es quien se
encarga del procesamiento de las transacciones, resolviendo en cada caso concreto sobre su aprobación o rechazo. Será
aplicable tanto para operatoria de tarjeta presente como operatoria de tarjeta no presente.
                   </p>
                <p>
                28. Los servicios se encuentran limitados únicamente a las transacciones que se realicen bajo la modalidad de comercio
electrónico y venta telefónica a través de la Plataforma del Establecimiento, quedando excluidas aquellas transacciones
con Tarjetas realizadas por otros medios como ser ventas en locales comerciales y por correspondencia.
28.1. Requisitos previos. Para la prestación del servicio, el Establecimiento deberá previamente: (i) homologar la
Plataforma de manera que cumpla con las normas de seguridad vigentes establecidas por las normas que regulan el
comercio electrónico y las condiciones de seguridad particulares exigidas por Fiserv y/o por cada sistemas de Tarjetas; (ii)
mantener su sistema acorde a las condiciones de seguridad homologadas y adecuarlo a las nuevas condiciones que Fiserv
y/o cada sistema de Tarjeta requieran de tiempo en tiempo, lo que podrá ser comprobado mediante la realización de las
revisiones, auditorías y/o pruebas que Fiserv y/o las Marcas establezcan; y (iii) establecer los vínculos necesarios para la
operatoria, los que serán a su cargo.
28.2. Gastos de conexión. Todos los costos, gastos, tarifas y demás importes que deban abonarse por la conexión y
mantenimiento de vínculos entre los equipos del Establecimiento y los de Fiserv correrán por cuenta exclusiva del
Establecimiento.
28.3. Niveles de Servicio. El Servicio de E‐Posnet se prestará de conformidad con los niveles de servicio detallados a
continuación: Las siguientes premisas serán aplicables a los mismos: (i) se excluirá del cálculo de medición de la métrica y
Fiserv no se responsabilizará por las deficiencias, fallas, suspensiones y/o interrupciones derivadas de lo siguiente: (a)
cualquier cambio, configuración, instalación y/o reprogramación informado por Fiserv; (b) cualquier sistema, red, vínculo
y/o capa de transporte no mantenido por Fiserv, entre ellos la disponibilidad de la red externa (internet); (c) cualquier
cambio que el Establecimiento hiciere sobre su Plataforma que no estuviere de acuerdo con las sugerencias y/o
recomendaciones de conectividad estándar de Fiserv; (ii) las mediciones se realizarán desde y hasta que la transacción
ingresa/sale de la infraestructura o sistemas de Fiserv, (iii) La medición se hará por la cantidad de minutos del mes
calendario en curso (“Período de Medición”).

                </p>
                <p>
                29. Operatoria con tarjetas de débito.
29.1. Concepto. Todas las transacciones con Tarjetas de débito son en línea,se canalizan a través de un Medio de Captura
y son autorizadas por el Emisor. Dichas transacciones podrán requerir el ingreso de un número de identificación personal
confidencial –PIN‐ por parte del Tarjetahabiente. Las compras con Tarjetas de débito no permiten operaciones en cuotas,
débito automático, ni operaciones a distancia, salvo autorización expresa y por escrito de Fiserv.
29.2. Modalidades disponibles. Las Tarjetas de débito están habilitadas para realizar en los Establecimientos las
transacciones detalladas a continuación. Es obligatoria la aceptación de las transacciones de compra, devolución,
reversión y anulación.
(a) Compra. Transacción por la cual un Tarjetahabiente cancela, a través de la utilización de su Tarjeta, una deuda
generada por la adquisición de un bien o servicio en un Establecimiento.
(b) Compra con retiro de fondos (cashback). Transacción por la cual se realiza una compra y paralelamente efectúa una
extracción de fondos de su cuenta bancaria.
La extracción de fondos estará limitada a un porcentaje sobre el monto de la compra o a un importe predeterminado que
es fijado periódicamente Fiserv, sin necesidad de notificarlo al Establecimiento. Toda transacción que supere dicho
importe será rechazada.
(c) Devolución. Transacción por la cual se le reembolsan los fondos a un Tarjetahabiente ya que éste entregó en
devolución los bienes o servicios adquiridos en el Establecimiento. Será considerada como una transacción nueva y no
está ligada a una transacción de compra realizada previamente.
El Sistema establecerá un límite máximo de devolución cuyo importe será fijado Fiserv. Toda transacción de devolución
que supere dicho importe será rechazada.
El monto de la transacción es debitado por el Pagador al Establecimiento en el plazo de pago pactado.
(d) Anulación. Aplicará lo dispuesto en la cláusula 22.7 del presente
29.3. Presentación de operaciones al cobro. Debido a que todas las transacciones con Maestro y Visa Electrón
son realizadas a través de Medios de Captura que están conectadas en línea, el Establecimiento no deberá efectuar
presentaciones ya que las mismas se realizan en forma automática al efectuarse el corte de la red Maestro y Visa Electron.
En cada presentación automática, sólo se considerarán las transacciones realizadas dentro del día de negocio,
entendiendo por tal al tiempo que transcurre entre dos cortes consecutivos de red. El momento de realización de los
cortes de red es determinado periódicamente por Fiserv, sin necesidad de informarlo a los Establecimientos.
29.4. Liquidación y pago. El proceso de liquidación de las transacciones al Establecimiento se llevará a cabo todos los días
hábiles bancarios correspondientes a la República Argentina, mientras que la fecha de pago será determinada por el día
de negocio de cada transacción y el plazo de pago pactado al momento de la adhesión.
                </p>
                <p>
                30. Tarjetas contactless o sin contacto. Esta operatoria permitirá al Establecimiento realizar sus ventas mediante
Tarjetas que cuenten con la funcionalidad contactless o sin contacto, acercándola al Medio de Captura habilitado al
efecto, sin la obligación de requerir firma del Tarjetahabiente, emitir Ticket o comprobante respaldatorio de la
operación. Bajo esta modalidad el Establecimiento no podrá aceptar operaciones que excedan el monto máximo que
establezca Fiserv periódicamente. El riesgo de esta operatoria en relación al cuestionamiento de las operaciones será
asumido por quien establezcan las Reglas.


                </p>
                <p>
                31. Tarjeta no presente.
31.1. Ámbito de aplicación. Se regula el procedimiento para la realización de operaciones mediante la modalidad
de Tarjeta No Presente (M.O.T.O.), sin lectura de banda, chip o contacless, con los medios de pago del Sistema autorizados
para ello, en los Establecimientos habilitados para esta operatoria.
Para, ello Fiserv asignará al Establecimiento un código de establecimiento especial que sólo podrá ser utilizado por éste a
efectos de la operatoria modalidad de operatoria "a distancia" o Tarjeta No Presente para la cual sea autorizado, no
pudiendo ser utilizado para efectuar presentaciones de otra operatoria diferente.
Fiserv podrá, en cualquier momento, suspender, modificar o cancelar, total o parcialmente, en forma general para todos
los Establecimientos o individual para un Establecimiento determinado la operatoria aquí detallada.
31.2. Información que debe requerirse para la Autorización para ventas telefónicas. El Establecimiento deberá
comunicarse con el Centro de Autorizaciones de Venta a Distancia para obtener la Autorización correspondiente y deberá
requerir al Tarjetahabiente antes de realizar la operación los siguientes datos y/o aquellos que Fiserv pudiera requerir a
ese momento: 1) Datos personales del Tarjetahabiente. a) Apellido y nombre impreso en la tarjeta; b) Tipo y número de
documento de identidad; c) Domicilio donde recibe el resumen de cuenta; d) Número de teléfono del Tarjetahabiente, 2)
Datos de la tarjeta de crédito. a) Número de Tarjeta; b) Vencimiento de la Tarjeta; c) Código de seguridad de la Tarjeta;
d) Apellido y nombres de los adicionales (si compra el adicional) o del titular de la cuenta
(cuando el que compra es un titular), 3) Datos de la operación. a) Importe total de la operación; b) Moneda en que se
pactó la operación; c) Cantidad de cuotas; d) Domicilio donde serán remitidos los productos o lugar de prestación de los
servicios; e) Si se trata de una suscripción: período de la misma.
31.3. Riesgos de la operatoria. Operaciones impugnadas. Atento el alto potencial de fraude que presenta este tipo de
operación el riesgo de la operatoria será asumido de manera exclusiva por el Establecimiento que reconoce que la sola
presentación de una impugnación de la operación por el Tarjetahabiente, autorizará el descuento automático de los
importes acreditados y desconocidos.
Sin perjuicio de lo dispuesto anteriormente, por cada operación impugnada el Establecimiento deberá remitir a Fiserv,
dentro de los cinco (5) días corridos siguientes, las explicaciones que correspondan por escrito con firma de apoderado de
forma tal que la misma pueda ser entregada al Tarjetahabiente para su análisis. Deberá adjuntar al mismo copia del remito,
y demás documentación que acredite el perfeccionamiento de la operación y la entrega de los productos.
31.4. Entrega de los productos. Para certificar la entrega de los productos el Establecimiento emitirá un remito en el que
deberán consignarse todos los recaudos exigidos por la legislación aplicable para la entrega de productos bajo la
modalidad de que se trate. El Establecimiento deberá archivar por el lapso mínimo de un (1) año para operaciones de
contado y dos (2) para operaciones en cuotas, los remitos o comprobantes de prestación de servicio, según el caso, y toda
otra documentación que permita certificar el perfeccionamiento de las operaciones y entregarla a Fiserv toda vez que  ésta lo requiera. El cumplimiento de los requisitos anteriormente establecidos no obstará al rechazo o descuento de las
operaciones cuando sean impugnadas por el Tarjetahabiente.
31.5. Pago Puntual Telefónico. Bajo esta modalidad no se podrán aceptar operaciones que excedan el monto máximo y/o
que no cumplan con los requisitos que Fiserv establezca periódicamente.
31.6. Arrepentimiento. Los Establecimientos deberán otorgar y permitir a los Tarjetahabientes el ejercicio del derecho de
arrepentimiento, sin invocación de causa alguna en los tiempos y en las condiciones que establezca la legislación vigente.
31.7. Presentación de operaciones al cobro. Los Establecimientos deberán documentar sus operaciones y presentarlas al
cobro mediante Medios de Captura u otra modalidad autorizada expresamente por Fiserv. No se podrán presentar al
cobro las siguientes operaciones: i) aquella operación por la que no se hizo entrega al Tarjetahabiente de los productos o
pasajes (vouchers) o no se prestó el servicio, según corresponda, ii) una operación luego de transcurridos treinta (30) días
de la fecha de asignación del código de autorización.
                </p>
                <p>
                32. Pagos Inmediatos. Se refiere a la operación de pago que realiza un usuario de una Billetera /Aplicación Móvil con los
pagos instantáneos habilitados en las mismas para la adquisición de bienes, o para el pago de la prestación de servicios
mediante la lectura de un Código QR expuesto por el Medio de Captura y que se efectiviza a través de la solicitud de
débito a la cuentas bancarias o virtuales (CBU o CVU) asociada a la Billetera Mobile/Aplicativo de Pagos, “DEBIN QR”. Esta
modalidad no permite el pago de la operación en cuotas.
32.1. Presentación de operaciones al cobro. Liquidación y pago. Por tratarse de una operación que transferencia entre
cuentas bancarias no se requiere presentación al cobro mediante cierre de lote. El proceso de liquidación de las
transacciones realizadas a través de Pago Inmediato a los Establecimientos se llevará a cabo todos los días de la semana
siendo su acreditación casi inmediata a la transacción1 y serán abonadas por el Pagador la cuenta del Establecimiento
abierta en la entidad a tal efecto. Está acreditación será neta de los cargos, tasa de descuento, impuestos y/o retenciones
impositivas que corresponda.
32.2. Confirmación del Pago. Para entender que la operación ha sido realizada exitosamente, el Establecimiento deberá
aguardar la confirmación de la autorización que exhibe el Medio de Captura.
32.3. Ticket. El Medio de Captura, dependiendo del modelo, podrá o no emitir ticket. Sin perjuicio de ello, en caso de que
así lo haga esta operatoria no requiere firma.
32.4. Cancelaciones. Solamente podrán realizarse siempre y cuando el Medio de Captura no hubiera emitido la orden de
confirmación.
32.5. Desconocimientos y Contracargos. La operatoria de pago inmediato, por ser una operatoria de débito con protocolo
de identidad en la Billetera /Aplicación Móvil no habilita disputas por desconocimientos o contracargos. Cualquier
cuestionamiento vinculado a esta operatoria deberá ser realizado por el usuario directamente ante el Establecimiento.
32.6. Devoluciones. Esta operatoria no admite devoluciones y/o reversa. Todo pedido de devolución deberá cursarse por
otro medio que el Establecimiento estime pertinente.
                </p>
                <p>
                33. Débito automático.
33.1. Ámbito de aplicación. Regula el procedimiento para la adhesión al servicio de cobro de débito automático al que
adhieren los Tarjetahabientes para el pago de facturas periódicas emitidas por el Establecimiento. El débito automático
es únicamente una modalidad de aceptación de pagos y no tiene relación con la operación cuyas facturas se debitan, no
garantizan la cobrabilidad futura de las mismas y en oportunidad del procesamiento de cada una de las facturas se
determina la procedencia o rechazo de su débito siguiendo lo previsto para la Autorización de las mismas.
33.2. Modos del servicio.
32.2.1. Los Tarjetahabientes podrán gestionar su adhesión, baja y/o suspensión momentánea al servicio de débito
automático de los siguientes modos:
a) A través de los medios que Fiserv o los Emisores de las Tarjetas pongan a disposición al efecto;
b) A través del Establecimiento, en cuyo caso el mismo se responsabilizará por obtener y documentar el consentimiento
de los Tarjetahabientes.
32.3. Normas de procedimiento.
32.3.1. Código especial. Fiserv asignará al Establecimiento un código de establecimiento especial que sólo podrá ser
utilizado por éste para esta modalidad no pudiendo ser utilizado bajo ninguna circunstancia y/o motivo para efectuar otra
operatoria distinta.
32.3.2. Adhesiones y/o bajas al servicio. La información relativa a altas y/o modificaciones de Tarjetahabientes,
solicitadas ante el Establecimiento será enviada por éste a Fiserv en los tiempos y modos que informe.
32.3.3. Base de datos. El Establecimiento llevará una base de datos actualizada de Tarjetahabientes adheridos alservicio
de débito automático, incorporando las altas, bajas y/o modificaciones informadas por Fiserv, como las recibidas
directamente.
El Establecimiento será exclusivamente responsable frente a los Tarjetahabientes por los rechazos, falta de procesamiento
o procesamiento indebido de operaciones, motivados en el incumplimiento de las obligaciones a su cargo o error en el
manejo de la base de Tarjetahabientes adheridos.
32.3.4. Procesamiento de las operaciones. Dentro de los cinco (5) días hábiles de la recepción de las instrucciones de
débito, Fiserv procesará las operaciones para su débito a los Tarjetahabientes. Cuando el vencimiento de la operación
exceda el término anterior, la Fiserv podrá diferir la fecha de procesamiento hasta la de vencimiento.
Fiserv pondrá a disposición del Establecimiento el detalle de los débitos efectuados, así como de los no realizados. Respecto
de éstos últimos, el Establecimiento informará al Tarjetahabiente delrechazo y gestionará su cobro por otra vía. En los casos
de Tarjetahabientes adheridos alservicio de débito automático, el Establecimiento incluirá en lasfacturas y/o liquidaciones
que emita, la leyenda: "Se debitará de su tarjeta de crédito".
                </p>
                <h5>Capítulo 4 Formas de Venta</h5>
                <p>
                33. Operatoria de contado: Es la forma de venta que le permite al Establecimiento especialmente habilitado al efecto a
realizar la venta de sus mercaderías y servicios en un solo pago o cuota, aplicando para esta modalidad lo dispuesto en la
cláusula 34 en lo que fuera compatible.
</p>

                <p>
                34. Operatoria en Cuotas
34.1 Establecimientos habilitados. Los Establecimientos especialmente habilitados al efecto podrán vender sus mercaderías
y servicios en cuotas hasta el máximo de cuotas que autorice Fiserv. La autorización conferida podrá ser dejada sin efecto en
cualquier momento por el Pagador, aún sin necesidad de causa que lo justifique.
34.2 Precio. El precio de venta de las mercaderías y servicios en cuotas deberá ser el mismo vigente para las operaciones en
efectivo y de contado, al cual se le adicionará el importe correspondiente a la financiación o cargo financiero.
34.3 Limitaciones a la operatoria en cuotas. Fiserv podrá excluir algunos medios de pago de la operatoria en cuotas y/o
contado y/o establecer otras limitaciones, lo cual le será notificado al Establecimiento al momento de la autorización.
34.4 Modos de financiación. El Establecimiento tendrá disponibles las siguientes formas de financiación, a las que
corresponderá un número de Establecimiento diferente para cada una de ellas:
a) Financiación Fiserv: Mediante este tipo de financiación el Establecimiento puede efectuar operaciones en cuotas con los
medios de pago del Sistema, hasta el máximo autorizado por Fiserv. En este sentido, Fiserv, en la medida en que el
Establecimiento optare por operar bajo esta modalidad, pagará en un solo pago cada una de las cuotas, dentro de los 2 (dos)
días hábiles siguientes a la presentación de la transacción. Esta financiación se calculará mediante la siguiente fórmula: Costo
Financiero (Financiación Fiserv): (Importe Neto sin descuento Emisor) * (Días de Financiación) * (TNA / 365)
Dónde:
Días de Financiación = se refiere a los días corridos entre la fecha de pago de cada una de las cuotas vigente en el Sistema para
el Establecimiento y la fecha de pago de la transacción bajo esta modalidad. TNA = es
la tasa nominal anual vigente al momento de cálculo de importe a abonar, según lo comunique Fiserv, la cual podrá variar
de tiempo en tiempo.
Del monto total de la operación, se le descontará en todos los casos al Establecimiento el importe correspondiente al costo
financiero y los otros cargos previstos en el presente.
Consideraciones: (i) En la Financiación Fiserv, se cargará al Establecimiento una tasa de riesgo a favor del Emisor, la que será
informada por Fiserv por los medios acordados en este Reglamento, (ii) El monto máximo disponible para operar bajo esta
modalidad, en caso de aplicar, será informado por Fiserv
Las modificaciones a los términos y condiciones de Financiación Fiserv se regirán de conformidad con la sección 35.4.
Al optar por operar bajo la modalidad de Financiación Fiserv, el Establecimiento entiende que las transacciones efectuadas
bajo la operatoria contado y bajo Planes de Financiación Ahora quedarán sujetas a Anticipación y serán abonadas por Fiserv
dentro de un plazo de 2 días hábiles de presentadas las transacciones por parte del Establecimiento. En estos casos, el costo
financiero aplicable a la Anticipación de las operaciones en contado y bajo Planes de Financiación Ahora será el definido en la
sección 35.2.
b) Otorgante (financiación emisora). El Establecimiento podrá efectuar ventas bajo la modalidad de cuotas con financiación
del Emisor, retribuyendo al emisor el costo financiero de las ventas bajo esta modalidad. La fórmula a estos efectos, así como
la alícuota serán las que Fiserv comunique a los Establecimientos periódicamente. Del monto total de la operación, se le
descontará en todos los casos al Establecimiento el importe correspondiente al cargo financiero y los otros cargos previstos en
el presente. El importe resultante será abonado al Establecimiento en un solo pago, en los plazos y condiciones vigentes a la
fecha de presentación.
c) Cuota a Cuota. Mediante este tipo de financiación el Establecimiento puede efectuar operaciones en cuotas con los medios
de pago del Sistema, hasta el máximo autorizado por Fiserv. Las operaciones serán acreditadas al Establecimiento en las fechas
de caída de cada cuota (la primera cuota dentro del plazo de pago aplicable según el tipo de Establecimiento y las siguientes
cuotas cada 30 días del pago de la cuota anterior).
d) Planes de financiación “Ahora”: En tanto se encuentre vigente el Programa “Ahora” establecido por el Gobierno Nacional,
Fiserv informará la tasa aplicable bajo esta modalidad de financiación, de conformidad con los términos establecidos bajo dicho
Programa. El plazo de pago aplicable a las transacciones efectuadas bajo esta modalidad será de 10 (diez) días hábiles bancarios.
Cualquier modificación en el plazo de pago será comunicada por Fiserv de conformidad con la sección 8.
                </p>
                <h5>Capítulo 5 Servicios Adicionales</h5>

                <p>
                35. Anticipación. Este servicio permite al Establecimiento percibir el importe correspondiente a las liquidaciones y/o
Tickets de operaciones realizadas bajo un mismo C.U.I.T., previa deducción del Arancel, impuestos aplicables previstos en
el presente Reglamento (el “Importe Neto”) y el Costo Financiero (conforme se indica en la cláusula 35.2), en el plazo de
2 (dos) días hábiles bancarios siguientes a la presentación (el “Importe Anticipado”).
35.1. Modalidades disponibles:
a) Eventual: a través del Sitio y del usuario habilitado, el Establecimiento podrá elegir las liquidaciones o Tickets que se
anticiparán de acuerdo con las siguientes categorías: (i) fechas de cobro, (ii) operaciones de Contado, (iii) tarjetas
MasterCard, Visa y/o American Express, (iv) número de Establecimiento, (v) Emisor; y (vi) Pagador. La contratación de esta
modalidad estará disponible en día hábil bancario en los horarios informados en el Sitio. La vigencia de esta modalidad
deberá respetar lo previsto en la cláusula 35.3.
b) Recurrente: esta modalidad será habilitada través de los canales que Fiserv ponga a disposición, permitiendo anticipar
la totalidad de los Ticket y/o liquidaciones de Contado de las tarjetas MasterCard, Visa y/o American Express. La vigencia
de esta modalidad será la prevista en la cláusula 35.3. El Importe Anticipado podrá incluir el monto acumulado de
liquidaciones y/o Tickets a cobrar que correspondan a ventas realizadas con más de dos (2) días hábiles bancarios
de antelación a la adhesión a esta operatoria (el “Stock”).
35.2. Costo Financiero. El costo de requerir Anticipación será el de aplicar la siguiente fórmula:
- Costo Financiero: Importe Neto * (Días de Anticipo) * (TNA / 365)
TNA = es la tasa nominal anual vigente al momento de cálculo del Importe Anticipado según lo comunicado por Fiserv de
tiempo en tiempo.
35.2.1. Producida la acreditación del Importe Anticipado, el Establecimiento quedará desinteresado no teniendo nada que
reclamar a Fiserv, el Pagador y/o los Emisores, con relación a las liquidaciones y/o Tickets involucrados, renunciando a
formular cualquier reclamo en tal sentido, sea por falta de pago de los importes por el Pagador, imposibilidad de disponer
de los mismos por el Establecimiento, por cualquier situación que afecte al Pagador, al propio Establecimiento y/o por
cualquier disposición legal. El Establecimiento renuncia a interponer cualquier acción que pueda coartar, perjudicar o
limitar el derecho de Fiserv y/o del Pagador a cobrar el importe de Anticipación a los Emisores de las Tarjetas con las cuales
se hubieran realizado las transacciones objeto de tal anticipo
35.3. Vigencia de la operatoria. (i) Eventual: una vez aceptada la adhesión del Establecimiento por Fiserv, esta modalidad
se extenderá solo por el día hábil bancario en que fue requerido; (ii) Recurrente: su vigencia comenzará con la adhesión y
se renovará automáticamente pudiendo ser terminada mediante notificación fehaciente con treinta (30) días corridos
de preaviso.
35.4. Modificación de los términos y condiciones. Fiserv podrá modificar en cualquier momento el Costo Financiero y/o
el monto máximo, notificándolo al Establecimiento con una anticipación mínima de diez (10) días corridos a la fecha en
que tendrá efecto. Dentro de dicho plazo, el Establecimiento podrá optar por rescindir su adhesión a la operatoria de
Anticipación dentro de los siete (7) días corridos siguientes a la notificación de Fiserv, sin que ello le otorgue derecho a
formular reclamo alguno a Fiserv. Transcurrido dicho plazo, las modificaciones se tendrán por aceptadas a todo efecto.
</p>
<p>
36. Operatoria mayorista. Esta operatoria permite a los Establecimientos minoristas autorizados por Fiserv la compra de
mercaderías en Establecimientos mayoristas, abonando su precio mediante la cesión del producto de sus ventas, con los
medios de pago del Sistema.
36.1. Mediante la suscripción de la Solicitud del Comercio aceptando esta operatoria: (i) el Establecimiento minorista cede
y transfiere al Establecimiento mayorista indicado en dicha Solicitud del Comercio y éste último acepta, todos sus derechos
y obligaciones presentes y futuros relativos a las operaciones realizadas en el Establecimiento minorista con los medios
de pago del Sistema que fueran presentadas al cobro al Pagador y Fiserv con posterioridad a su aceptación; (ii)
el Establecimiento mayorista solicita que el producido de tales operaciones sea acreditado en la cuenta del
Establecimiento mayorista que tiene abierta en la entidad del Pagador, comprometiéndose a entregar mercaderías
al Establecimiento minorista hasta la concurrencia de los importes percibidos.
36.2. Aranceles, cargos y plazos de pago. El arancel, los cargos y el plazo de pago serán los que aplique al Establecimiento
minorista.
36.3. Vigencia de la Operatoria. Tendrá una vigencia a partir del día de la adhesión del Establecimiento a esta operatoria
y podrá ser terminada mediante notificación fehaciente con quince (15) días corridos de preaviso a Fiserv y al Pagador.
36.4. El Establecimiento mayorista toma conocimiento y autoriza expresamente que los importes correspondientes a
Tickets crédito ingresados por el Establecimiento minorista y a operaciones irregulares o defectuosas sean debitados por
el Pagador de la cuenta del Establecimiento mayorista consignada en la Solicitud del Comercio.
36.5. El Pagador y Fiserv serán ajenos y no intervendrán en modo alguno en los conflictos suscitados entre el
Establecimiento mayorista y minorista y/o los Tarjetahabientes, motivados en los bienes comercializados, en diferencias
entre lo cedido y vendido, en el monto, composición y/u oportunidad de los débitos efectuados a los Tarjetahabientes y/o
en general, en cualquier otra causa ajena al Sistema.
</p>
<p>
37. PREVENCION DE FRAUDES
37.1. Objeto del servicio. En caso de que el Establecimiento contratase este servicio, Fiserv o una empresa de su grupo
realizará el análisis de riesgo y prevención de fraude sobre las transacciones realizadas con las Tarjetas bajo la modalidad
de comercio electrónico y venta telefónica, con el propósito de minimizar la exposición al fraude de tales transacciones.
El Establecimiento reconoce y acepta que la prestación de estos servicios no impide la efectiva comisión de fraudes con
las Tarjetas ni garantiza que se pueda obtener un resultado cierto y determinado sobre el fraude al cual está expuesto el
Establecimiento. Sin perjuicio de los reportes o información de riesgo resultantes de los servicios provistos por Fiserv, el
Establecimiento es el único responsable de decidir si continua o no con la transacción y Fiserv no tendrá ninguna
37.2. Cuestiones previas. Para la prestación del servicio, el Establecimiento deberá cumplir con lo dispuesto en la cláusula
28.1 de este Reglamento.
37.3. Requerimiento de información. El Establecimiento reconoce y acepta que, para la prestación del servicio, deberá
proporcionar todos los datos, documentos, información o materiales sobre las transacciones, lo cual deberá realizarse en
las condiciones que Fiserv solicite de tiempo en tiempo, como condición indispensable para que el servicio pueda
brindarse en las condiciones pactadas, sin que pueda imputarse responsabilidad alguna a Fiserv en caso de incumplimiento
del Establecimiento en la provisión de tales datos o si no se hubiesen proporcionado en las condiciones y tiempos
establecidos por Fiserv. En base a dichos datos, Fiserv brindará estos Servicios y pondrá el resultado de los mismos a
disposición del Establecimiento en el Sitio.
37.4. Cuestiones previas. Para la prestación del servicio, el Establecimiento deberá cumplir con lo dispuesto en la cláusula
28.1 de este Reglamento.
37.5. Requerimiento de información. El Establecimiento reconoce y acepta que, para la prestación del servicio, deberá
proporcionar todos los datos, documentos, información o materiales sobre las transacciones, lo cual deberá realizarse en
las condiciones que Fiserv solicite de tiempo en tiempo, como condición indispensable para que el servicio pueda
brindarse en las condiciones pactadas, sin que pueda imputarse responsabilidad alguna a Fiserv en caso de incumplimiento
del Establecimiento en la provisión de tales datos o si no se hubiesen proporcionado en las condiciones y tiempos
establecidos por Fiserv. En base a dichos datos, Fiserv brindará estos Servicios y pondrá el resultado de los mismos a
disposición del Establecimiento en el Sitio.
</p>


<p>
38. ACEPTACION DE PIX
38.1 Objeto del servicio. Habilitar al Establecimiento que así lo solicite a ofrecer, para el pago de los bienes y/o servicios
comercializados por dicho Establecimiento, la aceptación del medio de pago electrónico denominado “PIX” a residentes
de Brasil.
38.2 PIX. Es una solución de pagos digital habilitada por entidades financieras e instituciones de pago de Brasil, que permite
transferir dinero y hacer pagos con tan solo un código. El pago mediante PIX bajo este Reglamento, se refiere a la operación
de pago que realiza una persona residente de Brasil, usuario de una Billetera /Aplicación Móvil habilitada a operar con PIX
para la adquisición de bienes, o para el pago de la prestación de servicios en Argentina (el “Usuario”) mediante la lectura
de un Código QR expuesto por el Medio de Captura Clover, a través de una web y/o QR impreso, y que se efectiviza a
través de la solicitud de débito a la cuentas bancarias o virtuales (CBU o CVU) asociadas a la Billetera Mobile/Aplicativo de
Pagos PIX. Esta modalidad no permite el pago de la operación en cuotas.
38.3 El Establecimiento reconoce que el servicio de Aceptación PIX es prestado directamente al Establecimiento por parte
de Orangedata S.A. Orangedata es una entidad del grupo Fiserv, inscripta ante el BCRA para operar como proveedor de
servicios de pago y proveedor de cuentas virtuales.
38.4 Es del conocimiento del Establecimiento que los débitos en las cuentas de los Usuarios Pix serán realizados por
RENDIMENTOPAY INSTITUIÇÃO DE PAGAMENTO S.A. (“Rendix”) y/o por la entidad que Fiserv designe en el futuro. Rendix
es una institución de pagos, prestadora de servicios de pago y transferencias internacionales autorizada para operar por
el Banco Central de Brasil.
38.5 Operatoria. Para la Aceptación Pix, el Establecimiento deberá descargar en su terminal Clover la aplicación “Pagos
PIX” o acceder a la misma vía aplicativo web que le permite la generación del código QR (la “Aplicación”) y/o contar con
un QR impreso PIX.
38.5.1 Para concertar la operación, el Establecimiento debe ingresar en la Aplicación y completar los siguientes datos
básicos del Usuario interesado en realizar la operación (los “Datos del Usuario”): número de teléfono móvil, CPF y correo
electrónico (este último opcional), que permitirán validar la identidad del Usuario y evaluar los límites transaccionales
aprobados para cada transacción comercial. Asimismo, por cuestiones de prevención de lavado de dinero, es obligación
del Establecimiento, solicitarle al Usuario un documento con fotografía que acredite la identidad y el número de CPF que
ingresa en la Aplicación.
El Establecimiento reconoce y acepta que es responsable de solicitar los Datos del Usuario, así como de validar la identidad
de este. En caso de incumplimiento, el Establecimiento deberá soportar los contracargos motivados en desconocimientos
efectuados por el titular de la cuenta impactada.
38.5.2 Adicionalmente a los Datos del Usuario, el Establecimiento deberá incluir y cargar a través de la Aplicación, los datos
de la transacción de pago a realizar, indicando el monto en pesos argentinos, que será automáticamente convertido por
el sistema a dólares estadounidenses tomando como referencia la cotización para consumos con billeteras extranjeras
(“Datos de la Transacción”). En la Aplicación se encontrará disponible el tipo de cambio en dólares al momento de la
transacción para que el Establecimiento pueda transmitir la información al Usuario, sin perjuicio de que el cobro al Usuario
se le realizará en reales. Cargos todos los datos, se validará la información del Usuario, así como su límite transaccional.
La operación podrá ser denegada en caso de que supere el límite transaccional y/o existiera sospecha de fraude y/o
irregularidad en la transacción.
38.5.3 En caso de que la operación ingresada pueda realizarse, el Usuario recibirá a través de un SMS (mensaje de texto)
un enlace con los términos y condiciones de la transacción, lo que incluirá información sobre la comisión que Rendix
cobrará al Usuario por ejecutar el débito en la cuenta del Usuario y facilitar la transferencia internacional.
38.5.4 El Establecimiento a través de la Aplicación generará un código QR para concertar la operación, el cual deberá ser
leído por el Usuario por medio de su Billetera /Aplicación Móvil PIX. Dicho código QR exhibirá el valor de la operación en
reales. Al ejecutar el PIX, se concreta la operación y se entienden automáticamente aceptados por el Usuario los términos
y condiciones de Rendix.
38.5.5 Una vez identificado el pago y que el monto haya sido efectivamente debitado por Rendix, el estado de la
transacción en la Aplicación cambiará a "Aprobada" y se visualizará en la pantalla de la Clover.
38.5.6 El comprobante de la transacción será impreso una vez aprobada y finalizada la operación. Se deja establecido que
una vez que la operación sea ejecutada, la misma no podrá ser anulada ni cancelada por el Establecimiento.
38.6 La Aplicación estará disponible para su uso las 24 hs. del día, los 7 días de la semana y se encuentra sujeta a constante
actualizaciones y suma de nuevas funcionalidades.
38.7 Rechazo de operaciones. Una operación podrá ser rechazada en casa de tratarse de operaciones sospechosas y/o
irregulares, por exceder el límite transaccional del Usuario y/o por insuficiencia de fondos.
Asimismo, es posible que una operación resulte rechazada al momento de ejecución como consecuencia de la
intermitencia en la transferencia de datos o porque el CPF del Usuario se encuentra deshabilitado, en cuyos casos los
rechazos serán automáticos, al instante y el Establecimiento recibirá le aviso de “Transacción Rechazada”.
38.8 Descuento de operaciones. Orangedata podrá descontar mediante débitos o compensaciones de la/s cuenta/s que
mantenga abierta el Establecimiento y aún con percepción directa o judicial, todas las sumas que hubieren abonado a los
Establecimientos con relación a operaciones defectuosas y/o irregulares, así como cualquier suma de dinero que adeudare
el Establecimiento en virtud del Reglamento, en supuestos en los que el Usuario hubiese solicitado una devolución. A los
fines y efectos de la cláusula 38.7 se entiende como operación defectuosa y/o irregular a aquella que hubiera sido cursada
por el Establecimiento en incumplimiento a las disposiciones del Reglamento, a la normativa vigente y/o a sus obligaciones
contempladas en la presente cláusula 38, lo que incluye, pero no se limita a: (i) no haber cargado correctamente los Datos
del Usuario; (ii) no haber validado la identidad del Usuario; (iii) no haber entregado la mercadería y/o prestado el servicio
al Usuario.
38.9 Liquidación y Pago: El monto de las operaciones será acreditado, deducidas las comisiones, gastos e impuestos
correspondientes, en la cuenta virtual única (CVU) que el Establecimiento tenga abierta en Orangedata para tal fin dentro
de los diez (10) días hábiles a partir del día siguiente de efectuada la transacción.
38.9.1 Las acreditaciones recibidas en la CVU del Establecimiento podrán ser sujeto de retenciones y percepciones
impositivas determinadas por la normativa aplicable.
38.9.2 El Establecimiento podrá transferir los saldos de su CVU provista por Orangedata a otra cuenta de su titularidad
debiendo el Establecimiento ingresar al módulo de transferencias de la Aplicación y seleccionar una CBU/CVU/ALIAS para
realizar la transferencia. Estas transferencias no se realizarán de forma automática y el CBU/CVU/ALIAS, encontrándose
prohibida la transferencia a cuentas de titularidad de terceros.
38.9.3 El Establecimiento podrá descargar un archivo con la liquidación de las operaciones, para que, por cada transacción
se pueda realizar la conciliación e imputación correspondiente.
38.9.4 Comisión. El Establecimiento reconoce a Orangedata una comisión del 0.8% sobre cada transacción que realice
mediante a Aceptación Pix. Dicha comisión más los cargos, intereses y las retenciones o descuentos establecidos en este
Reglamento o impuestos por ley o contractualmente será deducida de las liquidaciones del Establecimiento. Mensualmente,
Orangedata enviará mediante correo electrónico al Establecimiento, la factura junto con el detalle de las comisiones
cobradas + IVA y percepciones de corresponder.
38.10 Transferencia Internacional de datos. La aceptación de PIX y la adhesión a este Reglamento por parte del
Establecimiento implica su consentimiento para la transferencia internacional de los datos personales del Establecimiento
así como de la información que viaje con las transacciones que se realicen mediante el uso de la Aplicación hacia Brasil a
RENDIMENTOPAY INSTITUIÇÃO DE PAGAMENTO S.A (con domicilio legal en Av. Rebouças Nº3970 piso 16 de la ciudad de
San Pablo, Brasil, sociedad limitada debidamente constituida de acuerdo con las leyes de la República Federativa del Brasil,
registrada en el CNPJ/ME con el Nº 13.776.742/0001-55), en el marco del procesamiento de las operaciones. En dicho
sentido, el Establecimiento reconoce y acepta que Orangedata podrá compartir con Rendix el legajo del Establecimiento,
el formulario “know your customer”, su perfil transaccional, así como la solicitud de afiliación al Reglamento a los efectos
del procesamiento de la operación PIX y para cumplir con requerimientos regulatorios en Brasil.
38.11 Resultarán aplicables a la Aceptación Pix las restantes cláusulas del presente Reglamento en tanto resulten
compatibles con la operatoria aquí descripta.
</p>
<h5>Capítulo 6 Servicios Generales</h5>

<p>
2. Misceláneas
2.1. Definiciones. Los términos con mayúscula inicial que no se definan de otra forma en el Reglamento, tendrán el
significado que se les atribuye en Anexo A.
2.2. Intercambio de información. El Establecimiento debe velar por el cumplimiento de la regulación de datos
personales, Ley 25.326, Decreto Reglamentario 1558/2001 y sus normas reglamentarias. En dicho sentido, debe adoptar
las medidas necesarias para proteger la confidencialidad de sus clientes y de la información de las transacciones.
Asimismo, el Establecimiento debe establecer y mantener controles físicos, técnicos y administrativos para prevenir el
acceso por terceros no autorizados a la información del tarjetahabiente y de las transacciones. El Establecimiento se
abstendrá de vender, comprar, proveer y/o intercambiar información obtenida como consecuencia de operaciones
realizadas con los medios de pago del Sistema, a cualquier persona distinta de Fiserv. Adicionalmente, el Establecimiento
se compromete a no compilar ni almacenar listas o bases de datos de información personal o de transacciones, ni a ceder,
divulgar o utilizar la misma con un fin diferente a la operatoria establecida bajo el Sistema. Se entiende por información
personal, toda aquella que hace a la identificación de una persona, tal como es definida por la regulación vigente en
materia de Protección de Datos Personales en Argentina. El Establecimiento debe notificar de forma inmediata en caso
de que el Establecimiento tome conocimiento y/o sospeche que la Información del tarjetahabiente, o la información de
la transacción ha sido consultada o utilizada sin la autorización del Establecimiento. Asimismo, ante el requerimiento
formal de autoridad competente con facultades suficientes, el Establecimiento deberá dar aviso previo a Fiserv para que
ésta pueda interponer las medidas que estime pertinentes para evitar su divulgación.
El Establecimiento presta su conformidad expresa para que First Data Cono Sur S.R.L. pueda compartir, y/o transferir a
cualquier otra empresa del Grupo Fiserv y/o sus proveedores de servicios y/o terceros sean nacionales o del exterior
(incluyendo las Marcas, instituciones financieras u otras administradoras de tarjetas), los datos recabados que sean
necesarios y estén relacionados con los servicios incluidos en el presente Reglamento así como a los fines de integrar la
base de datos de clientes del Grupo Fiserv (los “Datos Personales del Establecimiento”).
2.3 El Establecimiento presta su consentimiento expreso para que FirstData Cono Sur S.R.L. comparta con American Express
los Datos Personales del Establecimiento a fin de que American Express pueda cumplir sus obligaciones bajo el Programa
OptBlue, enviar comunicaciones comerciales de marketing, comunicaciones transaccionales o de relaciones de American
Express, realizar análisis, crear informes, así como para cualquier otro propósito comercial lícito.
En cualquier momento, el Establecimiento que ha aceptado el Programa OptBlue de America Express podrá optar por
excluirse voluntariamente de recibir futuras comunicaciones de marketing comercial de American Express. No obstante,
es posible que el Establecimiento siga recibiendo comunicaciones de marketing mientras American Express actualiza sus
registros para reflejar su elección. Excluirse voluntariamente de los mensajes de marketing no impedirá que el
Establecimiento reciba comunicaciones importantes sobre transacciones o la relación de parte de American Express.
American Express puede utilizar la información obtenida en la solicitud del Establecimiento adherido al Programa OptBlue
en el momento de su configuración para seleccionar y/o supervisar al Establecimiento en relación con los procesos de
marketing y administración de la tarjeta Amex.
2.4 El Establecimiento reconoce y acepta que su información personal puede ser transferida y alojada en sistemas,
servidores o dispositivos informáticos ubicados en países con un sistema legislativo cuyo nivel de protección de datos
personales difiera del argentino. El Establecimiento declara conocer que la suscripción de la presente solicitud importa el
otorgamiento de su consentimiento expreso en los términos de la Ley 25.326, Decreto Reglamentario 1558/2001 y sus
normas reglamentarias para que dichos datos sean transferidos y/o almacenados en empresas que integran el Grupo
Fiserv y/o sus proveedores de servicios y/u otros terceros residentes en dichos países.
 2.5 Datos personales. Derechos de acceso, rectificación y/ o supresión. Los datos recabados por First Data Cono Sur
S.R.L. serán almacenados en una base de datos ubicada en Rondeau N° 3439, Ciudad Autónoma de Buenos Aires. El titular
de los datos podrá ejercer los derechos de acceso, rectificación y/ o supresión sobre sus datos conforme lo dispuesto por
la ley 25.326. El titular de los datos tiene la facultad de ejercer el derecho de acceso a sus datos personales en forma
gratuita a intervalos no inferiores a seis meses, salvo que se acredite un interés legítimo al efecto conforme lo establecido
en el artículo 14, inciso 3 de la ley nº 25.326. La Agencia de Acceso a la Información Pública, Órgano de Control de la Ley
Nº 25.326, tiene la atribución de atender las denuncias y reclamos que se interpongan con relación al incumplimiento de
las normas sobre protección de datos personales.
2.6 Cesión. El Establecimiento no podrá ceder total o parcialmente el presente convenio, incluyendo su carácter de
Establecimiento, el número correspondiente, como así tampoco el Medio de Captura asignado para su operatoria, sin
conformidad previa, expresa y por escrito de Fiserv. El Establecimiento autoriza a Fiserv a cambiarlo de Pagador cuando
la entidad elegida por el Establecimiento hubiera incurrido en mora con las restantes entidades del Sistema o con Fiserv,
dejará de ser afiliada al Sistema o bien, por cualquier otro motivo no realizara el pago de las operaciones presentadas al
cobro o Fiserv lo estimara conveniente. En ningún caso corresponderá el reclamo a las restantes entidades del Sistema ni
a Fiserv de suma alguna por concepto de intereses, daños y perjuicios y/o lucro cesante, devengados por los retrasos que
hubiere producido la entidad morosa.
2.7 Domicilio constituido. A todos los efectos legales derivados del presente, el Establecimiento constituye domicilio en
el indicado en la Solicitud del Comercio, donde se tendrán por válidas, vinculantes y produciendo todos los efectos
jurídicos correspondientes, todas las comunicaciones, citaciones, intimaciones, reclamos, interpelaciones y/o
notificaciones, judiciales o extrajudiciales, que deban ser cursadas. El domicilio constituido no podrá ser modificado sin
notificación fehaciente previa al Pagador y a Fiserv.

</p>
<p>
3. Política Anti‐soborno. Prevención de Lavado de Activos y Financiamiento al Terrorismo. Los Establecimientos, como
condición indispensable para solicitar y mantener su afiliación al Sistema, deben garantizar que en todas sus actividadesse
abstendrán de efectuar promesa de realizar cualquier pago o transferencia de cualquier cosa de valor, directa o
indirectamente, a: (i) cualquier funcionario gubernamental (incluidos los empleados de gobierno de propiedad estatal o
controlados por las corporaciones y organizaciones internacionales públicas); (ii) cualquier partido político, funcionario de
un partido político o candidato; (iii) cualquier intermediario para el pago a cualquiera de los nombrados en los ítems (i) y
(ii) anteriores, o (iv) cualquier otra persona o entidad, si tal pago o transferencia tuviera el propósito o el efecto de llevar
a cabo actos de corrupción públicos o comerciales, la aceptación de extorsión, sobornos o cualquier otro medio ilegal o
indebido para la obtención de negocios. El Establecimiento manifiesta y garantiza que no tiene conocimiento alguno que
sus afiliadas, subsidiarias, así como cada uno de los accionistas, directores, empleados y demás personas que trabajan en
su nombre (incluyendo, pero no limitado a sus empleados, representantes y agentes) han participado en ningún acto de
soborno como los arriba mencionados.
En caso de que algún empleado, funcionario, director o propietario directo o indirecto del Establecimiento sea considerado
Persona Políticamente Expuesta (PEP) de acuerdo con la normativa establecida por el Banco Central de la República
Argentina sobre Prevención del Lavado de Dinero y Otras Actividades Ilícitas, deberá dar conocimiento de dicha situación
al Pagador y a Fiserv. Los Establecimientos deberán en todo momento cumplir con la legislación aplicable, especialmente
en materia de prevención del soborno y prevención de prácticas corruptas.
</p>
<p>
4. Ley “No Llame”. El Establecimiento autoriza expresamente a Fiserv para que, de tiempo en tiempo, pueda contactarlo
por cualquier medio – incluido el telefónico‐ para ofrecerle otros productos, servicios y nuevas oportunidades de negocios,
con independencia si el Establecimiento figura en el registro “No Llame”
</p>
<p>
5. Firmantes. Para el caso en que la Solicitud del Comercio fuera suscripta por un representante del Establecimiento y/o
el Establecimiento Mayorista, éste declara tener atribuciones suficientes para suscribirlo y que el mandato se encuentra
vigente. Si resultara que dicho mandato no lo facultara para suscribir dicha solicitud, no estuviere vigente y/o adoleciere
de cualquier defecto de fondo o forma que atente contra su validez, el representante se obliga a responder ante Fiserv o
Posnet S.R.L. por cualquier reclamo extrajudicial y/o judicial que ésta efectúe.
</p>

                
            </>
    )
}
export default RegulationCommerce;