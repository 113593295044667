import React from 'react';
import { Navigate, Route, Routes } from "react-router-dom";
import '../App.css';
import { WebCache } from "../services/WebCache";
import AutomaticDebit from "./automaticdebit/AutomaticDebit";
import GenerateQR from './charges/GenerateQR';
import TransfersReturnReport from "./charges/TransfersReturnReport";
import DataCommerce from './commerce/DataCommerce';
import LoyaltyVirtualCoupons from "./coupons/LoyaltyVirtualCoupons";
import Dashboard from "./Dashboard";
import GlobalLoader from "./GlobalLoader";
import HelpComponent from './help/HelpComponent';
import HelpIncidentComponent from './help/HelpIncidentComponent';
import Footer from './layout/Footer';
import Header from "./layout/Header";
import HelpSidebar from './layout/help/HelpSidebar';
import { LayoutProvider } from './layout/LayoutContext';
import MenuITD from "./layout/menu/Menu";
import ClientReport from "./loyalty/clients/ClientReport";
import LoyaltyPromotions from "./loyalty/clients/LoyaltyPromotions";
import Loyalty from "./loyalty/Loyalty";
import LoyaltyAccountReport from "./loyalty/LoyaltyAccountReport";
import MercadoPagoPage from './mercadopago/MercadoPagoPage';
import MercadoPagoPageBranchABM from './mercadopago/MercadoPagoPageBranchABM';
import MercadoPagoPageConfirm from './mercadopago/MercadoPagoPageConfirm';
import MercadoPagoPageTerminalsABM from './mercadopago/MercadoPagoPageTerminalsABM';
import ConciliationDashboard from "./payments/ConciliationDashboard";
import OperationTableReport from "./payments/OperationTableReport";
import PaymentCalendar from "./payments/PaymentCalendar";
import PaymentReport from "./payments/PaymentReport";
import PaymentTaxReport from "./payments/PaymentTaxReport";
import PaymentTransactionReport from "./payments/PaymentTransactionReport";
import PresentationDownloadsReport from './payments/PresentationDownloadsReport';
import PresentationReport from "./payments/PresentationReport";
import SendProfileManagement from "./SendProfileManagement";
import SettlementChargeBacks from './settlements/SettlementChargeBacks';
import SettlementConf from './settlements/SettlementConf';
import SettlementsByDay from './settlements/SettlementsByDay';
import SettlementsByMonth from './settlements/SettlementsByMonth';
import BranchManagement from "./terminals/BranchManagement";
import TaxDocumentation from './terminals/FiscalDocumentInvoices';
import QrPctInvoicesDocumentation from "./terminals/QrPctInvoiceInvoices";
import TerminalMap from "./terminals/TerminalMap";
import TerminalsReport from './terminals/TerminalsReport';
import CurrentTransactionReport from "./transactions/CurrentTransactionReport";
import TransactionReport from "./transactions/TransactionReport";
import UsersManagement from './UsersManagement';
import AdvancedRefund from './advanced/AdvancedRefund';
import HistoricAdvanced from './advanced/HistoricAdvanced';

interface IProps {
}

const Home: React.FC<IProps> = (props) => {
    const isValid = false;
    return (
        <React.Fragment>
            <GlobalLoader></GlobalLoader>
            <div className="page">
                <div className="page-main">
                    <LayoutProvider>
                        <Header></Header>
                        {/*<Survey></Survey>*/}
                        <div style={{paddingLeft:"0px",backgroundColor:"var(--bs-gray-100)"}}>
                            <div style={{ display: 'flex', height: '100%'}}>
                                <MenuITD></MenuITD>
                                <HelpSidebar></HelpSidebar>
                                <Routes>
                                    <Route path={'/'} element={<Navigate to={'/dashboard'} />} />
                                    <Route path={'/help'} element={<HelpComponent />} />
                                    <Route path={'/help/incident'} element={<HelpIncidentComponent />} />
                                    <Route path={'/dashboard'}
                                        element={
                                            (!WebCache.isUserConciliator()) && WebCache.isDashboardAllowed() ? (
                                                <Dashboard />
                                            ) : (
                                                <Navigate
                                                    to="/sales/transactions"
                                                />
                                            )
                                        }
                                    />
                                    {/* Sales */}
                                    <Route path={'/sales/transactions'} element={WebCache.isTransactionsAllowed() ? (
                                        <TransactionReport />
                                    ) : (
                                        <Navigate
                                            to="/help#session"
                                        />
                                    )} />
                                    <Route path={'/sales/pendingOrders'} element={WebCache.isTransactionsAllowed() ? (
                                        <CurrentTransactionReport />
                                    ) : (
                                        <Navigate
                                            to="/help#session"
                                        />
                                    )} />
                                    {/* Charge */} 
                                    <Route path={'/charge/transfersReturn'} element={WebCache.isDevelopmentQrAllowed() ? (
                                        <TransfersReturnReport />
                                    ) : (
                                        <Navigate
                                            to="/help#session"
                                        />
                                    )} /> 
                                    <Route path={'/charge/generateQR'} element={WebCache.isManagementAllowed() ? (
                                        <GenerateQR />
                                    ) : (
                                        <Navigate
                                            to="/charge/transfersReturn"
                                        />
                                    )} />
                                    <Route path={'/charge/automaticDebit'} element={WebCache.isDaAllowed() ? (
                                        <AutomaticDebit />
                                    ) : (
                                        <Navigate
                                            to="/dashboard"
                                        />
                                    )} />
                                    {/* Advanced */}
                                    <Route path={'/advanced/search'} element={WebCache.isAdvancedAllowed() ? (
                                        <AdvancedRefund />
                                    ) : (
                                        <Navigate
                                            to="/dashboard"
                                        />
                                    )} />
                                    <Route path={'/advanced/historic'} element={WebCache.isAdvancedAllowed() ? (
                                        <HistoricAdvanced />
                                    ) : (
                                        <Navigate
                                            to="/dashboard"
                                        />
                                    )} />
                                    {/* Terminals */}
                                    <Route path={'/terminals/branches'} element={WebCache.isManagementAllowed() ? (
                                        <BranchManagement />
                                    ) : (
                                        <Navigate
                                            to="/dashboard"
                                        />
                                    )} />
                                    <Route path={'/terminals/map'} element={WebCache.isManagementAllowed() ? (
                                        <TerminalMap />
                                    ) : (
                                        <Navigate
                                            to="/dashboard"
                                        />
                                    )} />
                                    <Route path={'/terminals/reports'} element={<TerminalsReport allowUpdateTerminal={false} />} />
                                    <Route path={'/terminals/management'} element={WebCache.isManagementAllowed() ? (
                                        <TerminalsReport allowUpdateTerminal={true} />
                                    ) : (
                                        <Navigate
                                            to="/dashboard"
                                        />
                                    )} />
                                    <Route path={'/terminals/fiscalDocumentInvoices'} element={WebCache.isManagementAllowed() ? (
                                        <TaxDocumentation/>
                                    ) : (
                                        <Navigate
                                            to="/dashboard"
                                        />
                                    )} />
                                    <Route path={'/terminals/qrPctInvoices'} element={WebCache.isManagementAllowed() ? (
                                        <QrPctInvoicesDocumentation/>
                                    ) : (
                                        <Navigate
                                            to="/dashboard"
                                            />                                    
                                    )} />   
                                    {/* Conciliation */}
                                    <Route path={'/conciliation/dashboard'} element={ (!WebCache.isUserConciliator()) && WebCache.isFinancialAllowed() ? (
                                        <ConciliationDashboard />
                                    ): (
                                        <Navigate
                                            to="/dashboard"
                                            />                                    
                                    )} />                                 
                                    <Route path={'/conciliation/calendar'}
                                        element={
                                            WebCache.isFinancialAllowed() ? (
                                                <PaymentCalendar />
                                            ) : (
                                                <Navigate
                                                    to="/sales/transactions"
                                                />
                                            )
                                        }
                                    />
                                    <Route path={'/conciliation/settlementVouchers'}
                                        element={
                                            WebCache.isFinancialAllowed() ? (
                                                <PaymentTransactionReport settlementNumber={undefined} show={true} showOnlyVal={false} />
                                            ) : (
                                                <Navigate
                                                    to="/sales/transactions"
                                                />
                                            )
                                        }
                                    />
                                    <Route path={'/conciliation/settlementVouchersVal'}
                                        element={
                                            WebCache.isFinancialAllowed() ? (
                                                <PaymentTransactionReport settlementNumber={undefined} show={true} showOnlyVal={true} />
                                            ) : (
                                                <Navigate
                                                    to="/sales/transactions"
                                                />
                                            )
                                        }
                                    />
                                    <Route path={'/conciliation/settlementTaxes'}
                                        element={
                                            WebCache.isFinancialAllowed() ? (
                                                <PaymentTaxReport />
                                            ) : (
                                                <Navigate
                                                    to="/sales/transactions"
                                                />
                                            )
                                        }
                                    />
                                    <Route path={'/conciliation/settlementConf'}
                                        element={
                                            WebCache.isManagementAllowed() ? (
                                                <SettlementConf />                                                                                            
                                            ) : (
                                                <Navigate
                                                    to="/sales/transactions"
                                                />
                                            )
                                        } />
                                    {/* Settlements */}
                                    <Route path={'/settlements/daily'}
                                        element={
                                            WebCache.isFinancialAllowed() ? (
                                                <SettlementsByDay />
                                            ) : (
                                                <Navigate
                                                    to="/dashboard"
                                                />
                                            )
                                        }
                                    />
                                    <Route path={'/settlements/monthly'}
                                        element={
                                            WebCache.isFinancialAllowed() ? (
                                                <SettlementsByMonth />
                                            ) : (
                                                <Navigate
                                                    to="/dashboard"
                                                />
                                            )
                                        }
                                    />
                                    <Route path={'/settlements/charge-backs'}
                                        element={
                                            WebCache.isFinancialAllowed() ? (
                                                <SettlementChargeBacks />
                                            ) : (
                                                <Navigate
                                                    to="/dashboard"
                                                />
                                            )
                                        }
                                    />
                                    {/* Reports */}
                                    <Route path={'/reports/settlements'}
                                        element={
                                            WebCache.isFinancialAllowed() ? (
                                                <PaymentReport />
                                            ) : (
                                                <Navigate
                                                    to="/sales/transactions"
                                                />
                                            )
                                        }
                                    />
                                    <Route path={'/reports/presentations'}
                                        element={
                                            WebCache.isFinancialAllowed() ? (
                                                <PresentationReport />
                                            ) : (
                                                <Navigate
                                                    to="/sales/transactions"
                                                />
                                            )
                                        }
                                    />
                                    <Route path={'/reports/operationTable'}
                                        element={
                                            WebCache.isFinancialAllowed() ? (
                                                <OperationTableReport />
                                            ) : (
                                                <Navigate
                                                    to="/sales/transactions"
                                                />
                                            )
                                        }
                                    />
                                    <Route path={'/reports/presentationDownloads'}
                                        element={
                                            WebCache.isFinancialAllowed() ? (
                                                <PresentationDownloadsReport />
                                            ) : (
                                                <Navigate
                                                    to="/sales/transactions"
                                                />
                                            )
                                        }
                                    />
                                    <Route path={'/reports/sendProfileManagement'} element={WebCache.isManagementAllowed() ? (
                                        <SendProfileManagement />
                                    ) : (
                                        <Navigate
                                            to="/dashboard"
                                        />
                                    )} />
                                    {/* Commerce */}
                                    <Route path={'/commerce/data'} element={WebCache.isManagementAllowed() ? (
                                        <DataCommerce />
                                    ) : (
                                        <Navigate
                                            to="/dashboard"
                                        />
                                    )} /> 
                                    {/* Settings */}
                                    <Route path={'/settings/users'} element={WebCache.isManagementAllowed() ? (
                                        <UsersManagement />
                                    ) : (
                                        <Navigate
                                            to="/dashboard"
                                        />
                                    )} />
                                    {/* Integrations */}
                                    <Route path={'/integrations/MPAssociate'} element={!WebCache.getMPConfiguration().initialized ? (
                                        <MercadoPagoPage />
                                    ) : (
                                        <Navigate
                                            to="/dashboard"
                                        />
                                    )} />
                                    <Route path={'/integrations/MercadoPagoStoreABM'} element={WebCache.isManagementAllowed() && WebCache.getMPConfiguration().initialized ? (
                                        <MercadoPagoPageBranchABM />
                                    ) : (
                                        <Navigate
                                            to="/dashboard"
                                        />
                                    )} />
                                    {/* Loyalty */}
                                    <Route path={'/loyalty'} element={<Loyalty />} />
                                    <Route path={'/loyalty/clients'} element={<ClientReport />} />
                                    <Route path={'/loyalty/promos'} element={<LoyaltyPromotions />} />
                                    <Route path={'/loyalty/coupons'} element={<LoyaltyVirtualCoupons />} />
                                    <Route path={'/loyalty/summary'} element={<LoyaltyAccountReport />} />
                                    {/* Other routes */}
                                    <Route path={'/MPConfirm'} element={WebCache.isManagementAllowed() ? (
                                        <MercadoPagoPageConfirm />
                                    ) : (
                                        <Navigate
                                            to="/dashboard"
                                        />
                                    )} />
                                    <Route path={'/MercadoPagoTerminalsABM'} element={WebCache.isManagementAllowed() && WebCache.getMPConfiguration().initialized ? (
                                        <MercadoPagoPageTerminalsABM />
                                    ) : (
                                        <Navigate
                                            to="/dashboard"
                                        />
                                    )} />
                                </Routes>
                            </div>
                        </div>
                    </LayoutProvider>
                </div>
                <Footer isValid={isValid} />
            </div>
        </React.Fragment>
    );
}

export default Home;