import React, { useEffect, useState } from 'react';
import { WebCache } from "../services/WebCache";
import {TFunction, useTranslation} from "react-i18next";
import { AxiosResponse } from 'axios';
import Swal from "sweetalert2";
import {Option} from "../models/Option";
import SelectField from "./controls/fields/selectField";
import FormFilter from "./containers/form-filter";
import {UserService} from "../services/UserService";
import {PageItem} from "react-bootstrap";
import Form from "../components/containers/form";
import SubmitButton from '../components/controls/buttons/submitButton';



interface IProps {
    onCompanyChange: TFunction<string>
}
interface IState {
    availableCompanies : Array<Option>
}
interface IFilter{
    currentSystemId:string,
}
let MultiCompanySelector: React.FC<IProps> = (prop) => {

    let [state, setState] = useState<IState>({
        availableCompanies :WebCache.getMultiCompanyFiscalDocumentOptions(),
    });

    const [filters, setFilters] = useState<IFilter>({currentSystemId:""});
    const { t } = useTranslation();
    return (
        <React.Fragment>
            {state.availableCompanies.length>0 &&(
                    <div className="row">
                        <div className='w-100 mr-4'>
                        <Form model={[filters, setFilters.bind(this)]} onSubmit={() => {
                            UserService.moveToCompany(filters.currentSystemId).then((response) => {
                                WebCache.initCache().then(() => {
                                    // To enable the fingerprint functionality, uncomment this if code
                                    // if (UtilService.mobileCheck()) {
                                    //     setPageMode(PageMode.FINGERPRINT_REGISTRATION);
                                    // }
                                    // else {
                                    //     navigateToDashboard();
                                    // }
                                    // To enable the fingerprint functionality, remove this navigateToDashboard() call.
                                    window.location.href = '/#/dashboard'
                                    setState({availableCompanies:WebCache.getMultiCompanyFiscalDocumentOptions()})
                                    prop.onCompanyChange(filters.currentSystemId);
                                })
                            }).catch(() => {
                                Swal.fire({
                                    title: "Error",
                                    html: "No es posible cambiar de cliente. Vuelva a intentar",
                                    confirmButtonColor: '#f60',
                                    timer: 6000,
                                    timerProgressBar: true,
                                    icon: "warning"
                                });
                            });
                        }}>
                                <>                               
                                <div className="modal-body modalTransfersReturn">
                                    <div className='d-flex order-lg-4 w-auto'>
                                        <SelectField
                                            attr='currentSystemId'
                                            label={`availableCompanies`}
                                            options={state.availableCompanies}
                                        ></SelectField>
                                    </div>
                                </div>
                                <div className="modalFooter">
                                    <SubmitButton label={'changeClient'} noBlock />
                                </div>
                                </>
                        </Form></div>
                        </div>
                )}
        </React.Fragment>
    )
}
export default MultiCompanySelector;

