import React, { RefObject, useCallback, useEffect } from 'react';
import "./Menu.scss";
import { useTranslation } from "react-i18next";
import { Menu, MenuItem, MenuItemStyles, Sidebar, SubMenu, menuClasses } from 'react-pro-sidebar';
import { Link } from "react-router-dom";
import { UserService } from "../../../services/UserService";
import { WebCache } from "../../../services/WebCache";
import { SidebarHeader } from "./SidebarHeader";
import { ReactComponent as IconComputerAnalyticsSVG } from "../../../../node_modules/itd-common/assets/icons/computer-analytics.svg";
import { ReactComponent as IconCreditCardPaymentSVG } from "../../../../node_modules/itd-common/assets/icons/credit-card-payment.svg";
import { ReactComponent as IconSettlementSVG } from "../../../../node_modules/itd-common/assets/icons/file_grey.svg";
import { ReactComponent as IconHomeSVG } from "../../../../node_modules/itd-common/assets/icons/home.svg";
import { ReactComponent as IconLoyaltySVG } from "../../../../node_modules/itd-common/assets/icons/lealtad.svg";
import { ReactComponent as IconLogoutSVG } from "../../../../node_modules/itd-common/assets/icons/logout.svg";
import { ReactComponent as IconSettingsSVG } from "../../../../node_modules/itd-common/assets/icons/settings.svg";
import {ReactComponent as UsersSVG} from "../../../../node_modules/itd-common/assets/icons/icono_usuario.svg"
import { ReactComponent as IconSplitSVG } from "../../../../node_modules/itd-common/assets/icons/split.svg";
import { ReactComponent as IconTagCashSVG } from "../../../../node_modules/itd-common/assets/icons/tag-cash.svg";
import { ReactComponent as IconChargeSVG } from "../../../../node_modules/itd-common/assets/icons/ventas.svg";
import { ReactComponent as IconHelpSVG } from "../../../../node_modules/itd-common/assets/icons/Icono_ayuda.svg";
import { ReactComponent as IconCommerceSVG } from "../../../../node_modules/itd-common/assets/icons/house-5.svg";
import { ReactComponent as IconServicesSVG } from "../../../../node_modules/itd-common/assets/icons/switchboard.svg";
import { ReactComponent as IconSalesSVG } from "../../../../node_modules/itd-common/assets/icons/movimientos.svg";
import { ReactComponent as IconDocumentsSVG } from "../../../../node_modules/itd-common/assets/icons/accounting-invoice.svg";
import { ReactComponent as IconAdvancedSVG } from "../../../../node_modules/itd-common/assets/icons/lealtad.svg";
import { ReactComponent as IconReportsSVG } from "../../../../node_modules/itd-common/assets/icons/folder-file.svg";
import { useLayout } from '../LayoutContext';
import { SelectContainer } from 'react-select/dist/declarations/src/components/containers';
import { link } from 'fs';
import colors from 'react-multi-date-picker/plugins/colors';
import { axiosApiInstance } from '../../../services/HttpService';
import { request } from 'http';
import axios, { AxiosResponse } from 'axios';
import { TransactionService } from '../../../services/TransactionService';

type Theme = 'light' | 'dark';

const themes = {

        sidebar: {
            backgroundColor: '#ffffff',
            color:'#ff6600',
        },
        menu: {
            menuContent: '#fbfcfd',
            icon: '#818080',
            hover: {
                backgroundColor: '#e5e5e5',
                color: '#fdfdfd',
            },
            disabled: {
                color: '#9fb6cf',
            },
            
        }
};

// hex to rgba converter
const hexToRgba = (hex: string, alpha: number) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

interface IProps {

}
interface IState {
    toggled: boolean,
    menuToggled:boolean
}
let MenuITD: React.FC<IProps> = () => {
    const { isMenuOpen, isMenuCollaped, setToggleMenu, setCollapsedMenu, setBrokenMenu, toggleHelp } = useLayout();
    const [rtl, setRtl] = React.useState(false);
    const [hasImage, setHasImage] = React.useState(false);
    const menuRef: RefObject<HTMLHtmlElement> = React.createRef();
    const [activePageItems, setActivePageItems] = React.useState<Array<HTMLLIElement>>([]);
    const { t } = useTranslation();
    //const isNotSettlementEnabled = !WebCache.isSettlementOnboardingEnabled();
    const isNotManagementAllowed = !WebCache.isManagementAllowed();

    // handle on RTL change event
    const handleRTLChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setRtl(e.target.checked);
    };

    const getLinkAutomaticDebit = () => {
        TransactionService.getLinkAutomaticDebit().then((response: AxiosResponse<string>) => {
            if(response.data)
                window.location.href= response.data;
        }).catch(error => console.log('error:', error));
    }

    // handle on image change event
    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setHasImage(e.target.checked);
    };

    function handleClickMenu(event: React.MouseEvent<HTMLAnchorElement>) {
        activateMenu(event.target as HTMLAnchorElement);        
    }

    const activateMenu = useCallback((anchor: HTMLAnchorElement) => {

        if (!anchor) {
            return;
        }

        let targetList: Array<HTMLLIElement> = new Array<HTMLLIElement>();
        const clickedTarget: HTMLElement = anchor;
        let targetToActivate: Element | undefined = clickedTarget.closest(".ps-menuitem-root")?.children[0];
        let indexSearch: number = 0;

        while (targetToActivate && indexSearch <= 5) {
            indexSearch++;
    
            if (targetToActivate) {
                targetList.push(targetToActivate as HTMLLIElement);
                const targetToActivateParent: HTMLElement | null | undefined = targetToActivate.parentElement?.parentElement;
                if (targetToActivateParent) {
                    targetToActivate = targetToActivateParent.closest(".ps-menuitem-root")?.children[0];
                }
            }
        }

        let itemToActivateFound: boolean = targetList.some((targetItem) => targetItem.getAttribute("href"));

        if (activePageItems && itemToActivateFound) {
            activePageItems.forEach((targetItem) => targetItem.classList.remove('active'));
        }

        targetList.forEach((targetItem) => {
            if(targetItem.getAttribute("href") || itemToActivateFound) {
                targetItem.classList.toggle('active');
            }
        });

        if ((itemToActivateFound)) {
            setActivePageItems(targetList);
        }
    }, [activePageItems]);

    useEffect(() => {
        if (menuRef.current) {
            let activeAnchors: HTMLCollectionOf<Element> = menuRef.current.getElementsByClassName("active") as HTMLCollectionOf<Element>;
            if (activeAnchors.length === 0) {
                let anchorToActivate: HTMLAnchorElement = Array.from(menuRef.current.getElementsByTagName("a")).find((item) => item.href.toLowerCase() === window.location.href.toLowerCase()) as HTMLAnchorElement;
                activateMenu(anchorToActivate);
            }
        }
    }, [menuRef, activateMenu]);

    useEffect(() => {
        const closeMenuOnClick = (event: MouseEvent) => {
            if (isMenuOpen() && !menuRef.current?.contains(event.target as Node)) {
                setToggleMenu(false);
                setCollapsedMenu(true);
            }
        };

        document.addEventListener('mousedown', closeMenuOnClick);

        return () => {
            document.removeEventListener('mousedown', closeMenuOnClick);
        };
    }, [isMenuOpen, setToggleMenu, setCollapsedMenu]);

    const menuItemStyles: MenuItemStyles = {
        root: {
            fontSize: '1rem',
            fontWeight: 400,
        },     

        icon: {
            color: themes.menu.icon,
            [`&.${menuClasses.disabled}`]: {
                color: themes.menu.disabled.color,
            },
        },
        SubMenuExpandIcon: {
            color: '#b6b7b9',
            'span': {
                color: '#616161',
                borderRight: '.5px solid currentcolor',
                borderBottom: '.5px solid currentcolor' 
            }
        },
        subMenuContent: ({ level }) => ({
            backgroundColor:
                level === 0
                    ? hexToRgba(themes.menu.menuContent, hasImage && !isMenuCollaped() ? 0.4 : 1)
                    : 'transparent',
        }),
        button: {
            [`&.${menuClasses.disabled}`]: {
                color: themes.menu.disabled.color,
            },
            '&:hover': {
                backgroundColor: hexToRgba(themes.menu.hover.backgroundColor, hasImage ? 0.8 : 1)
            }, 
            '.slide-item': {
                fontSize:'0.875rem',
            },
        },
        label: ({ open , active}) => ({
            fontWeight: open ? 600 : undefined,    
            color: open ? '#FF6600': undefined,
            fontSize: open ? undefined : '0.875rem'     
        }),
    };

    return (
        
        <Sidebar
            collapsed={isMenuCollaped()}
            toggled={isMenuOpen()}
            onBackdropClick={() => { 
                setToggleMenu(false); 
                setCollapsedMenu(true);
            }}
            //onBreakPoint={setBrokenMenu}
            rtl={rtl}
            breakPoint="md"
            backgroundColor={hexToRgba(themes.sidebar.backgroundColor, hasImage ? 0.9 : 1)}
            ref={menuRef}
        >
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <SidebarHeader rtl={rtl} style={{ marginBottom: '24px', marginTop: '16px' }} />
                <Menu menuItemStyles={menuItemStyles} closeOnClick={true}>
                    <MenuItem hidden={WebCache.isUserConciliator()} icon={<IconHomeSVG></IconHomeSVG>} component={<Link to="/dashboard" onClick={handleClickMenu} />}>{t("Dashboard")}</MenuItem>
                    <SubMenu hidden={!WebCache.isTransactionsAllowed()} label={t("movements")} icon={<IconSalesSVG></IconSalesSVG>}>
                        <MenuItem  component={<Link id="btn" className="slide-item" to={'/sales/transactions'} onClick={handleClickMenu}></Link>}> {t("transactionReport")}</MenuItem>
                        {/* <MenuItem hidden={!WebCache.getMPConfiguration().initialized} component={<Link className="slide-item" to={'/sales/fiservQRPurhcase'} onClick={handleClickMenu}></Link>}>QR Fiserv</MenuItem> */}
                        {/* <MenuItem hidden={!WebCache.getMPConfiguration().initialized} component={<Link className="slide-item" to={'/sales/paymentLink'} onClick={handleClickMenu}></Link>}> {t("paymentLink")}</MenuItem> */}
                    </SubMenu>
                    <SubMenu hidden={!(WebCache.isDaAllowed() || WebCache.isTransferReturnAllowed() ||  WebCache.getMPConfiguration().initialized|| WebCache.isDevelopmentQrAllowed())} label={t("charge")}icon={<IconChargeSVG></IconChargeSVG>}>
                        <MenuItem hidden={!WebCache.getMPConfiguration().initialized} component={<Link className="slide-item" to={'/sales/mercadoñPagoPurchase'} onClick={handleClickMenu} ></Link>}>QR MercadoPago</MenuItem> 
                        <MenuItem hidden={!(WebCache.isTransferReturnAllowed()|| WebCache.isDevelopmentQrAllowed())} component={<Link className="slide-item" to={'/charge/generateQR'} onClick={handleClickMenu} ></Link>}>{t("qr")}</MenuItem>
                        <MenuItem hidden={!WebCache.isDaAllowed()} component={<Link className="slide-item" to={'/charge/automaticDebit'}></Link>} onClick={handleClickMenu}> {t("automaticDebit")}</MenuItem>
                    </SubMenu>
                    <SubMenu hidden={!WebCache.isPromotionCouponsEnabled()} label={t("promotions")} icon={<IconTagCashSVG></IconTagCashSVG>}>
                        <MenuItem hidden={!WebCache.isPromotionCouponsEnabled()} component={<Link className="slide-item" to={'/loyalty/coupons'}></Link>} onClick={handleClickMenu}> {t("promotionCoupons")}</MenuItem>
                    </SubMenu>
                    <SubMenu hidden={!WebCache.isLoyaltyEnabled()} label={t("loyalty")} icon={<IconLoyaltySVG></IconLoyaltySVG>}>
                        <MenuItem hidden={!WebCache.isLoyaltyEnabled()} component={<Link className="slide-item" to={'/loyalty'}></Link>} onClick={handleClickMenu}> {t("configuration")}</MenuItem>
                        <MenuItem hidden={!WebCache.isLoyaltyEnabled()} component={<Link className="slide-item" to={'/loyalty/summary'}></Link>} onClick={handleClickMenu}> {t("summary")}</MenuItem>
                        <MenuItem hidden={!WebCache.isLoyaltyEnabled()} component={<Link className="slide-item" to={'/loyalty/promos'}></Link>} onClick={handleClickMenu}> {t("promotions")}</MenuItem>
                        <MenuItem hidden={!WebCache.isLoyaltyEnabled()} component={<Link className="slide-item" to={'/loyalty/clients'}></Link>} onClick={handleClickMenu}> {t("clients")}</MenuItem>
                    </SubMenu>
                    <SubMenu hidden={!WebCache.isManagementAllowed()} label={t("conciliation")} icon={<IconComputerAnalyticsSVG></IconComputerAnalyticsSVG>}>
                        <MenuItem hidden={WebCache.isUserConciliator()} component={<Link className="slide-item" to={'/conciliation/dashboard'}></Link>} onClick={handleClickMenu} > {t("Dashboard")}</MenuItem>
                        <MenuItem component={<Link className="slide-item" to={'/conciliation/calendar'}></Link>} onClick={handleClickMenu}> {t("paymentCalendar")}</MenuItem>
                        <SubMenu hidden={!WebCache.isManagementAllowed()} label={t("salesAndPayments")}>
                            <MenuItem component={<Link className="slide-item" to={'/conciliation/settlementVouchers'}></Link>} onClick={handleClickMenu}> {t("saleDetail")}</MenuItem>
                            <MenuItem component={<Link className="slide-item" to={'/conciliation/settlementVouchersVal'}></Link>} onClick={handleClickMenu}> {t("advanceDetail")}</MenuItem>
                            <MenuItem component={<Link className="slide-item" to={'/conciliation/settlementTaxes'}></Link>} onClick={handleClickMenu}> {t("taxes")}</MenuItem>
                        </SubMenu>
                        {/*<MenuItem hidden={isNotManagementAllowed} component={<Link className="slide-item" to={'/conciliation/settlementConf'}></Link>} onClick={handleClickMenu} > {t("pages.settlementsConf.title")}</MenuItem>*/}
                    </SubMenu>
                    <MenuItem icon={<IconSettlementSVG></IconSettlementSVG>} hidden={!WebCache.isFinancialAllowed()} component={<Link  to={'/settlements/daily'}></Link>} onClick={handleClickMenu}>{t("settlements")}</MenuItem>
                    <SubMenu hidden={!WebCache.isManagementAllowed()} label={t("documents")} icon={<IconDocumentsSVG></IconDocumentsSVG>}>
                        {WebCache.getCurrentUser()?.countryId == 2 ?
                        <MenuItem component={<Link className="slide-item" to={'/terminals/fiscalDocumentInvoices'}></Link>} onClick={handleClickMenu}> {t("fiscalDocumentInvoices")}</MenuItem>:
                        <><MenuItem component={<Link className="slide-item" to={'/terminals/fiscalDocumentInvoices'}></Link>} onClick={handleClickMenu}> {t("pages.posnetPlatformInvoices.title")}</MenuItem>
                        <MenuItem component={<Link className="slide-item" to={'/terminals/qrPctInvoices'}></Link>} onClick={handleClickMenu}> {t("pages.qrPctInvoices.title")}</MenuItem></>
                    }</SubMenu>
                    <SubMenu hidden={!WebCache.isManagementAllowed() && !WebCache.isFinancialAllowed()} label={t("reports")} icon={<IconReportsSVG></IconReportsSVG>}>
                        <MenuItem component={<Link className="slide-item" to={'/reports/settlements'}></Link>} onClick={handleClickMenu}> {t("fileReports")}</MenuItem>
                        <MenuItem hidden={!WebCache.isManagementAllowed()} component={<Link className="slide-item" to={'/reports/sendProfileManagement'}></Link>} onClick={handleClickMenu}> {t("configuration")}</MenuItem>
                    </SubMenu>
                    <SubMenu label={t("commerce")} hidden={!WebCache.isManagementAllowed()} icon={<IconCommerceSVG></IconCommerceSVG>}>
                        <MenuItem component={<Link className="slide-item" to={'/commerce/data'}></Link>} onClick={handleClickMenu}> {t("comercialdata")}</MenuItem>
                        <SubMenu hidden={WebCache.isUserConciliator()} label={t("users")}>
                            <MenuItem component={<Link className="slide-item" to={'/settings/users'}></Link>} onClick={handleClickMenu}> {t("configuration")}</MenuItem>
                        </SubMenu>
                        <SubMenu hidden={WebCache.isUserConciliator()} label={t("terminals")}>
                            <MenuItem component={<Link className="slide-item" to={'/terminals/branches'}></Link>} onClick={handleClickMenu}> {t("branches")}</MenuItem>
                            <MenuItem component={<Link className="slide-item" to={'/terminals/map'}></Link>} onClick={handleClickMenu}> {t("location")}</MenuItem>
                            <MenuItem component={<Link className="slide-item" to={'/terminals/reports'}></Link>} onClick={handleClickMenu}> {t("reports")}</MenuItem>
                           {/* <MenuItem component={<Link className="slide-item" to={'/terminals/management'}></Link>} onClick={handleClickMenu}> {t("configuration")}</MenuItem> */}
                        </SubMenu>
                    </SubMenu>
                    <MenuItem icon={<IconAdvancedSVG></IconAdvancedSVG>} hidden={!WebCache.isAdvancedAllowed()} component={<Link  to={'/advanced/search'}></Link>} onClick={handleClickMenu}>{t("advanced")}</MenuItem>
{/*                     <SubMenu label={t("integrations")} icon={<IconSplitSVG></IconSplitSVG>}>
 */}                        {/*<SubMenu hidden={!WebCache.getMPConfiguration().enabled} label={t("Mercado Pago")}>
                            <MenuItem hidden={WebCache.getMPConfiguration().initialized||!WebCache.getMPConfiguration().enabled} component={<Link className="slide-item" to={'/integrations/MPAssociate'}></Link>} onClick={handleClickMenu}> {t("administration")}</MenuItem>
                            <MenuItem hidden={!WebCache.getMPConfiguration().initialized} component={<Link className="slide-item" to={'/integrations/MercadoPagoStoreABM'}></Link>} onClick={handleClickMenu}> {t("administration")}</MenuItem>
                        </SubMenu>*/}
{/*                     </SubMenu>
 */}                    {/* <SubMenu label={t("services")} hidden={!WebCache.isManagementAllowed()} icon={<IconServicesSVG></IconServicesSVG>}>
                    </SubMenu>  */}
                    <div className="dividing-line"></div>
                    <MenuItem icon={<IconHelpSVG></IconHelpSVG>} component={<Link to={'/help'}></Link>} onClick={handleClickMenu}>{t("help")}</MenuItem>
                    <MenuItem icon={<IconLogoutSVG></IconLogoutSVG>} onClick={UserService.logout}>{t("logOut")}</MenuItem>
                </Menu>
            </div>
        </Sidebar>
    );
};
export default MenuITD
