import React from 'react';
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { IPeriodIndicator } from "../../models/IBarChartData";
import "./chart.css"
import {DashboardService} from "../../services/DashboardService";
import Tooltip from '../Tooltip';
import { ReactComponent as ArrowGreen } from "../../../node_modules/itd-common/assets/icons/arrow-green.svg";
import { ReactComponent as ArrowRed } from "../../../node_modules/itd-common/assets/icons/arrow-red.svg";
import { ReactComponent as IconInfoSVG } from "../../../node_modules/itd-common/assets/icons/alert-information.svg";


interface IProps {
    chartData: IPeriodIndicator
    currencySymbol: string,
    t: any
    title:string,
    infoTooltip: string,
    dateRange: string,
}
interface IState {
    chartData: IPeriodIndicator,
    currencySymbol: string,
    changePercentage: number
    dateRange:string
}

const defaultData: IState = {
    currencySymbol: "UYU",
    changePercentage: 0,
    dateRange: "",
    chartData: DashboardService.createIPeriodIndicator([0,0])

}

class CouponPromIndicator extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = defaultData
    }

    chartTemplate: { options: ApexOptions, series: ApexOptions['series'] } = {
        series: [],
        options: {
            chart: {
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false,
                }

            },
            fill: {
                type: 'gradient',
                gradient: {
                    shadeIntensity: 1,
                    inverseColors: false,
                    opacityFrom: 0.5,
                    opacityTo: 0,
                    stops: [0, 90, 100]
                },
            },
            dataLabels: {
                enabled: false
            },
            grid: {
                show: false
            },
            xaxis: {
                labels: {
                    show: false
                },
                axisTicks: {
                    show: false
                },
                axisBorder: {
                    show: false
                }
            },
            tooltip: {
                enabled: false
            },
            yaxis: {
                labels: {
                    show: false
                },
                show: false,
                axisTicks: {
                    show: false
                },
                axisBorder: {
                    show: false
                }
            },
            legend: {
                show: false
            }
        }
    }


    componentWillReceiveProps(nextProps: IProps) {
        // You don't have to do this check first, but it can help prevent an unneeded render
        if (nextProps.chartData !== this.state.chartData) {
            if (nextProps.chartData.periodTotals.length < 1) {
                this.setState(defaultData);
                return;
            }

            var series = [];
            var firstIndex = 10000;
            var lastIndex = -1;

            nextProps.chartData.rows.forEach(row => {
                row.values.forEach((value, index) => {
                    if (value != 0) {
                        if (firstIndex > index)
                            firstIndex = index;

                        if (lastIndex < index)
                            lastIndex = index;
                    }
                });
            });

            if (firstIndex < nextProps.chartData.rows[0].values.length)
                lastIndex -= firstIndex;

            nextProps.chartData.rows.forEach(row => {
                if (firstIndex < row.values.length)
                    row.values.splice(0, firstIndex)
                if (lastIndex > 0)
                    row.values.splice(lastIndex, row.values.length - lastIndex)
            });



            for (var i = 0; i < nextProps.chartData.rows.length; i++) {
                series.push({
                    name: nextProps.chartData.rows[i].label,
                    data: nextProps.chartData.rows[i].values,
                    color: nextProps.chartData.rows[i].color
                });
            }

            this.chartTemplate.series = series;
            const totalsDividend = (nextProps.chartData.promTotals[1] | 0) * 100;
            const totalsDivisor = nextProps.chartData.promTotals[0] | 0;
            const totalsDivision = totalsDividend / totalsDivisor
            const changePercentage = (isFinite(totalsDivision) && !isNaN(totalsDivision)) ? totalsDivision : 0;

            this.setState({
                chartData: nextProps.chartData,
                currencySymbol: nextProps.currencySymbol,
                changePercentage: (changePercentage === 100) ? 0 : 100 - changePercentage,
                dateRange: nextProps.dateRange
            });


        }
    }

    render() {
        const { t } = this.props;
        return (
            <div className="col-xl-3">
                <div className="card card-amount">
                    <div className="card-body">
                        <div className="d-flex ">
                            <div className="text-start w-100">
                                <div className="d-flex inline-block">
                                    <h4 className="w-80 card-title">{this.props.title}</h4>
                                    <div className="d-flex justify-content-end w-30">
                                        <a type="button">
                                            <Tooltip message={this.props.infoTooltip}>
                                                <IconInfoSVG className={'info-icon'} ></IconInfoSVG>
                                            </Tooltip>
                                        </a>
                                    </div>
                                </div>
                                <h1 className="mt-2 h-30">
                                    {Intl.NumberFormat('es-UY', { style: 'currency', currency: this.state.currencySymbol, minimumFractionDigits: 0 }).format(Number(this.state.chartData.promTotals[0].toFixed(0)))}
                                </h1>

                                <div hidden={this.state.dateRange=="1year"}>
                                    {this.state.changePercentage > 0 ? <ArrowGreen></ArrowGreen> : <ArrowRed></ArrowRed>}
                                    <span className={"mb-0 number-font ps-1 " + (this.state.changePercentage < 0 ? "text-red" : "text-green")}>{Intl.NumberFormat('es-UY', { minimumFractionDigits: 0 }).format(Number(Math.abs(this.state.changePercentage).toFixed(1)))+"% X "+t(this.state.chartData.titleLabel)}</span>
                                </div>
                            </div>

                            <div className="ms-auto">
                                <div className="overlay-chart-panel">
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    };
}
export default CouponPromIndicator;
