import { AxiosRequestConfig, AxiosResponse } from "axios";
import { IChargeBack, IDailyFavoriteSettlement, IDailySettlements, IFavoriteDailySettlementResponse, IFavoriteMonthlySettlementResponse, IGetDailyFavoriteResponse, IGetMonthlyFavoriteResponse, IMonthlyFavoriteSettlement, IMonthlySettlements, ISettlementHTMLSelectProduct } from "../models/ISettlementData";
import {
    IChargeBacksRequest,
    IDailySettlementsRequest,
    IDownloadDailyESettlement,
    IDownloadMonthlyESettlement,
    IDownloadProofOfWithholdings,
    IMonthlySettlementsRequest,
    NewFavoriteDailyRequest
} from "../requests/ISettlementsRequest";
import { axiosApiInstance } from "./HttpService";

export class FiscalSettlementService {

    public static async getDailySettlements(request: IDailySettlementsRequest): Promise<IDailySettlements> {        
        let dataURL: string = `${process.env.REACT_APP_API_SETTLEMENT_URL}/settlement/Settlement/SettlementListDaily`;
        return await axiosApiInstance.post<IDailySettlements>(dataURL, request)
                                    .then((response: AxiosResponse<IDailySettlements>) => { 
            return response.data });
    }

    public static async getDailySettlementFavorites(): Promise<IDailyFavoriteSettlement[]> {        
        let dataURL: string = `${process.env.REACT_APP_API_SETTLEMENT_URL}/settlement/Settlement/GetDailyFavorites`;
        return await axiosApiInstance.get(dataURL).then((response: AxiosResponse<IDailyFavoriteSettlement[]>) => { 
            return response.data });;
    }
    public static async getMonthlySettlementFavorites(): Promise<IMonthlyFavoriteSettlement[]> {        
        let dataURL: string = `${process.env.REACT_APP_API_SETTLEMENT_URL}/settlement/Settlement/GetMonthlyFavorites`;
        return await axiosApiInstance.get(dataURL).then((response: AxiosResponse<IMonthlyFavoriteSettlement[]>) => { 
            return response.data });;
    }
    public static async addDailyFavoriteSettlement(request:NewFavoriteDailyRequest): Promise<IFavoriteDailySettlementResponse> {        
        let dataURL: string = `${process.env.REACT_APP_API_SETTLEMENT_URL}/settlement/Settlement/AddDailyFavorite`;
        return await axiosApiInstance.post<IFavoriteDailySettlementResponse>(dataURL, request)
                                .then((response: AxiosResponse<IFavoriteDailySettlementResponse>) => { 
            return response.data });
    }
    public static async addMonthlyFavoriteSettlement(request:NewFavoriteDailyRequest): Promise<IFavoriteMonthlySettlementResponse> {        
        let dataURL: string = `${process.env.REACT_APP_API_SETTLEMENT_URL}/settlement/Settlement/AddMonthlyFavorite`;
        return await axiosApiInstance.post<IFavoriteMonthlySettlementResponse>(dataURL, request)
                                .then((response: AxiosResponse<IFavoriteMonthlySettlementResponse>) => { 
            return response.data });
    }
    public static async deleteDailyFavoriteSettlement(name:string): Promise<IFavoriteDailySettlementResponse> {        
        let dataURL: string = `${process.env.REACT_APP_API_SETTLEMENT_URL}/settlement/Settlement/DeleteDailyFavorite`;
        return await axiosApiInstance.post<IFavoriteDailySettlementResponse>(dataURL, {name: name})
                                .then((response: AxiosResponse<IFavoriteDailySettlementResponse>) => { 
            return response.data });
    }
    public static async deleteMonthlyFavoriteSettlement(name:string): Promise<IFavoriteMonthlySettlementResponse> {        
        let dataURL: string = `${process.env.REACT_APP_API_SETTLEMENT_URL}/settlement/Settlement/DeleteMonthlyFavorite`;
        return await axiosApiInstance.post<IFavoriteMonthlySettlementResponse>(dataURL, {name: name})
                                .then((response: AxiosResponse<IFavoriteMonthlySettlementResponse>) => { 
            return response.data });
    }
    public static async downloadProofOfWithholdings(request: IDownloadProofOfWithholdings){
        let dataURL:string = `${process.env.REACT_APP_API_TRXSENDER_URL}/apiTrxSender/FileExporter/downloadProofOfWithholdings`;
        let options: AxiosRequestConfig = {
            responseType: 'blob' // don't forget this
        };

        return await axiosApiInstance.post(dataURL, request, options);
    }

    public static async getDownloadSettlementZip(request: IDailySettlementsRequest){
        let dataURL:string = `${process.env.REACT_APP_API_TRXSENDER_URL}/apiTrxSender/FileExporter/downloadDailyESettlementBulk`;
        let options: AxiosRequestConfig = {
            responseType: 'arraybuffer' // don't forget this
        };
        return await axiosApiInstance.post(dataURL, request, options);
    }

    public static async getDownloadMonthlySettlementZip(request: IMonthlySettlementsRequest){
        let dataURL:string = `${process.env.REACT_APP_API_TRXSENDER_URL}/apiTrxSender/FileExporter/downloadMonthlyESettlementBulk`;
        let options: AxiosRequestConfig = {
            responseType: 'arraybuffer' // don't forget this
        };
        return await axiosApiInstance.post(dataURL, request, options);
    }
    
    public static async getDowloadDailyESettlement(request: IDownloadDailyESettlement){        
        //let dataURL:string = `${process.env.REACT_APP_API_SETTLEMENT_URL}/settlement/Settlement/DownloadDailyESettlement`;
        let dataURL:string = `${process.env.REACT_APP_API_TRXSENDER_URL}/apiTrxSender/FileExporter/downloadDailyESettlement`;
        let options: AxiosRequestConfig = {
            responseType: 'blob' // don't forget this
          };

        return await axiosApiInstance.post(dataURL, request, options);
    }  

    public static async getMonthlySettlements(request: IMonthlySettlementsRequest): Promise<IMonthlySettlements> {          
        let dataURL: string = `${process.env.REACT_APP_API_SETTLEMENT_URL}/settlement/Settlement/MonthlyESettlementFileList`;
        return await axiosApiInstance.post<IMonthlySettlements>(dataURL, request).then((response: AxiosResponse<IMonthlySettlements>) => { 
            return response.data });
    }    

    public static async getDowloadMonthlyESettlement(request: IDownloadMonthlyESettlement){        
        //let dataURL:string = `${process.env.REACT_APP_API_SETTLEMENT_URL}/settlement/Settlement/DownloadMonthlyESettlement`;
        let dataURL:string = `${process.env.REACT_APP_API_SETTLEMENT_URL}/apiTrxSender/FileExporter/downloadMonthlyESettlement`;
        let options: AxiosRequestConfig = {                         
            responseType: 'blob' // don't forget this
          };

        return await axiosApiInstance.post(dataURL, request, options);
    }    

    public static async getAllProducts(): Promise<HTMLOptionElement[]> {          
        let dataURL: string = `${process.env.REACT_APP_API_SETTLEMENT_URL}/settlement/Settlement/GetAllProducts`;
        return await axiosApiInstance.post<HTMLOptionElement>(dataURL).then((response: AxiosResponse<HTMLOptionElement[] | any>) => { 
            return response.data });
    } 

    public static async getChargeBacks(request: IChargeBacksRequest): Promise<AxiosResponse<IChargeBack[]>> {
        let dataURL: string = `${process.env.REACT_APP_API_SETTLEMENT_URL}/settlement/Settlement/getChargeBacks`;
        return await axiosApiInstance.post<IChargeBack[]>(dataURL, request);
    }
}
