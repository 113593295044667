import React, {ChangeEvent, RefObject} from 'react';
import FullCalendar, { EventContentArg } from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import './PaymentCalendar.css'
import {TFunction, withTranslation} from "react-i18next";
import {AxiosResponse} from "axios";
import esLocale from '@fullcalendar/core/locales/es';
import {SettlementService} from "../../services/SettlementService";
import {
    ICalendarTotals,
    ISettlementCalendarResponse, ISettlementCalendarRow
} from "../../models/ISettlementData";
import {DatesSetArg} from "@fullcalendar/core";
import {ITransaction} from "../../requests/ITransactionWebModelRequest";
import {IShortcutCaptureResult} from "../../models/IShortcut";
import {String} from "sugar";
import AccordionHeader from "react-bootstrap/AccordionHeader";
import Accordion from "react-bootstrap/Accordion";
import {Modal} from "react-bootstrap";
import AccordionBody from "react-bootstrap/AccordionBody";
import CalendarTotal from "./CalendarTotal";
import { CurrencyOption } from '../../models/Currency';
import { Option } from '../../models/Option';
import { UtilService } from '../../services/UtilService';
import { WebCache } from '../../services/WebCache';
import { Link } from 'react-router-dom';
import { OptionMapperHelper } from '../../helpers/OptionMapperHelper';
import SelectField from '../controls/fields/selectField';
import Button from '../controls/buttons/button';
import SubmitButton from '../controls/buttons/submitButton';
import Form from '../containers/form';
import Card from '../containers/card';
import FormFilter from '../containers/form-filter';
import Tooltip from '../Tooltip';
import { CurrencyHelper } from '../../helpers/CurrencyHelper';
import Page from '../containers/page'

interface IProps { 
    t: TFunction
}

interface ICalendarEvent {
    title: string,
    start: string,
    end: string,
    paymentChargeAndReturnsFlag: boolean,
    additionalData: {
        statusColor: string,
        row: ISettlementCalendarRow 
    }
}
interface IFilters {
    bankFilter:string,
    fiscalDocument: string,
    merchantDocument: string
    bankBranchFilter:string,
    productFilter:string,
    selectedCurrency:string
}

interface IState {
    filters:IFilters,
    initialized:boolean,
    weekendsVisible: boolean,
    rowDetail: {title:string,data:{ prop:string,value:string }[],total:string} |null,
    currentEvents:ICalendarEvent[],
    weekDayFormat: "short" | "long" | "narrow" | undefined,
    totalAmount:string[],
    paymentQuantity:number,
    conciliationAlarms:{days:string,quantity:number}[],
    conciliationTransactions:ITransaction[]|null
    columns:string[],
    settlementCurrency:string|null
    selectedSettlementDate:Date|null,
    filterAccordionRef:RefObject<any>
    settlementAccordionRef:RefObject<any>
    pressentedAccordionRef:RefObject<any>
    balanceAccordionRef:RefObject<any>
    calendarRef:RefObject<any>
    eventSelected:ICalendarTotals|null
    totals:ICalendarTotals,
    accordionRef:RefObject<any>,
    currencyOptions: CurrencyOption[],
    bankOptions: Option[],
    fiscalDocuments:Option[],
    merchantNumbers:Option[],
    bankBranchOptions: Option[],
    productOptions: Option[],
}

const T_PREFIX: string = 'pages.calendar.';

let lastFiscalDocument:string=""
class PaymentCalendar extends React.Component<IProps, IState> {
    private optionMapper: OptionMapperHelper = new OptionMapperHelper(this.props.t);
    private observer : ResizeObserver;

    constructor(props: IProps) {
        super(props);
        const currencyOptions = UtilService.getCurrencyOptions().filter(row => row.value !== "");
        const defaultCurrency = currencyOptions[0].value;
        const allBanks = WebCache.getAllPayerBanks(true);
        const allProducts = WebCache.getAllProducts(true);
        const allBankBranches = WebCache.getAllPayerBankBranches(true);
        // const algo: Array<Option> = OptionMapperHelper.buildSelectOptionsFromAnArrayWithDifferentInterface(WebCache.getAllPayerTaxIds(), 'id', 'name', this.props.t);
        this.observer = new ResizeObserver(() => {
            let value : 'short' | 'long' = window.innerWidth > 650 ? "long" : "short";
            if(value != this.state.weekDayFormat){
                this.setState({
                    ...this.state,
                    weekDayFormat: value
                })
            }
        });
        this.observer.observe(document.body);

        this.state = {
            initialized:false,
            weekendsVisible: true,
            currentEvents:[],
            weekDayFormat: "short",
            rowDetail:null,
            totalAmount:[],
            conciliationAlarms:[],
            conciliationTransactions:null,
            columns:[],
            paymentQuantity:0,
            selectedSettlementDate:null,
            settlementCurrency:null,
            filterAccordionRef:React.createRef(),
            settlementAccordionRef:React.createRef(),
            pressentedAccordionRef:React.createRef(),
            balanceAccordionRef:React.createRef(),
            calendarRef:React.createRef(),
            totals:{
                currency:defaultCurrency,
                presentedAmount:0,
                settlementAmount:0,
                balanceAmount:0,

                presentedOthersAmount:0,
                presentedGrossAmount:0,
                presentedChargeAmount:0,
                presentedContapartAmount:0,
                settlementGrossAmount:0,
                settlementChargeAmount:0,
                settlementContapartAmount:0,
                settlementOthersAmount:0,
                settlementTaxAmount:0,
            },
            filters:{
                selectedCurrency: defaultCurrency, 
                productFilter:allProducts[0].value,
                bankBranchFilter:allBankBranches[0].value,
                fiscalDocument:WebCache.getAllMerchantDocuments(true,true)[0].label+"",
                merchantDocument:"" ,
                bankFilter:allBanks[0].value
            },
            eventSelected:null,
            currencyOptions: currencyOptions,
            accordionRef:React.createRef(),
            bankOptions: allBanks,
            bankBranchOptions: allBankBranches,
            productOptions: allProducts,
            merchantNumbers:OptionMapperHelper.getAllMerchantNumbersOptions( WebCache.getAllMerchantDocuments(false,true)[0].label,true,true),
            fiscalDocuments:WebCache.getAllMerchantDocuments(true,true)

        }
    }

    componentWillUnmount(): void {
        this.observer.unobserve(document.body);
    }
    renderSidebar() {
        const { t } = this.props;
        return (
            <div >
                <div >
                    <h3>{t("payments")}: {this.state.paymentQuantity} Total :{this.state.totalAmount}</h3>
                </div>
            </div>
        )
    }

    handleWeekendsToggle = () => {
        this.setState({...this.state,
            weekendsVisible: !this.state.weekendsVisible
        })
    }

    private lastQueryDateTime: number = 0;

    buildTitleText =(currency:string,amount:number)=>{
        var text= Intl.NumberFormat('es-UY', { style: 'currency', currency: CurrencyHelper.getCurrencySymbol(currency) }).format(Number(amount)) + " "
        if(text.length>15)
            text=text.substring(0,12)+"...";
        return text;
    }

    buildCalendarTotals = (rows: ISettlementCalendarRow[], events: ICalendarEvent[], currency: string): ICalendarTotals => {
        let balanceAmount = 0;
        let settlementAmount = 0;
        let presentedGrossAmount = 0;
        let presentedContapartAmount = 0;
        let presentedChargeAmount = 0;
        let presentedOthersAmount = 0;
        let presentedAmount = 0;
        let settlementGrossAmount = 0;
        let settlementContapartAmount = 0;
        let settlementChargeAmount = 0;
        let settlementOthersAmount = 0;
        let settlementTaxAmount = 0;
        let currentDate = new Date();
        let pastCurrentDate = false;

        for (let row of rows) {
            pastCurrentDate = new Date(row.paymentDate) >= currentDate;
            const GREEN = "#54B34A";
            const YELLOW = "#F3B823";
            const RED = "#DD3435";

            let statusColor = pastCurrentDate ? YELLOW : GREEN;
            if (row.settlementTotals.length > 0) {
                var conciliated = true;
                if (row.presentationTotals.length > 0) {
                    for (let presentationTotal of row.presentationTotals) {
                        if (presentationTotal.conciliatedTotalCount !== presentationTotal.totalCount) {
                            conciliated = false;
                            statusColor = pastCurrentDate ? YELLOW : RED;
                        }
                    }
                    if (!conciliated) {//ajusto la diferencia entre presentado y liquidado cuando concilia
                        for (let presentationTotal of row.presentationTotals) {
                            presentedAmount += presentationTotal.netAmount;
                            presentedGrossAmount += presentationTotal.grossAmount;
                            presentedChargeAmount += presentationTotal.chargeAmount;
                            presentedContapartAmount += presentationTotal.contrapartAmount;
                        }
                    }

                }
                for (let settlementTotal of row.settlementTotals) {
                    settlementTaxAmount += settlementTotal.taxAmount;
                    settlementAmount += settlementTotal.netAmount;
                    settlementGrossAmount += settlementTotal.grossAmount;
                    settlementChargeAmount += settlementTotal.chargeAmount;
                    settlementContapartAmount += settlementTotal.contrapartAmount;
                    if (conciliated) {//ajusto la diferencia entre presentado y liquidado cuando concilia
                        presentedAmount += settlementTotal.netAmount;
                        presentedGrossAmount += settlementTotal.grossAmount;
                        presentedChargeAmount += settlementTotal.chargeAmount;
                        presentedContapartAmount += settlementTotal.contrapartAmount;
                    }
                    events.push({
                        additionalData: { statusColor, row },
                        title: this.buildTitleText(settlementTotal.currency,settlementTotal.netAmount),
                        start: row.paymentDate,
                        end: row.paymentDate.replace("T00","T23"),
                        paymentChargeAndReturnsFlag: settlementTotal.contrapartAmount!=0||settlementTotal.credVouchAmount!=0
                    });
                }
            } else if (row.presentationTotals.length > 0) {
                statusColor = pastCurrentDate ? "#F3B823" : "#DD3435";
                for (let settlementTotal of row.presentationTotals) {
                    presentedAmount += settlementTotal.netAmount;
                    presentedGrossAmount += settlementTotal.grossAmount;
                    presentedChargeAmount += settlementTotal.chargeAmount;
                    presentedContapartAmount += settlementTotal.contrapartAmount;
                    events.push({
                        additionalData: { statusColor, row },
                        title: this.buildTitleText(settlementTotal.currency,settlementTotal.netAmount),
                        start: row.paymentDate,
                        end: row.paymentDate.replace("T00","T23"),
                        paymentChargeAndReturnsFlag: settlementTotal.contrapartAmount!=0||settlementTotal.credVouchAmount!=0
                    });
                }
            } else {
                events.push({
                    additionalData: { statusColor:"#9B9B9B", row },
                    title: this.buildTitleText(currency,0),
                    start: row.paymentDate,
                    end: row.paymentDate.replace("T00","T23"),
                    paymentChargeAndReturnsFlag: false
                });
            }
        }

        balanceAmount = Math.abs(presentedAmount)-Math.abs(settlementAmount);
        settlementOthersAmount = Math.abs(settlementAmount)-Math.abs(settlementGrossAmount)+Math.abs(settlementTaxAmount)+Math.abs(settlementChargeAmount)+Math.abs(settlementContapartAmount);
        presentedOthersAmount = Math.abs(presentedAmount)-Math.abs(presentedGrossAmount)+Math.abs(presentedContapartAmount)+Math.abs(presentedChargeAmount);

        return {
            currency,
            presentedAmount,
            settlementAmount,
            balanceAmount,

            presentedOthersAmount,
            presentedGrossAmount,
            presentedChargeAmount,
            presentedContapartAmount,

            settlementGrossAmount,
            settlementChargeAmount,
            settlementContapartAmount,
            settlementOthersAmount,
            settlementTaxAmount
        };
    }

    getFromBuscar = () => {
        const calendarApi = this.state.calendarRef.current.getApi();
        const view = calendarApi.view;
        const datesSet: DatesSetArg = { start: view.activeStart, end: view.activeEnd } as DatesSetArg;
        this.calendarUpdatedInt(datesSet, this.state.filters.selectedCurrency); // Actualizar con la moneda seleccionada
    }
    
    calendarUpdated = (res:DatesSetArg) => {
        this.calendarUpdatedInt(res,this.state.filters.selectedCurrency);
    }
    
    calendarUpdatedInt = (res: DatesSetArg | null, selectedCurrency: string) => {
        if (res === null) {
            return;
        }
    
        let fromDate = res.start;
        let toDate = res.end;
        toDate.setDate(res.end.getDate() - 1);
        if (this.lastQueryDateTime < new Date().getTime() - 1000) {
            SettlementService.getSettlementCalendar({
                From: fromDate.toJSON(),
                To: toDate.toJSON(),
                Currency: this.state.filters.selectedCurrency, 
                PayerEntity: this.state.filters.bankFilter.replace("payingEntity-", ""),
                PayingEntityBranch: this.state.filters.bankBranchFilter,
                MerchantDocument: this.state.filters.fiscalDocument=="all"?"":this.state.filters.fiscalDocument,
                MerchantNumber: this.state.filters.merchantDocument=="all"?"":this.state.filters.merchantDocument,
                ProductCode: this.state.filters.productFilter.replace("productCode-", "")
            }).then((response: AxiosResponse<ISettlementCalendarResponse>) => {
                let events: ICalendarEvent[] = [];
                let totals = this.buildCalendarTotals(response.data.rows, events, selectedCurrency);
                this.setState({
                    totals,
                    currentEvents: events,
                    filters: {
                    ...this.state.filters,
                    selectedCurrency: selectedCurrency
                    }
                });  
            });
        }
        this.lastQueryDateTime = new Date().getTime();
    }
    handleEventClick = (clickInfo: any) => {
        if (clickInfo.event.extendedProps) {
            this.setState({
                selectedSettlementDate: new Date(clickInfo.event.extendedProps.additionalData.row.paymentDate),
                eventSelected: this.buildCalendarTotals([clickInfo.event.extendedProps.additionalData.row], [], this.state.totals.currency)
            });
        }
    }

    handleDayClick = (clickInfo: any) => {
        let eventFound = this.state.currentEvents.find((event) => event.start.startsWith(clickInfo.dateStr));
        this.handleEventClick({ event: { extendedProps: eventFound } });
    }

    renderEventContent = (eventInfo: EventContentArg) => {
        return (
            <div className="calendar-event-container">
                { 
                    (eventInfo.event.extendedProps.paymentChargeAndReturnsFlag) ?
                        <Tooltip message={this.props.t('paymentChargeAndReturnsTooltip')} >
                            <i className="calendar-warning fas fa-exclamation-triangle text-warning"></i>
                        </Tooltip>
                    :
                        ''
                }
                <div className="calendar-amount" >{eventInfo.event.title}</div>
                <div className="calendar-div-status" style={{ backgroundColor: eventInfo.event.extendedProps.additionalData.statusColor }}></div>
            </div>
        )
    }

    handleCloseDialogClick = (event: any) => {
        event.preventDefault();
        this.setState({ eventSelected: null });
    }

    handleCurrencyChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const selectedCurrency = event.target.value;
        this.setState(prevState => ({
            ...prevState,
            filters: {
        ...prevState.filters,
        selectedCurrency: selectedCurrency
            }
        }));
    }
    
    handleChange(event: ChangeEvent<HTMLSelectElement>): void {
        const target: HTMLSelectElement = event.target;
        const { name, value } = target;    
        this.setState(prevState => ({
            ...prevState,
            selectedCurrency: value 
        }), () => {
            if (name === "currencyFilter") {
                this.getFromBuscar(); 
            } else {
                this.setState({...this.state, [target.name]: target.value});
            }
        }
        );
    }

    buildCalendarTotalByDayTitle = (): string => {
        const { t } = this.props;
        return t("calendarDayDetail", { day: this.state.selectedSettlementDate?.toLocaleDateString('es-ES', { month: 'long', day: 'numeric' })});
    }

    componentDidUpdate(prevProps:any, prevState:any) {
        if(lastFiscalDocument!=this.state.filters.fiscalDocument){
            lastFiscalDocument=this.state.filters.fiscalDocument;
            let merchantNumbers= OptionMapperHelper.getAllMerchantNumbersOptions(lastFiscalDocument,true,true)
            this.setState({...this.state,filters:{...this.state.filters,merchantDocument:merchantNumbers[0].value},merchantNumbers:merchantNumbers})
        }
    }

    getSettlementPath = (settlementCurrency: string | null) => {
        let captions:IShortcutCaptureResult[]=[];
        captions.push({data:this.state.settlementCurrency,description:"CURRENCY",type:"CURRENCY"})
        captions.push({data:{ year: (this.state.selectedSettlementDate+"").split("T")[0].split("-")[0],
                month: (this.state.selectedSettlementDate+"").split("T")[0].split("-")[1],
                day: (this.state.selectedSettlementDate+"").split("T")[0].split("-")[2]},description:"PAYMENT_DATE",type:"PAYMENT_DATE"})
        return "/reports/settlements?shortcuts="+String.escapeURL(JSON.stringify(captions));
    }
    
    buildSelectOptionsFromIdName(options: Array<{id: string, name: string}>) {
        return new OptionMapperHelper(this.props.t).buildSelectOptionsFromAnArrayWithDifferentInterface(options, 'id', 'name');
    }

    setFilters(filters: IFilters) {
        this.setState({ ...this.state, filters });
    }

    clearFilter = () => {
        this.setFilters({
            ...this.state.filters,
            bankFilter:'',
            fiscalDocument: '',
            merchantDocument: '',
            bankBranchFilter:'',
            productFilter:'',
            selectedCurrency:''
        });
    }
    
    render() {
        const { t } = this.props;
        const currentUser = WebCache.getCurrentUser()?.countryId;
        let merchantDocument;
        let currencyFilter;

        if (currentUser !== undefined && currentUser === 2) {
            merchantDocument = `${T_PREFIX}merchantDocumentROU`;
            currencyFilter =  (<SelectField label={t("currency")} name="currency" options={this.state.currencyOptions}  onChange={this.handleCurrencyChange}  value={this.state.filters.selectedCurrency} />   
        )
        } else {
            merchantDocument = `${T_PREFIX}merchantDocumentARG`;
            currencyFilter= ''
        }
        
        return (
            <Page>
                <>
                <h2>{t(`${T_PREFIX}conciliationCalendar`)}</h2>
                <Modal show={this.state.eventSelected!=null}>
                    <div className="card border p-0 pb-3">
                        <div className="card-header border-0 pt-3">
                            <div className="card-options">
                                <a href={window.location.href} className="card-options-remove" onClick={this.handleCloseDialogClick} ><i className="fe fe-x"></i></a>
                            </div>
                        </div>
                        <div className="card-body text-center">
                            <CalendarTotal title={this.buildCalendarTotalByDayTitle()} totals={this.state.eventSelected ?? this.state.totals}></CalendarTotal>
                        </div>
                        <div className="card-footer text-center border-0 pt-0">
                            <div className="d-flex flex-row-reverse">
                                <a href={window.location.href} className="btn btn-white me-2" onClick={this.handleCloseDialogClick}>{t('close')}</a>
                                <Link to={`/conciliation/settlementVouchers?shortcuts=${JSON.stringify([{ type:"DATE", data: this.state.selectedSettlementDate }, {type:"CURRENCY", data: this.state.filters.selectedCurrency}])}`} className="btn btn-primary me-2">
                                    {t(`${T_PREFIX}goToSettlement`)}
                                </Link>
                            </div>
                        </div>
                    </div>
                </Modal>
                <div className="row">
                    <div className="col-lg-12">
                        <Card>
                            <FormFilter model={[ this.state.filters, this.setFilters.bind(this)]} onSubmit={this.getFromBuscar} clear={this.clearFilter}>
                                <div className="row">
                                    <div className="col-md-4">
                                        <SelectField attr='bankFilter' label='bank'
                                                    options={this.optionMapper.translateOptionValues(this.state.bankOptions)}></SelectField>
                                    </div>
                                    <div className="col-md-4">
                                        <SelectField attr='fiscalDocument' label={merchantDocument}
                                                    options={this.optionMapper.translateOptionValues(this.state.fiscalDocuments)}></SelectField>
                                    </div>
                                    <div className="col-md-4">
                                        <SelectField attr='merchantDocument' label={`merchantNumber`}
                                                    options={this.optionMapper.translateOptionValues(this.state.merchantNumbers)}></SelectField>
                                    </div>
                                    <div className="col-md-4">
                                        <SelectField attr='bankBranchFilter' label='branch'
                                                    options={this.optionMapper.translateOptionValues(this.state.bankBranchOptions)}></SelectField>
                                    </div>
                                    <div className="col-md-4">
                                        <SelectField attr='productFilter' label='card'
                                                    options={this.optionMapper.translateOptionValues(this.state.productOptions)}></SelectField>
                                    </div>
                                    <div className="col-md-4">
                                        {currencyFilter}
                                    </div>
                                </div>
                            </FormFilter>
                        </Card>
                    </div>
                </div>
                    <div className="row">
                        <div className="col-lg-9">
                            <Card>
                                <FullCalendar
                                    ref={this.state.calendarRef}
                                    datesSet={this.calendarUpdated}
                                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                    headerToolbar={{left: 'prev', center: 'title', right: 'next'}}
                                    locale={esLocale}
                                    initialView='dayGridMonth'
                                    dayHeaderFormat={{weekday: this.state.weekDayFormat}}
                                editable={false}
                                selectable={true}
                                selectMirror={true}
                                dayMaxEvents={true}
                                weekends={this.state.weekendsVisible}
                                events={this.state.currentEvents}
                                //initialEvents={INITIAL_EVENTS} // alternatively, use the `events` setting to fetch from a feed
                                //select={this.handleDateSelect}
                                eventContent={this.renderEventContent} // custom render function
                                eventClick={this.handleEventClick}
                                dateClick={this.handleDayClick}
                                //eventsSet={this.handleEvents} // called after events are initialized/added/changed/removed
                                /* you can update a remote database when these fire:
                                eventAdd={function(){}}
                                eventRemove={function(){}}
                                eventChange={function(){alert("ok")}}
                                */
                            />
                        </Card>
                    </div>
                    <div className="col-lg-3">
                        <CalendarTotal title={t("calendarTotalDetail")} totals={this.state.totals}></CalendarTotal>
                    </div>
                </div>
            </>
            </Page>
        )
    };
}
export default withTranslation()(PaymentCalendar);
