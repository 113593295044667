import React, { Dispatch, ReactElement, SetStateAction,  useEffect,  useState} from 'react';
import { TFunction, withTranslation } from "react-i18next";
import "./AdvancedRefund.scss";
import SubmitButton from '../controls/buttons/submitButton';
import {Modal } from "react-bootstrap";
import {AxiosResponse} from "axios";
import Page from '../containers/page';
import NavigationTabLinksForAdvanced from './NavigationTabLinksForAdvanced';
import {ColumnDefinition, currencyColumnDefinition, textColumnDefinition, numericColumnDefinition, actionColumnDefinition } from '../table/ColumnDefinition';
import CustomTable, { TableModeEnum } from '../table/CustomTable';
import FormFilter from '../containers/form-filter';
import SelectField from '../controls/fields/selectField';
import NumericField from '../controls/fields/numericField';
import { WebCache } from '../../services/WebCache';
import { OptionMapperHelper } from '../../helpers/OptionMapperHelper';
import { DateHelper } from '../../helpers/DateHelper';
import { DateObject } from 'react-multi-date-picker';
import Card from '../containers/card';
import { Link } from 'react-router-dom';
import TermsAndContions from '../layout/TermsAndCondition'
import RegulationCommerce from '../layout/RegulationCommerce'
import { AdvancedService } from '../../services/AdvancedService';
import {
    IAvailableSettlementsRequest,
    IAvailableSettlementsResponse,
    IcouponsSearchRequest,
    IcouponsSearchResponse,
    IliquidationsConfirmationRequest,
    IliquidationsConfirmationResponse,
    BalanceListDTO,
    PaymentEntityListDTO,
    PaymentEmiterListDTO,
    IWS81PaymentLists,
    CommerceFiscalNumbersList
} from '../../models/IAdvancedData';
import { toast } from 'react-toastify';
import { ReactComponent as LogoSVG } from "../../../node_modules/itd-common/assets/images/brand/logo.svg";
import { UtilService } from '../../services/UtilService';
import i18next from "i18next";

enum PageMode {
    ADVANCED,
    SEARCH_COUPONS,
    CONFIRM_ADVANCED,
}
interface IProps {
    t: TFunction
}
interface IFilters {
    comercios:string,
    issuingEntitysCode: string,
    payingEntitysCode: string,
    brandsCode:string,
    amount:string,
    cuits:string    
};

type RenderObject = {
    renderFunction: () => ReactElement,
    initialize?: {
        state: [any, Dispatch<SetStateAction<any>>],
        function: <T>(state: [T, Dispatch<SetStateAction<T>>]) => void 
        } 
};

type Option ={
    value:string,
    label: string
}

const T_PREFIX: string = 'pages.advanced.';
const ARGENTINA= "AR";

const AdvancedRefund: React.FC<IProps> = ({ t }: IProps) => {
    const [pageMode, setPageMode] = useState<PageMode>(PageMode.ADVANCED);
    const [detailAdvancedModal, setDetailAdvancedModal] = useState<boolean>(false);
    const [filters,setFilters]= useState<IFilters>({ comercios:"",issuingEntitysCode:WebCache.getAllPayerBanks(true)[0].value,payingEntitysCode:WebCache.getAllPayerBanks(true)[0].value,brandsCode:WebCache.getAllProducts(true)[0].value,amount:"",cuits:""});
    const [totalAmount,setTotalAmount]= useState<string>();
    const [showTermsAndConditionsModal, setShowTermsAndConditionsModal] = useState<boolean>(false);
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [country, setCountry] = useState<string>(ARGENTINA);
    const [showRegulationCommerce, setShowRegulationCommerce]=useState (false)
    const [selectedRows,setSelectedRows]= useState<IWS81PaymentLists[]>([]);
    const [mainPage,setMainPage]= useState<number>(0)
    const [cuits, setCuits] = useState<Array<HTMLOptionElement>>(new OptionMapperHelper(t).translateOptionValues(WebCache.getAllMerchantDocuments(true,false)) as Array<HTMLOptionElement>);
    const [comercios, setComercios]=useState<Option[]>(new OptionMapperHelper(t).translateOptionValues(OptionMapperHelper.getAllMerchantNumbersOptions(WebCache.getAllMerchantDocuments(true,false)[0].label,true,false)) as Array<Option>);
    const [allComercios, setAllComercios]=useState<Option[]>(new OptionMapperHelper(t).translateOptionValues(OptionMapperHelper.getAllMerchantNumbersOptions(WebCache.getAllMerchantDocuments(true,false)[0].label,true,false)) as Array<Option>);
    const [allBrands, setAllBrands]= useState<Option[]>([] as Array<Option>);
    const [paymentEntityList, setPaymentEntityLists]= useState<Option[]>(new OptionMapperHelper(t).translateOptionValues(WebCache.getAllPayerBanks(true)) as Option[]);
    const [paymentEmiterList,setPaymentEmiterList]= useState<Option[]>(new OptionMapperHelper(t).translateOptionValues(WebCache.getAllPayerBanks(true)) as Option[]);
    const [balanceList,setBalanceList]= useState<BalanceListDTO[]>([]);
    const [paymentLists,setPaymentLists]= useState<IWS81PaymentLists[]>([]);
    const [sumaryTotals,setSumaryTotals]= useState({totalCouponsQuantity:0,financialCost:"%",totalNetAmount:0,totalPreviousCost:0,totalAcreditedAmount:0});
    
    const openTermsAndConditionsModal = () => {
        setCountry(ARGENTINA)
        setShowTermsAndConditionsModal(true);
    };
    
    const closeTermsAndConditionsModal = () => {
        setShowTermsAndConditionsModal(false);
    };

    const openRegulationCommerceModal = ()=>{
        setShowRegulationCommerce(true);
    }

    const closeRegulationCommerceModal = ()=>{
        setShowRegulationCommerce(false);
    }

    const openDetailAdvanced = () => {
        setDetailAdvancedModal(true);
    };

    //Funcion para pasar la fecha a formato dd.mm.aaaa
    const formatDateObject = (dateObject: DateObject): string => {
        if (!dateObject || !dateObject.day || !dateObject.month || !dateObject.year){
            return ''
        }
        const day = dateObject.day.toString().padStart(2, '0');
        const month = dateObject.month.toString().padStart(2, '0');
        const year = dateObject.year.toString()
        return `${day}.${month}.${year}`
    }
    

    const clearFilters = () =>{
        setFilters({
            ...filters,
            comercios:"",
            issuingEntitysCode:"",
            payingEntitysCode:"",
            brandsCode:"",
            amount:"",
            cuits:""
        })
    }

    useEffect(() => {
        if(filters.cuits && filters.cuits.length > 0){
            setFilters({...filters,comercios:OptionMapperHelper.getAllMerchantNumbersOptions(filters.cuits,true,false)[0].key})
            setComercios(allComercios.filter(x=>filters.cuits==""?true:x.value.indexOf(filters.cuits)==0));
        }
    }, [filters.cuits]); 

    useEffect(()=>{
        setSelectedRows([...selectedRows])
        const totalCouponsQuantity = selectedRows.reduce((sum,row)=> sum + (Number(row.couponsQuantity)),0)
        const totalNetAmount = selectedRows.reduce((sum,row)=> sum + (Number(row.netAmount)),0)
        const totalPreviousCost = selectedRows.reduce((sum,row)=> sum + (Number(row.previousCost)),0)
        const totalAcreditedAmount = selectedRows.reduce((sum,row)=> sum + (Number(row.acreditedAmount)),0)
        
        setSumaryTotals({
            totalCouponsQuantity,
            financialCost:"%",
            totalAcreditedAmount,
            totalNetAmount,
            totalPreviousCost
        });        
    },[selectedRows])

    const availableSettlementsRequest = () => {
        const tranWebModel: IAvailableSettlementsRequest = {
            fiscalNumbers: WebCache.getAllMerchantDocuments(false, false)?.map(item => item.value), /* ["0033630659219", "0033711535719"], */
            Skip: 0,
            Take: 0,
        };
        return tranWebModel;
    }

    useEffect(()=>{
    //Llamar al servicio que vamos a mostrar en el primer render de ADVANCED
    const getInitSettlement = async () => {
            try {
                const res:AxiosResponse<IAvailableSettlementsResponse> = await AdvancedService.availableSettlements(availableSettlementsRequest());
                    if(res.data) {
                        const paymentEntityOptions = [{label: i18next.t("allFemale"),value: ""}].concat(res.data.paymentEntityLists.map((item)=>({
                            label: item.paymentEntityName,
                            value: item.paymentEntityName
                        })));
                        const paymentEmiterOptions = [{label: i18next.t("allFemale"),value: ""}].concat(res.data.paymentEmiterLists.map((item)=>({
                            label: item.paymentEmiterName,
                            value: item.paymentEmiterName
                        })));
                        const brandsOptions = [{label: i18next.t("allFemale"),value: ""}].concat(res.data.balanceLists.map((item)=>({
                            label: item.brandName,
                            value: item.brandName
                        })));
                        const merchantsOptions = [{label: i18next.t("all"),value: ""}].concat(res.data.availableMerchants.map((item)=>({
                                label: item.value,
                                value: item.id
                        })));
                        setTotalAmount(res.data.totalAmount)
                        setBalanceList(res.data?.balanceLists || []);
                        setAllBrands(brandsOptions);
                        setPaymentEmiterList(paymentEmiterOptions)
                        setPaymentEntityLists(paymentEntityOptions)
                        setComercios(merchantsOptions.filter(x=>filters.cuits==""?x:x.value.indexOf(filters.cuits)==0));
                        setAllComercios(merchantsOptions);
                    }
                ;
            } catch (error) {
                console.error("Error al mostrar cupones:", error);
                toast.error ('Error al mostrar cupones')
            }
        };
        getInitSettlement();
    },[]) 

    //#####################################################################  Render Methods ################################################################################## 
    /////////////////////////////////////////////////////////////Render total de cupones por marca////////////////////////////////////////////////////////////////////////////////////////////
    const totalCoupon = (): ReactElement => {

        const searchCoupons = () =>{
            setPageMode(PageMode.SEARCH_COUPONS)
        }

        const columnDefinitions:ColumnDefinition[] = [
            textColumnDefinition({ key: 'brandName', label: t(`${T_PREFIX}brand`), mapValue: (logoMarca: BalanceListDTO) => {
                    const imgLogo= logoMarca.brandName;
                    return <img src={UtilService.getImageTag(imgLogo)} alt={`${imgLogo}`} ></img>;
                }   
            }),
            numericColumnDefinition({ key: 'couponsNumber', label: t(`${T_PREFIX}advancedColumns.totalCupons`),mapValue:(indiceCupones: BalanceListDTO)=>{
                const qCoupons = indiceCupones.couponsNumber
                return qCoupons.replace(/^0+/ ,'')
            }}),
            currencyColumnDefinition({ key: 'availableAmount', label: t(`${T_PREFIX}advancedColumns.totalAmount`),columnDefinitionKey:'currency',mapValue:(rowObjects:BalanceListDTO)=>{
                const availableAmount = rowObjects.availableAmount
                return Intl.NumberFormat('es-UY', { style: 'currency', currency: "UYU" }).format(Number(availableAmount)/100)
            }})
        ]

        return (
            <React.Fragment> 
                <>
                    <div className="row">
                        <Card>
                            <>
                                <h4>{t(`${T_PREFIX}infoBrand`)} </h4>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div>
                                        <CustomTable
                                            rowObjects={balanceList}
                                            columnDefinitions={columnDefinitions}
                                            config={{
                                                paginator: {
                                                    quantity:30,
                                                mainPage: mainPage,
                                                    modifyMainPage: (value: number) => {
                                                        setMainPage(mainPage +value )
                                                    },
                                                pageRequest: {
                                                        Skip: 0,
                                                        Take: 0
                                                    }
                                                }
                                            }}
                                        ></CustomTable>
                                        </div> 
                                        <div className="row m-4">
                                            <div className='col-md-8'></div>
                                            <div className='col-md-4'>
                                                <label className="tariffAmount"> {t(`${T_PREFIX}totalAmountTariff`)}</label>
                                                <label hidden={Number(totalAmount) === 0 } className="border-start-0 ms-0" >{
                                                    Intl.NumberFormat('es-UY', { style: 'currency', currency: "UYU" }).format(Number(totalAmount)/100)
                                                }</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='confirmContainer row'>
                                    <div className='col-md-9'></div>
                                    <div className='boton col-md-3'>
                                        <div className='boton__click'>
                                            <SubmitButton className='mb-4 sendingButton' onClick={searchCoupons} >
                                                <div className='submit-button-container'>
                                                    <span className='ms-3'>{t(`${T_PREFIX}continue`)}</span>
                                                </div>
                                            </SubmitButton>
                                        </div>
                                    </div>
                                </div>   
                            </>
                        </Card> 
                    </div>  
                </>
            </React.Fragment>
        );
    }

    /////////////////////////////////////////////////////Render busqueda cupones/////////////////////////////////////////////////////

    const renderSearchCoupons = (): ReactElement => {

        const couponsSearchRequest = () => {
            const tranWebModel: IcouponsSearchRequest = {
                maxAmount: filters.amount || "",
                temporalStorageId: "",
                temporalStorageLastItem: "",
                brandsList: getBrandNamesRequest(),
                paymentEntityList: getPaymentEntityListRequest(),
                emitterEntityList: getEmitterEntityList(),
                commerceFiscalNumbersList: getcommerceFiscalNumbersListRequest(),
                Skip: 0,
                Take: 0,
            };
            return tranWebModel;
        }

        const getPaymentEntityListRequest = (): Array<string | undefined> => {
            if(filters.payingEntitysCode=="")
                return paymentEntityList.map(x=>x.value).filter(x=>x.length>0);
            return [filters.payingEntitysCode];
        }

        const getEmitterEntityList = (): Array<string | undefined> => {
            if(filters.issuingEntitysCode=="")
                return paymentEmiterList.map(x=>x.value).filter(x=>x.length>0);
            return [filters.issuingEntitysCode];
        }

        const getBrandNamesRequest = (): Array<string | undefined> => {
            if(filters.brandsCode=="")
                return allBrands.map(x=>x.value).filter(x=>x.length>0);
            return [filters.brandsCode];
        }
        const getcommerceFiscalNumbersListRequest = (): Array<CommerceFiscalNumbersList> => {
            if(filters.comercios!="")
                return  [{
                    fiscalNumber: filters.comercios.split("-")[0]+"",
                    commerceNumber: filters.comercios.split("-")[1]+""
                }]

            if(filters.cuits=="") {
                var res: Array<CommerceFiscalNumbersList> = [];
                var fiscalNumbers = WebCache.getAllMerchantDocuments(false, false).map(x => x.key);
                for (var i in fiscalNumbers) {
                    var fn = fiscalNumbers[i];
                    res=res.concat(comercios.filter(x=>x.value!="").map(y => {
                                return {
                                    fiscalNumber: fn + "",
                                    commerceNumber: y.label + ""
                                }
                            }
                        ));
                }

                return res;
            }


           return comercios.filter(x=>x.value!="").map(y => {
                   return {
                       fiscalNumber:  filters.cuits,
                       commerceNumber: y.label + ""
                   }
               }
           );


        }


        
        //Llamamos  al servicio que va a traer los cupones (serviceWS81)
        const getCoupons = async () => {
            try {
                const res: AxiosResponse<IcouponsSearchResponse> = await AdvancedService.couponsSearch(couponsSearchRequest());
                    if(res.data) {
                        /*
                       alert("Cupones: "+
                            Intl.NumberFormat('es-UY', {  minimumFractionDigits: 0 }).format(res.data.couponCount)
                            +", TotalNeto: "+
                            Intl.NumberFormat('es-UY', { style: 'currency', currency: "UYU" }).format(res.data.netTotal/10)
                            +", CostoTotal:"+
                            Intl.NumberFormat('es-UY', { style: 'currency', currency: "UYU" }).format(res.data.costTotal/10)
                            +", TotalAcreditado: "+
                            Intl.NumberFormat('es-UY', { style: 'currency', currency: "UYU" }).format(res.data.acredTotal/10)
                        )
                        var tmp:any={}
                        res.data.paymentListsDates.forEach(x=>{
                            var key=x.paymentDate+" - "+x.brand+" - "+x.operationType;
                            if(tmp[key]==null)
                                tmp[key]={couponCount:0,netTotal:0,costTotal:0,acredTotal:0};
                            tmp[key].couponCount+=Number.parseInt(x.couponsQuantity);
                            tmp[key].netTotal+=Number.parseInt(x.netAmount);
                            tmp[key].costTotal+=Number.parseInt(x.previousCost);
                            tmp[key].acredTotal+=Number.parseInt(x.acreditedAmount);
                        });
                        for(var i in tmp)
                            console.log(i+": "+JSON.stringify(tmp[i]));

*/

                        setPaymentLists(res.data.paymentListsDates || []);
                        console.log(res.data.paymentListsDates);
                    }
            } catch (error) {
                console.error("Error al buscar cupones", error);
                toast.error("Error al buscar cupones");
            }
        };

        const handleRowSelect = (rowObject:IWS81PaymentLists) =>{
            setSelectedRows((prev) => {              
                if (prev.includes(rowObject)){
                    return prev.filter((obj)=>obj !== rowObject)
                } else {
                    return [...prev,rowObject]
                }
        }); 
    };

        const handleSelectAll = () => {
            if (selectedRows.length  === paymentLists.length){
                setSelectedRows([]);
            } else {
                setSelectedRows([...paymentLists])
            }
        }

        const disabledConfirm = (): boolean => {
            let rowsConfirm = (selectedRows.length === 0);
            return rowsConfirm;
        }; 

        const advancedColumns: ColumnDefinition[] = [
            actionColumnDefinition({key:"",label:t(`${T_PREFIX}advancedColumns.selected`),action:{
                function:(rowObject: IWS81PaymentLists) =>{
                    return null
                },
                icon:(rowObject: IWS81PaymentLists) =>{
                    const isChecked = selectedRows.includes(rowObject)
                    return <div className='containerIconRefund'> 
                                <input type="checkbox" checked={isChecked} onChange={()=>handleRowSelect(rowObject)}  /> 
                            </div>
            }}}),
            textColumnDefinition({ key: 'paymentDate', label: t(`${T_PREFIX}advancedColumns.operationDate`)}),
            numericColumnDefinition({ key: 'couponsQuantity', label: t(`${T_PREFIX}advancedColumns.totalCupons`),mapValue:(indiceCupones:IWS81PaymentLists)=>{
                const qCoupons = indiceCupones.couponsQuantity
                return qCoupons.replace(/^0+/ ,'')
            }}),
            textColumnDefinition({ key: 'operationType', label: t(`${T_PREFIX}advancedColumns.operationType`),mapValue:(rowObjects:IWS81PaymentLists)=>{
                if (rowObjects.operationType === "CDO"){
                    return "Contado"
                } else if (rowObjects.operationType === "CTA"){
                    return "Cuota"
                } else {
                    return ""
                }
            }}),
            textColumnDefinition({ key: 'netAmount', label: t(`${T_PREFIX}advancedColumns.netAmount`),mapValue:(rowObjects:IWS81PaymentLists)=>{
                const netAmount = rowObjects.netAmount
                return Intl.NumberFormat('es-UY', { style: 'currency', currency: "UYU" }).format(Number(netAmount)/100)
            }}),
            textColumnDefinition({ key: 'previousCost', label: t(`${T_PREFIX}advancedColumns.anticipationCostAmount`),mapValue:(rowObjects:IWS81PaymentLists)=>{
                const previousCost = rowObjects.previousCost
                return Intl.NumberFormat('es-UY', { style: 'currency', currency: "UYU" }).format(Number(previousCost)/100)
            }}),
            textColumnDefinition({ key: 'acreditedAmount', label: t(`${T_PREFIX}advancedColumns.anticipationAmount`),mapValue:(rowObjects:IWS81PaymentLists)=>{
                const acreditedAmount = rowObjects.acreditedAmount
                return Intl.NumberFormat('es-UY', { style: 'currency', currency: "UYU" }).format(Number(acreditedAmount)/100)
            }}),
        ];

        return (
            <React.Fragment>
                <FormFilter model={[filters, setFilters]} clear={clearFilters} onSubmit={getCoupons}  >
                    <>
                        <div className="row form-filter">


                            <div className="col-md-4">
                                <SelectField attr='cuits' label={WebCache.getCurrentUser()?.countryId==2?`${T_PREFIX}formFilter.fiscalNumberUY`: `${T_PREFIX}formFilter.fiscalNumberAR`} options={cuits}></SelectField>
                            </div>
                            <div className="col-md-4">
                                <SelectField attr='comercios' label={`${T_PREFIX}formFilter.comercios`} options={comercios}></SelectField>
                            </div>
                            <div className="col-md-4">
                                <SelectField attr='brandsCode' label={`${T_PREFIX}formFilter.brandsCode`} options={allBrands}></SelectField>
                            </div>  
                            <div className="col-md-4">
                                <SelectField attr='payingEntitysCode' label={`${T_PREFIX}formFilter.payingEntitysCode`} options={paymentEntityList} ></SelectField>
                            </div>
                            <div className="col-md-4">
                                <SelectField attr='issuingEntitysCode' label={`${T_PREFIX}formFilter.issuingEntitysCode`} options={paymentEmiterList} ></SelectField>
                            </div>
                            <div className="col-md-4">
                                <NumericField attr='amount' label={`${T_PREFIX}formFilter.amount`} ></NumericField>
                            </div>
                        </div>
                    </>
                </FormFilter>
                <nav className="nav mb-4">
                    <span className="vertical-align border-0 mt-0">
                        <input
                            type='checkbox'
                            onChange={handleSelectAll}
                            checked={paymentLists.length > 0 && selectedRows.length === paymentLists.length}   
                            />
                        <span>{t(`${T_PREFIX}selectAll`)}</span>
                    </span>                                    
                </nav> 
                <div className="row">
                    <div className="col-md-12">
                        <CustomTable
                            columnDefinitions={advancedColumns}
                            rowObjects={paymentLists} 
                            config={{
                                mode: TableModeEnum.LIST,
                                paginator: {
                                    quantity:30,
                                    mainPage: mainPage,
                                    modifyMainPage: (value: number) => {
                                        setMainPage(mainPage +value )
                                    },
                                    pageRequest: {
                                        Skip: 0,
                                        Take: 0
                                    }
                                }
                            }}   
                        ></CustomTable>
                    </div>
                </div>  
                <div className="row mt-4">
                    <div className="col-md-12">
                        <div className="table-container">
                            <h2>{t(`${T_PREFIX}summaryAdvanced`)}</h2>
                            <table className="styled-table">
                                <thead className='titleTable'>
                                    <tr >
                                    <th>{t(`${T_PREFIX}totalCouponsQuantity`)}</th>
                                    <th>{t(`${T_PREFIX}totalNetAmount`)}</th>
                                    <th>{t(`${T_PREFIX}financialCost`)}</th>
                                    <th>{t(`${T_PREFIX}totalPreviousCost`)}</th>
                                    <th>{t(`${T_PREFIX}totalAcreditedAmount`)}</th>
                                    </tr>
                                </thead> 
                                <tbody>
                                    <tr>
                                        <td>{sumaryTotals.totalCouponsQuantity}</td>
                                        <td>{Intl.NumberFormat('es-UY', { style: 'currency', currency: "UYU" }).format(Number(sumaryTotals.totalNetAmount)/100)}</td>
                                        <td>{sumaryTotals.financialCost}</td>
                                        <td>{Intl.NumberFormat('es-UY', { style: 'currency', currency: "UYU" }).format(Number(sumaryTotals.totalPreviousCost)/100)}</td>
                                        <td>{Intl.NumberFormat('es-UY', { style: 'currency', currency: "UYU" }).format(Number(sumaryTotals.totalAcreditedAmount)/100)}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className='navbar-text ms-auto mb-3 col-md-6 fs-5 pe-0 d-flex justify-content-end'>
                    <div className="col-md-3">
                        <SubmitButton className="mb-4 envio" onClick={()=>setPageMode(PageMode.ADVANCED)}>
                            <div className='submit-button-container'>
                                <span className='ms-3' >{t(`${T_PREFIX}back`)}</span>
                            </div>
                        </SubmitButton>
                    </div>
                    <div className="col-md-3">
                        <SubmitButton className="mb-4 envio" onClick={()=>setPageMode(PageMode.CONFIRM_ADVANCED)} disabled={disabledConfirm()} >
                            <div className='submit-button-container'>
                                <span className='ms-3' >{t(`${T_PREFIX}continue`)}</span>
                            </div>
                        </SubmitButton>
                    </div>
                </div>
        </React.Fragment>
        );
    };

    ////////////////////////////////////////////////////////////////////////////////Render confirmación/////////////////////////////////////////////////////////////////////////////////////////
    const renderConfirmationAdvanced = (): ReactElement => {

        //Se refresca el componente y se setea la pagina inicial cuando se cierra el modal de confirmación
        const refreshAdvanced = () => {
            // eslint-disable-next-line no-restricted-globals
            location.reload()
            setPageMode(PageMode.ADVANCED)
        }

        const getLiquidationsConfirmationRequest = () => {
            const tranWebModel: IliquidationsConfirmationRequest = {
                user: '',
                temporalStorageId: '',
                itemNumber: '',
                moreData: 'N',
                datesLists: selectedRows.map(payment => ({
                    fiscalNumber: payment.fiscalNumber ,
                    commerceNumber: payment.commerceNumber,
                    brand: payment.brand,
                    currencyCode: payment.currencyName,
                    countryCode: payment.countryCode,
                    paymentEntity: payment.paymentEntity,
                    emiterEntity: payment.emmiterEntity,
                    paymentDate: payment.paymentDate,
                    operationType: payment.operationType,
                    agreementRateCode: payment.agreementRateCode,
                    agreementRate: payment.agreementRate,
                    investorCode: payment.investmentEntity
                }))
            };
            return tranWebModel;
        }

        //Llamamos al servicio de confirmacion de adelanto (serviceWS82)
        const confirmAdvanced = async () => {
            try {
                const res: AxiosResponse <IliquidationsConfirmationResponse> =  await AdvancedService.liquidationsConfirmation(getLiquidationsConfirmationRequest());
                if(res.data.returnCode === "00000") {
                    console.log(res.data);
                    openDetailAdvanced()
                } else {
                    toast.error("Error al generar el anticipo");
                    setPageMode(PageMode.ADVANCED)
                }
                } catch (error) {
                    console.error("Error al generar el anticipo:", error);
                    toast.error("Error al generar el anticipo");
            }
        };

        //Mapeo para mostrar agrupado los datos en el resumen de anticipos
        const entityGroup = Array.from(new Set(selectedRows.map(x=>x.investmentEntity)))
        const couponsGroup = selectedRows.reduce((acc,item) => acc + (Number(item.couponsQuantity)),0)
        const fiscalGroup = Array.from(new Set(selectedRows.map(x=>x.fiscalNumber)))
        const brandGroup = Array.from(new Set(selectedRows.map(x=>i18next.t(T_PREFIX+"brand-"+x.brand)+", ")))
        const netAmountGroup = selectedRows.reduce((acc,item) => acc + (Number(item.netAmount)/100),0)
        const previousCostGroup = selectedRows.reduce((acc,item) => acc + (Number(item.previousCost)/100),0)
        const netAcreditedAmountGroup = selectedRows.reduce((acc,item) => acc + (Number(item.acreditedAmount)/100),0)

        /* Lógica para deshabilitar el continuar si no se aceptan los terminos.*/
        const isSubmitDisabled = (): boolean => {
            let isTermsNotAccepted=!(termsAccepted);
            return isTermsNotAccepted;
        };

        const handleTermsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setTermsAccepted(event.target.checked);
        }; 

        return (
            <React.Fragment>
                <>
                <div >
                        <>
                            <div className="col-12">
                                <h4 className='titleAdvanced'>{t(`${T_PREFIX}summaryAdvanced`)} </h4>
                            </div>
                            <div className='summaryAdvanced'>
                                <p>{t(`${T_PREFIX}advancedColumns.totalCupons`)}</p><span>{couponsGroup}</span>
                            </div> 
                            <div className='inlineSummary'></div>
                            <div className='summaryAdvanced'>
                                <p>{t('CUIT')}</p><span> {fiscalGroup} </span>
                            </div>
                            <div className='inlineSummary'></div>
                            <div className='summaryAdvanced'>
                                <p>{t(`${T_PREFIX}advancedColumns.brand`)}</p><span>{brandGroup}</span>
                            </div>
                            <div className='inlineSummary'></div>
                            <div className="col-12 mt-4">
                                <h4 className='titleAdvanced'>{t(`${T_PREFIX}totalCredited`)} </h4>
                            </div>
                            <div className='summaryAdvanced'>
                                <p>{t(`${T_PREFIX}advancedColumns.netAmount`)}</p><span>{Intl.NumberFormat('es-UY', { style: 'currency', currency: "UYU" }).format(Number(netAmountGroup))}</span>
                            </div>
                            <div className='inlineSummary'></div>
                            <div className='summaryAdvanced'>
                                <p>{t(`${T_PREFIX}advancedColumns.anticipationCostAmount`)}</p><span>{Intl.NumberFormat('es-UY', { style: 'currency', currency: "UYU" }).format(Number(previousCostGroup))}</span>
                            </div>
                            <div className='inlineSummary'></div>
                            <div className='summaryAdvanced'>
                                <p>{t(`${T_PREFIX}advancedColumns.anticipationAmount`)}</p><span>{Intl.NumberFormat('es-UY', { style: 'currency', currency: "UYU" }).format(Number(netAcreditedAmountGroup))}</span>
                            </div> 
                        </>
                    <div className='inlineSummary'></div>
                    <div>
                        <div>
                            <input type="checkbox" name="terms" onChange={handleTermsChange} />
                                <label>
                                    Los presentes {' '}<Link onClick={openTermsAndConditionsModal} className="linkTo" to={''}> términos y condiciones</Link> corresponden al Capítulo VII de Anticipación que forma para del {' '}<Link onClick={openRegulationCommerceModal} className="linkTo" to={''}> Reglamento de Comerciantes</Link>
                                </label>
                        </div>
                    </div>
                </div>
                <div className='navbar-text ms-auto mb-3 col-md-6 fs-5 pe-0 d-flex justify-content-end'>
                    <div className="col-md-3">
                        <SubmitButton className="mb-4 envio" onClick={()=>setPageMode(PageMode.SEARCH_COUPONS)}>
                            <div className='submit-button-container'>
                                <span className='ms-3' >{t(`${T_PREFIX}back`)}</span>
                            </div>
                        </SubmitButton>
                    </div>
                    <div className="col-md-3">
                        <SubmitButton className="mb-4 envio" onClick={()=>confirmAdvanced()} disabled={isSubmitDisabled()}>
                            <div className='submit-button-container'>
                                <span className='ms-3' >{t(`${T_PREFIX}createAdvanced`)}</span>
                            </div>
                        </SubmitButton>
                    </div>
                </div>
                <Modal show={showTermsAndConditionsModal} dialogClassName="footer-modal">
                    <div className="modal-content modal-content-demo">
                        <div className="modal-body">
                            <div className="scrollable-container">
                                <TermsAndContions country={country}/> 
                            </div>
                        </div>
                        <div className="footerModal">
                            <SubmitButton label={t(`close`)} className="me-3" onClick={() => closeTermsAndConditionsModal()} noBlock></SubmitButton>
                        </div>
                    </div>
                </Modal>
                <Modal show={showRegulationCommerce} dialogClassName="footer-modal">
                    <div className="modal-content modal-content-demo">
                        <div className="modal-body">
                            <div className="scrollable-container">
                                <RegulationCommerce /> 
                            </div>
                        </div>
                        <div className="footerModal">
                            <SubmitButton label={t(`close`)} className="me-3" onClick={() => closeRegulationCommerceModal()} noBlock></SubmitButton>
                        </div>
                    </div>
                </Modal>
                <Modal show={detailAdvancedModal} >
                    <div className="m-5">
                        <div className='brand-logo-container'>
                            <LogoSVG className='logo-image' ></LogoSVG>
                        </div>
                        <div className="modal-body">                       
                            <div className="w-100">
                                <p className='text-center textConfirm'>{t(`${T_PREFIX}sureConfirm`)}</p>
                                <p className='text-center'>{t(`${T_PREFIX}daysConfirm`)}</p>
                                <p className='text-center'>{t(`${T_PREFIX}followupConfirm`)}</p>
                                <div className="footerModal">
                                    <SubmitButton label={t(`close`)} className="me-3" onClick={() => refreshAdvanced()} noBlock></SubmitButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>            
            </>
            </React.Fragment>
        );
    }

    //#########################################################FINISH RENDER########################################################################################

    const buildRenderObject = (
        renderFunction: () => ReactElement,
        initialize?: { state: [any, Dispatch<SetStateAction<any>>], function: <T>(state: [T, Dispatch<SetStateAction<T>>]) => void }
    ): RenderObject => {
        return {renderFunction, initialize };
    }

    const renderObjectsMap: Map<PageMode, RenderObject> = new Map([
        [
            PageMode.ADVANCED,
            buildRenderObject(
                totalCoupon
            )
        ], 
        [
            PageMode.SEARCH_COUPONS,
            buildRenderObject(
                renderSearchCoupons,
            )
        ] ,
        [
            PageMode.CONFIRM_ADVANCED,
            buildRenderObject(
                renderConfirmationAdvanced,
            )
        ],
    ]);

    const getRenderObjectByPageMode = (pageMode: PageMode): RenderObject => {
        return renderObjectsMap.get(pageMode) || { title: "", renderFunction: () => <></> } as RenderObject;
    }

    const renderByPageMode = () => {
        try {
            return (
                <>
                    { getRenderObjectByPageMode(pageMode).renderFunction() }
                </>
            );
        } catch (error) {
            return console.log(error) ;
        }
    };

    return (
        <Page >
            <>
                <h2>{t(`advanced`)}</h2>
                <div className="row" >
                    <div className="col-lg-12">
                        <div className="col-lg-12 mt-2">
                            <NavigationTabLinksForAdvanced></NavigationTabLinksForAdvanced>
                        </div>
                        <span className="login100-form-title mb-5">
                            {/*{ renderTitle()}*/}
                        </span>
                        <>
                            { renderByPageMode() }
                        </>
                    </div>                        
                </div>
            </>
        </Page>
    )
}
export default withTranslation() (AdvancedRefund);
