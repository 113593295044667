import React, {useState} from 'react';
import { withTranslation,  TFunction  } from 'react-i18next';
import 'react-toastify/dist/ReactToastify.css';
import Page from '../containers/page';
import FormFilter from '../containers/form-filter';
import { ColumnDefinition, currencyColumnDefinition, numericColumnDefinition, textColumnDefinition,actionColumnDefinition } from '../table/ColumnDefinition';
import NavigationTabLinksForAdvanced from './NavigationTabLinksForAdvanced';
import DateField from '../controls/fields/dateField';
import CustomTable from '../table/CustomTable';
import { DateHelper } from '../../helpers/DateHelper';
import { DateObject } from 'react-multi-date-picker';
import { AxiosResponse } from 'axios';
import {IHistoricRequest, IHistoricResponse, BalanceList} from '../../models/IAdvancedData';
import { AdvancedService } from '../../services/AdvancedService';
import { WebCache } from '../../services/WebCache';
import { Option } from "../../models/Option";
import { toast } from 'react-toastify';
import CustomReCAPTCHA from '../CustomCaptcha';
import { ReactComponent as IconDownloadSVG } from "../../../node_modules/itd-common/assets/icons/download.svg";
interface IProps {
    t: TFunction
}
interface IFilters {
    dateFrom: DateObject;
    dateTo: DateObject;
}

const T_PREFIX: string = 'pages.advanced.';
const HistoricAdvanced: React.FC<IProps> = ({ t }: IProps) => {

    let [ dateFrom, dateTo ] = DateHelper.buildDateRange();
    const [filters, setFilters] = useState<IFilters>({ dateFrom: dateFrom, dateTo:dateTo});
    const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
    const [balanceList,setBalanceList]= useState<BalanceList[]>([]);
    const [mainPage,setMainPage]= useState<number>(0)

    const historicColumns: ColumnDefinition[] = [
        textColumnDefinition({ key: 'creationDate', label: t(`${T_PREFIX}advancedColumns.operationDate`),fixedWidth:140}),
        textColumnDefinition({ key: 'user', label: t(`${T_PREFIX}advancedColumns.userMail`),fixedWidth:10}),
        numericColumnDefinition({ key: 'managementNumber', label: t(`${T_PREFIX}advancedColumns.opearationNumber`),fixedWidth:10,mapValue:(rowObjects:BalanceList)=>{
            const managementNumber = rowObjects.managementNumber
            return managementNumber.replace(/^0+/ ,'')
        }}),
        numericColumnDefinition({ key: 'couponsQuantity', label: t(`${T_PREFIX}advancedColumns.totalCupons`),fixedWidth:10,mapValue:(rowObjects:BalanceList)=>{
            const couponsQuantity = rowObjects.couponsQuantity
            return couponsQuantity.replace(/^0+/ ,'')
        }}),
        textColumnDefinition({ key: 'investorCode', label: t(`${T_PREFIX}advancedColumns.investorDescription`),fixedWidth:10,mapValue:(rowObjects:BalanceList)=>{
            if (rowObjects.investorCode === "577"){
                return "FIRST DATA CONO SUR SRL V. A. L."
            } else {
                return ""
            }
        }}),
        textColumnDefinition({ key: 'netAmount', label: t(`${T_PREFIX}advancedColumns.netAmount`),fixedWidth:10,mapValue:(rowObjects:BalanceList)=>{
            const netAmount = rowObjects.netAmount
            return Intl.NumberFormat('es-UY', { style: 'currency', currency: "UYU" }).format(Number(netAmount)/100)
        }}),
        textColumnDefinition({ key: 'advanceCost', label: t(`${T_PREFIX}advancedColumns.anticipationCostAmount`),fixedWidth:10,mapValue:(rowObjects:BalanceList)=>{
            const advanceCost = rowObjects.advanceCost
            return Intl.NumberFormat('es-UY', { style: 'currency', currency: "UYU" }).format(Number(advanceCost)/100)
        }}),
        textColumnDefinition({ key: 'advanceAmount', label: t(`${T_PREFIX}advancedColumns.anticipationAmount`),fixedWidth:10,mapValue:(rowObjects:BalanceList)=>{
            const advanceAmount = rowObjects.advanceAmount
            return Intl.NumberFormat('es-UY', { style: 'currency', currency: "UYU" }).format(Number(advanceAmount)/100)
        }}),
        textColumnDefinition({ key: 'balanceState', label: t(`${T_PREFIX}advancedColumns.statusCode`),fixedWidth:10,mapValue:(rowObjects:BalanceList)=>{
            if (rowObjects.balanceState === "C"){
                return "Enviado"
            } else {
                return ""
            }
        }})/* ,
        actionColumnDefinition({ key: 'edit',label:'Descargar',fixedWidth:5, action: { function: (rowObject: BalanceList) => {
            toast.info(this.props.t("settlementsDownloadFileNotification"));
            this.getDowloadESettlement(rowObject.settlmentNumber,rowObject.settlmentNumber,rowObject.merchantNumber,rowObject.productCode,rowObject.productDesc); 
        }, icon: <IconDownloadSVG className='icon-download ms-5'></IconDownloadSVG>} })  */
    ];

    const clearFilters = () =>{
        setFilters ({...filters,dateFrom:dateFrom,dateTo:dateTo})
    }

    const handleCaptchaVerify = (onVerify: boolean) => {
        setIsCaptchaVerified(onVerify);
    };

    const parseDate = (inputDate: string): string => {
        const date = new Date(inputDate);
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, '0');
        const day = String(date.getUTCDate()).padStart(2, '0');
        const hours = '00';
        const minutes = '00';
        const seconds = '00';
        const milliseconds = '000000';
        return `${year}-${month}-${day}-${hours}.${minutes}.${seconds}.${milliseconds}`;
    }
    
    const historicRequest = () => {
        const tranWebModel: IHistoricRequest = {
            fromDate: parseDate(DateHelper.dateObjectToString(filters.dateFrom)),
            toDate: parseDate(DateHelper.dateObjectToString(filters.dateTo)),
            fiscalNumbers: WebCache.getAllMerchantDocuments(false,false)?.map(item => item.value),
            Skip: 0,
            Take: 0
        };
        return tranWebModel;
    }

    const searchHistoric = async ()=>{
        AdvancedService.historicAdvanced(historicRequest()).then(function(res:AxiosResponse<IHistoricResponse>) {
            if(res.data.returnCode === "00000") {
                setBalanceList(res.data.balancesList);
            }
        }).catch(error => {
            setBalanceList([])
            toast.error("Error al buscar cupones");
            console.log('error:', error);
        });
    }   

    return (        
        <Page>
        <>
        <h2>{t(`advanced`)}</h2>
                <div className="row" >
                    <div className="col-lg-12">
                        <div className="col-lg-12 mt-2">
                            <NavigationTabLinksForAdvanced></NavigationTabLinksForAdvanced>
                        </div>
                        <FormFilter model={[filters, setFilters]} clear={clearFilters} onSubmit={searchHistoric} >
                            <div className="row">
                                    <div className='col-md-4'>
                                        <DateField
                                            attr="dateFrom"
                                            label={t(`${T_PREFIX}formFilter.dateFrom`)}
                                            required={true}
                                        ></DateField>
                                    </div>
                                    <div className='col-md-4'>
                                        <DateField
                                            attr="dateTo"
                                            label={t(`${T_PREFIX}formFilter.dateTo`)}
                                            required={true}
                                        ></DateField>
                                    </div>
                                    <div className="col-md-4"></div>
                                </div>
                        </FormFilter>
                        <CustomTable
                            columnDefinitions={historicColumns} 
                            rowObjects={balanceList} 
                            config={{
                                paginator: {
                                    quantity:999,
                                    mainPage: mainPage,
                                    modifyMainPage: (value: number) => {
                                        setMainPage(mainPage +value )
                                    },
                                    pageRequest: {
                                        Skip: 0,
                                        Take: 0
                                    }
                                }
                            }}                       
                        ></CustomTable>
                        <div className='row'>
                            <div className='col-md-6'></div>                                    
                            <div className="col-md-3">
                                <CustomReCAPTCHA onVerify={handleCaptchaVerify}></CustomReCAPTCHA> 
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-9' ></div>
                        </div> 
                    </div>
                </div>
        </>                
        </Page>
    );
}
export default withTranslation()(HistoricAdvanced);
